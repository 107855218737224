import { Delete, ModeEdit } from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
//
import {
    optionsWarrantyTime,
    warrantyTimeFind,
} from '../../communs/enums/warrantyTime';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormInputDate } from '../../componets/form/formInputDate';
import { FormInputNumber } from '../../componets/form/formInputNumber';
import { paths } from '../../config';
import { useAuth } from '../../context/AuthContext';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { downloadReport } from '../../services/download-report';
import { momentZoneToDate } from '../../util/dateUtil';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { floatValue, formatNumber } from '../../util/infoFormat';
import { useWindowSize } from '../../util/responsiveness';

interface IItemProduct {
    product: {
        id: string;
        code: string;
        description: string;
        quantity: number;
        localization: string;
        brand?: {
            id: string;
            description: string;
        };
    };
    valueOutput: number | undefined;
    quantity?: number;
    warrantyTime: string;
    warrantyTimeOption?: {
        id: string;
        description: any;
    };
}

interface IFormInput {
    code?: string;
    dateOutput: Date;
    customer: {
        id: string;
        name: string;
    };
    seller: {
        id: string;
        name: string;
    };
    itemProductOutputs: IItemProduct[];
}
interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    setIndex: (index: number) => void;
    getValues: any;
    append: any;
    update: any;
    fields: any[];
    width: number;
}

interface ILoadAutocomplete {
    sellerId: string | null;
}

const rules = {
    dateOutput: {
        required: true,
    },
    customer: {
        required: true,
    },
    seller: {
        required: true,
    },
};

const messageError = (errors: any, field: any) => {
    // date
    if (errors && errors.type === 'required' && field === 'dateOutput') {
        return 'O campo data da saída é obrigátorio.';
    }

    // customer
    if (errors && errors.type === 'required' && field === 'customer') {
        return 'O campo cliente é obrigátorio.';
    }

    // seller
    if (errors && errors.type === 'required' && field === 'seller') {
        return 'O campo vendedor é obrigátorio.';
    }
    return '';
};

const Form: React.FC = () => {
    const defaultValues = {
        dateOutput: momentZoneToDate(undefined),
        itemProductOutputs: [],
    };

    const history = useHistory();
    const params = useParams<'id' | any>();
    const [width] = useWindowSize();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);

    const [options, setOptions] = useState<{ name: string; id: string }[]>([]);
    const [optionsSellers, setOptionsSellers] = useState<
        { name: string; id: string }[]
    >([]);
    const [total, setTotal] = useState<number>(0);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const [openModalSalesValue, setOpenModalSalesValue] = useState(false);
    const [indexItem, setIndexItem] = useState<number>(-1);
    const [messageDialogTitle, setMessageDialogTitle] = useState<
        string | undefined
    >(undefined);
    const [openModalDownloadReport, setOpenModalDownloadReport] = useState<{
        open: boolean;
        id?: string;
    }>({ open: false, id: undefined });

    const { user } = useAuth();

    const { handleSubmit, control, reset, setValue, getValues } =
        useForm<IFormInput>({
            defaultValues,
        });

    const { fields, append, remove, update } = useFieldArray<
        IFormInput,
        'itemProductOutputs',
        'itemProductOutputsId'
    >({
        control,
        name: 'itemProductOutputs',
        keyName: 'itemProductOutputsId',
    });

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`output-product/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        } else if (
            user.profiles.filter(t => t.typeProfile === 'Vendedor').length > 0
        ) {
            loadAutocompletes({ sellerId: user.sub });
        }
    }, []);

    useEffect(() => {
        const totalSale = fields.reduce((accumulator, item) => {
            const quantity = Number(item.quantity);
            const value = Number(item.valueOutput);
            return accumulator + quantity * value;
        }, 0);

        setTotal(totalSale);
    }, [fields]);

    const setModel = (data: any) => {
        setValue('code', data.code);
        setValue('dateOutput', data.dateOutput);
        if (data.customer && data.customer.id) {
            setValue('customer', {
                name: data.customer.name,
                id: data.customer.id,
            });
        }
        if (data.seller && data.seller.id) {
            setValue('seller', {
                name: data.seller.name,
                id: data.seller.id,
            });
        }
        data.itemProductOutputs.forEach((p: IItemProduct) => {});
        setValue('itemProductOutputs', data.itemProductOutputs);
    };

    const loadAutocompletes = async ({ sellerId }: ILoadAutocomplete) => {
        try {
            const response = await api.get<{
                id: string;
                name: string;
            }>(`users/${sellerId}`);
            const data = [response.data];
            setOptionsSellers(data);
            if (!params || !params.id) {
                setValue('seller', data[0]);
            }
        } catch (error) {
            console.error(error);
            addToast({
                type: 'error',
                title: message.error.selectOne,
            });
        }
    };

    const updateAutocompleteCustomer = async (name: any) => {
        if (name && name.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(`customer/detail?name=${name}`);
            setOptions(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const updateAutocompleteSeller = async (name: any) => {
        if (name && name.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(`users?name=${name}`);
            setOptionsSellers(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const submit = async (data: any) => {
        try {
            if (!fields || fields.length === 0) {
                addToast({
                    type: 'warn',
                    title: message.warn.save,
                    description: 'A saída tem que ter pelo menos um item.',
                });
                return;
            }
            delete data.definitionFrom;
            setOpenLoading(true);

            let id: string | undefined = undefined;
            if (params && params.id) {
                await api.patch(`output-product/${params.id}`, data);
                id = params.id;
            } else {
                const response = await api.post('output-product', data);
                id = response.data.id;
            }

            setMessageDialogTitle('Deseja imprimir o comprovante?');

            setOpenModalDownloadReport(prevState => ({
                ...prevState,
                id: id,
                open: true,
            }));

            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
        } catch (error) {
            setOpenLoading(false);
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`output-product/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.outputProduct}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const handleConfirmeDownloadReport = async (confirm: boolean) => {
        if (confirm) {
            setOpenLoading(true);
            try {
                await downloadReport({
                    nameReport: 'comp_venda',
                    params: { id: openModalDownloadReport.id },
                });
                setOpenLoading(false);
            } catch (error: any) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.delete,
                    description: messageResponse || error.message,
                });
            }
        }
        setMessageDialogTitle(undefined);
        setOpenModalDownloadReport({ open: false, id: undefined });
        history.push(`${paths.outputProduct}?`);
    };

    const handleDeleteItemTable = async (index: number) => {
        remove(index);
    };

    const handleNewItemTable = () => {
        setIndexItem(-1);
        setOpenModalSalesValue(true);
    };

    const handleEditItemTable = (index: any) => {
        setIndexItem(index);
        setOpenModalSalesValue(true);
    };

    const onChangeCustomer = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteCustomer(event.target.value);
        }
    };

    const onChangeSeller = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteSeller(event.target.value);
        }
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDownloadReport}
                open={openModalDownloadReport.open}
                messageDialogTitle={messageDialogTitle}
            />
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        {params && params.id && (
                            <Grid item xs={12}>
                                <Typography
                                    sx={{ flex: '1 1 100%', fontSize: '1rem' }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div">
                                    {`Código: ${getValues('code')}`}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item md={6} xs={12}>
                            <FormInputDate
                                name="dateOutput"
                                control={control}
                                rules={null}
                                label="Data da saída"
                                messageError={messageError}
                                setValue={setValue}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name="customer"
                                rules={rules.customer}
                                control={control}
                                label="Cliente"
                                messageError={messageError}
                                options={options}
                                loading={loadingAutocomplete}
                                activeDebounce={true}
                                setValue={setValue}
                                handleChange={onChangeCustomer}
                                mask="identification"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name="seller"
                                rules={rules.seller}
                                control={control}
                                label="Vendedor"
                                messageError={messageError}
                                options={optionsSellers}
                                loading={loadingAutocomplete}
                                activeDebounce={true}
                                setValue={setValue}
                                handleChange={onChangeSeller}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    flex: '1 1 100%',
                                    fontSize: '16px',
                                    pb: 1,
                                }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                <FormButton
                                    label={'Adicionar'}
                                    typeButton={'addRegister'}
                                    onClick={() => handleNewItemTable()}
                                />
                            </Typography>
                            <Typography
                                sx={{ flex: '1 1 100%', fontSize: '16px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Itens da saída
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Produto</TableCell>
                                            <TableCell>Marca</TableCell>
                                            <TableCell>Localização</TableCell>
                                            <TableCell align="center">
                                                Garantia
                                            </TableCell>
                                            <TableCell align="center">
                                                Valor pago&nbsp;(R$)
                                            </TableCell>
                                            <TableCell align="center">
                                                Quantidade
                                            </TableCell>
                                            <TableCell align="center">
                                                Sub total
                                            </TableCell>
                                            <TableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fields.map((item, index) => (
                                            <TableRow
                                                hover
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.product?.code}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.product?.description}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.product?.brand
                                                        ?.description || ''}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.product?.localization}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {
                                                        warrantyTimeFind(
                                                            item.warrantyTime,
                                                        )?.description
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {formatNumber(
                                                        item.valueOutput,
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.quantity}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item?.valueOutput &&
                                                    item.quantity
                                                        ? formatNumber(
                                                              item.valueOutput *
                                                                  item.quantity,
                                                          )
                                                        : ''}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleEditItemTable(
                                                                index,
                                                            )
                                                        }>
                                                        <ModeEdit />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleDeleteItemTable(
                                                                index,
                                                            );
                                                        }}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <br />
                    <div className="info-footer">
                        <div className="footer-buttons">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            {params.id && (
                                <FormButton
                                    label={'Excluir'}
                                    typeButton={'delete'}
                                    onClick={() => setOpenModalDelete(true)}
                                />
                            )}
                            <FormButton
                                label={'Voltar'}
                                typeButton={'cancel'}
                                onClick={() => handleCancel()}
                            />
                        </div>
                        <Typography id="total" component="div">
                            {`Total: ${formatNumber(total)}`}
                        </Typography>
                    </div>
                </form>
                <ModalItem
                    open={openModalSalesValue}
                    setOpen={setOpenModalSalesValue}
                    index={indexItem}
                    setIndex={setIndexItem}
                    getValues={getValues}
                    append={append}
                    update={update}
                    fields={fields}
                    width={width}
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalItem({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    fields,
    width,
}: ModalProps) {
    const [optionsAutocomplete, setOptionsAutocomplete] = useState<
        { description: string; id: string; saleValue?: number }[]
    >([]);
    const [focusValueOutput, setFocusValueOutput] = useState(false);
    const [focusWarrantyTimeOption, setFocusWarrantyTimeOption] =
        useState(false);
    const [focusProductOption, setFocusProductOption] = useState(false);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const { addToast } = useToast();

    const defaultValuesItems = {
        valueOutput: undefined,
        quantity: undefined,
        warrantyTime: '',
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const extraValidationOutputNumber = (valueInput: string | number) => {
        if (typeof valueInput === 'number') {
            if (valueInput <= 0.0) {
                return false;
            }
        }
        if (typeof valueInput === 'string') {
            valueInput = valueInput.replace(',', '.');
            valueInput = Number(valueInput);
            if (valueInput <= 0.0) {
                return false;
            }
        }
        return true;
    };

    const rulesItems = {
        valueOutput: {
            required: true,
            validate: extraValidationOutputNumber,
        },
        quantity: {
            required: true,
            min: 1,
        },
        warrantyTime: {
            required: true,
        },
        product: {
            required: true,
        },
    };

    const messageErrorItems = (errors: any, field: any) => {
        // valueOutput
        if (errors && errors.type === 'required' && field === 'valueOutput') {
            return 'O campo valor de saída é obrigátorio.';
        }
        if (errors && errors.type === 'validate' && field === 'valueOutput') {
            return 'O campo valor de saída tem que ser maior que 0,00.';
        }

        // quantity
        if (errors && errors.type === 'required' && field === 'quantity') {
            return 'O campo quantidade é obrigátorio.';
        }
        if (errors && errors.type === 'min' && field === 'quantity') {
            return 'O campo quantidade tem que ser maior que 0.';
        }

        // warrantyTime
        if (
            errors &&
            errors.type === 'required' &&
            field === 'warrantyTimeOption'
        ) {
            return 'O campo garantia é obrigátorio.';
        }

        // product
        if (errors && errors.type === 'required' && field === 'product') {
            return 'O campo produto é obrigátorio.';
        }
        return '';
    };

    const useFormItems = useForm<IItemProduct>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
        setFocusProductOption(true);
    }, [index, open]);

    const setModel = (index: number) => {
        const warrantyTimeId = getValues(
            `itemProductOutputs[${index}].warrantyTime`,
        );
        const wTime = warrantyTimeFind(warrantyTimeId);
        setValueItems('warrantyTime', warrantyTimeId);
        setValueItems('warrantyTimeOption', {
            id: wTime?.id,
            description: wTime?.description,
        });
        setValueItems(
            'product',
            getValues(`itemProductOutputs[${index}].product`),
        );
        setValueItems(
            'valueOutput',
            getValues(`itemProductOutputs[${index}].valueOutput`),
        );
        setValueItems(
            'quantity',
            getValues(`itemProductOutputs[${index}].quantity`),
        );
    };

    const handleClose = () => {
        setIndex(-1);
        setOpen(false);
        reset(defaultValuesItems);
    };

    const updateAutocomplete = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(
                `product/detail/entry?description=${description}`,
            );
            setOptionsAutocomplete(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const onChangeInputProduct = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.saleValue) {
            setValueItems('valueOutput', Number(option.saleValue));
            if (option.used) {
                const wTime = warrantyTimeFind('0');
                setValueItems('warrantyTime', '0');
                setValueItems('warrantyTimeOption', wTime);
                setFocusValueOutput(true);
                setTimeout(() => setFocusValueOutput(false), 500);
            } else {
                const wTime = warrantyTimeFind('3');
                setValueItems('warrantyTime', '3');
                setValueItems('warrantyTimeOption', wTime);
                setFocusWarrantyTimeOption(true);
                setTimeout(() => setFocusWarrantyTimeOption(false), 500);
            }
        } else if (!option) {
            updateAutocomplete(event.target.value);
        }
    };

    const onChangeInputWarrantyTime = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            setFocusWarrantyTimeOption(false);
        }
        setValueItems(`warrantyTime`, option?.id);
    };

    const onBlurInputWarrantyTime = (event: any) => {
        setFocusWarrantyTimeOption(false);
    };

    const onBlurProduct = (event: any) => {
        setFocusProductOption(false);
    };

    const submitItem = (data: IItemProduct) => {
        data.valueOutput = floatValue(data.valueOutput);

        delete data.warrantyTimeOption;

        const repeatItem = fields
            ? fields.filter(
                  (f, i) => index != i && f.product.id == data.product.id,
              )
            : [];

        if (repeatItem.length > 0) {
            addToast({
                type: 'warn',
                title: 'Item repedito',
                description: 'O item já foi informado.',
            });
            return;
        }

        if (data.product.quantity < Number(data.quantity)) {
            addToast({
                type: 'warn',
                title: 'Sem estoque',
                description: `Tem apenas ${data.product.quantity} unidade(s)`,
            });
            return;
        }

        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Inserir item
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <FormAutocompleteInitialized
                                    name={`product`}
                                    rules={rulesItems.product}
                                    control={controlItems}
                                    label="Produto"
                                    options={optionsAutocomplete}
                                    loading={loadingAutocomplete}
                                    activeDebounce={true}
                                    setValue={setValueItems}
                                    handleChange={onChangeInputProduct}
                                    messageError={messageErrorItems}
                                    autoFocus={focusProductOption}
                                    handleOnBlur={onBlurProduct}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormAutocompleteInitialized
                                    name={`warrantyTimeOption`}
                                    rules={rulesItems.warrantyTime}
                                    control={controlItems}
                                    label="Garantia"
                                    options={optionsWarrantyTime()}
                                    loading={false}
                                    setValue={setValueItems}
                                    handleChange={onChangeInputWarrantyTime}
                                    messageError={messageErrorItems}
                                    handleOnBlur={onBlurInputWarrantyTime}
                                    autoFocus={focusWarrantyTimeOption}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`valueOutput`}
                                    rules={rulesItems.valueOutput}
                                    control={controlItems}
                                    label="Valor de saída"
                                    decimalScale={2}
                                    messageError={messageErrorItems}
                                    autoFocus={focusValueOutput}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`quantity`}
                                    rules={rulesItems.quantity}
                                    control={controlItems}
                                    label="Quantidade"
                                    decimalScale={0}
                                    messageError={messageErrorItems}
                                />
                            </Grid>
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default Form;

import MainContainer from '../main-container';
import Form from './productForm';
import List from './productList';

const Product: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const ProductList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Product, ProductList };

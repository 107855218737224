import { Delete, ModeEdit } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { stateFind } from '../../communs/enums/state';
import {
    optionsTypeNumberPhone,
    typeNumberPhoneFind,
} from '../../communs/enums/typeNumberPhone';
import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import { extraValidationCNPJ } from '../../communs/valitions/cnpj';
import { extraValidationCPF } from '../../communs/valitions/cpf';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormInputDate } from '../../componets/form/formInputDate';
import {
    FormInputText,
    IFormInputProps,
} from '../../componets/form/formInputText';
import { cepMask } from '../../componets/form/mask/cep';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { phoneMask } from '../../componets/form/mask/phone';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { formatDate } from '../../util/dateUtil';
import { findVal } from '../../util/findValueObjectByKey';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { formatNumber } from '../../util/infoFormat';
import { objToQuery, queryToObj } from '../../util/query';
import { format, isValid, parse } from 'date-fns';

interface IFormInput {
    name: string;
    identification: string;
    birthDate: Date | null;
    formattedBirthDate: string | null;
    email: string;
    phones: IPhone[];
    publicPlace: string;
    number: string;
    district?: {
        id: string;
        description: string;
        cityIbgeId: string;
    };
    city?:
        | {
              id: string;
              description: string;
          }
        | '';
    state?: {
        id: string;
        description: string;
    };
    cep?: string;
}

interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date' | 'autocomplete';
    name: 'description' | 'city';
    setError?: any;
    clearErrors?: any;
    required: boolean;
    hidden?: boolean;
    options?: {
        name?: string;
        description?: string;
        id?: string;
        code?: string;
    }[];
    setValue?: any;
    setFocusFirstField?: (value: boolean) => void;
}

interface IInputFormSaved {
    description: string;
    city: {
        id: string;
        description: string;
    };
}

interface IPhone {
    phoneType: string;
    phoneDdd: string;
    phoneNumber: string;
    branch: string;
    id?: string;
    phoneTypeOption?: {
        id: string;
        description: string;
    };
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    getValues: any;
    append: any;
    update: any;
    setIndex: (index: number) => void;
    fields: any[];
}

interface Data {
    id: string;
    code: string;
    technician: {
        id: string;
        name: string;
    };
    dateEntry: Date;
    dateCompletion?: Date;
    modelBrand: {
        id: string;
        description: string;
        brand: {
            id: string;
            description: string;
        };
    };
    state: string;
    serviceValue?: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'O.S.',
    },
    {
        id: 'state',
        numeric: false,
        disablePadding: false,
        label: 'Estado',
    },
    {
        id: 'dateEntry',
        numeric: false,
        disablePadding: false,
        label: 'Data entrada',
    },
    {
        id: 'dateCompletion',
        numeric: false,
        disablePadding: false,
        label: 'Data conclusão',
    },
    {
        id: 'modelBrand',
        numeric: false,
        disablePadding: false,
        label: 'Equipamento',
    },
    {
        id: 'technician',
        numeric: false,
        disablePadding: false,
        label: 'Responsável',
    },
    {
        id: 'serviceValue',
        numeric: false,
        disablePadding: false,
        label: 'Valor total',
    },
];

interface ItemProductOutput {
    quantity: number;
    valueOutput: number;
}

interface DataOutput {
    id: string;
    dateOutput: Date;
    code: string;
    seller?: {
        id: string;
        name: string;
    };
    itemProductOutputs: ItemProductOutput[];
    total: number;
}

interface HeadCellOutput {
    disablePadding: boolean;
    id: keyof DataOutput;
    label: string;
    numeric: boolean;
}

const headCellsOutput: readonly HeadCellOutput[] = [
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'Código',
    },
    {
        id: 'seller',
        numeric: false,
        disablePadding: false,
        label: 'Vendedor',
    },
    {
        id: 'dateOutput',
        numeric: false,
        disablePadding: false,
        label: 'Data',
    },
    {
        id: 'total',
        numeric: false,
        disablePadding: false,
        label: 'Total',
    },
];

const defaultValues = {
    name: '',
    identification: '',
    email: '',
    publicPlace: '',
    number: '',
    birthDate: null,
    formattedBirthDate: null,
};

const defaultValuesSaved = {
    description: '',
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { search } = useLocation();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalPhones, setOpenModalPhones] = useState(false);
    const [indexItem, setIndexItem] = useState<number>(-1);
    const [expanded, setExpanded] = React.useState<'panel-address' | false>(
        false,
    );
    const [expandedOrder, setExpandedOrder] = React.useState<
        'panel-order' | false
    >(false);
    const [expandedOutputProduct, setExpandedOutputProduct] = React.useState<
        'panel-output' | false
    >(false);
    const [expandedTotalPurchases, setExpandedTotalPurchases] = React.useState<
        'panel-total' | false
    >(false);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
    const [optionsDistrict, setOptionsDistrict] = useState([]);
    const [optionsCity, setOptionsCity] = useState([]);
    const [optionsState, setOptionsState] = useState<
        { id: string; description: string; initials: string }[]
    >([]);
    const [focusDescriptionSaveDistrict, setFocusDescriptionSaveDistrict] =
        useState(true);
    const [rowOrders, setRowOrders] = useState<Data[]>([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rowOutputProduts, setRowOutputProduts] = useState<DataOutput[]>([]);
    const [totalOutputProduct, setTotalOutputProduct] = useState(0);
    const [pageOutputProduct, setPageOutputProduct] = useState(0);
    const [query, setQuery] = useState('');
    const [queryOutputProduct, setQueryOutputProduct] = useState('');
    const [queryLast, setQueryLast] = useState('');
    const [queryOutputProductLast, setQueryOutputProductLast] = useState('');
    const [totalPurchasesOutput, setTotalPurchasesOutput] =
        React.useState<Number>(0);
    const [totalPurchasesOrder, setTotalPurchasesOrder] =
        React.useState<Number>(0);

    const extraValidationPublicPlace = (value: string) => {
        if (expanded === 'panel-address' && (!value || value.length === 0)) {
            return `O campo logradouro é obrigatório.`;
        }
    };

    const extraValidationCity = (value: any) => {
        if (expanded === 'panel-address' && !value) {
            return `O campo cidade é obrigatório.`;
        }
    };

    const extraValidationState = (value: any) => {
        if (expanded === 'panel-address' && !value) {
            return `O campo estado é obrigatório.`;
        }
    };

    const extraValidationDistrict = (value: any) => {
        if (expanded === 'panel-address' && !value) {
            return `O campo bairro é obrigatório.`;
        }
    };

    const extraValidationCep = (value: any) => {
        if (expanded === 'panel-address' && !value) {
            return `O campo CEP é obrigatório.`;
        }
    };

    const rules = {
        name: {
            required: true,
            minLength: 5,
        },
        identification: {
            required: true,
            validate: {
                validateCNPJ: extraValidationCNPJ,
                validateCPF: extraValidationCPF,
            },
        },
        birthDate: {
            required: true,
        },
        email: {
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        },
        publicPlace: {
            validate: extraValidationPublicPlace,
        },
        district: {
            validate: extraValidationDistrict,
        },
        city: {
            validate: extraValidationCity,
        },
        state: {
            validate: extraValidationState,
        },
        cep: {
            validate: extraValidationCep,
        },
    };

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        setError,
        clearErrors,
    } = useForm<IFormInput>({
        defaultValues,
    });

    const { fields, append, remove, update } = useFieldArray<
        IFormInput,
        'phones',
        'phonesId'
    >({
        control,
        name: 'phones',
        keyName: 'phonesId',
    });

    const messageError = (errors: any, field: any) => {
        // name
        if (errors && errors.type === 'required' && field === 'name') {
            return 'O campo nome é obrigátorio.';
        }
        if (errors && errors.type === 'minLength' && field === 'name') {
            return `O campo nome tem que ser maior que ${rules.name.minLength} caracteres.`;
        }

        // email
        if (errors && errors.type === 'pattern' && field === 'email') {
            return 'E-mail inválido.';
        }

        // identification
        if (
            errors &&
            errors.type === 'required' &&
            field === 'identification'
        ) {
            return 'O campo identificação (CPF/CNPJ) é obrigátorio.';
        }
        if (
            errors &&
            errors.type === 'validateCPF' &&
            field === 'identification'
        ) {
            return 'CPF inválido.';
        }
        if (
            errors &&
            errors.type === 'validateCNPJ' &&
            field === 'identification'
        ) {
            return 'CNPJ inválido.';
        }

        // birthDate
        if (errors && errors.type === 'required' && field === 'birthDate') {
            return 'O campo data de nascimento é obrigatório.';
        }

        if (errors && errors.type === 'custom_error' && field === 'birthDate') {
            return 'O campo data de nascimento está com o formato inválido.';
        }

        return errors?.message || '';
    };

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`customer/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    useEffect(() => {
        if (expanded === 'panel-address') {
            updateState();
            const district = getValues('district');
            if (
                district &&
                district != undefined &&
                String(district?.cityIbgeId)
            ) {
                setModelAddress(district);
            } else {
                updateCity('42');
            }
        }
    }, [expanded]);

    useEffect(() => {
        if (expandedOrder === 'panel-order' && params.id && query.length > 0) {
            getOrder();
        }
    }, [expandedOrder, query]);

    useEffect(() => {
        const getParam = getParams();

        if (getParam.expandedOrder === 'panel-order') {
            getParam.page = Number(page) + 1;
            getParam.state = '3,5,6,11,10';
            const queryTemp = objToQuery(getParam);
            // setQueryOutputProduct(queryTemp);
            history.push(`?${queryTemp}`);
        }
    }, [page]);

    const getOrder = async () => {
        const queryTemp = queryToObj(query);
        const queryString = objToQuery(queryTemp, [
            'expandedOutputProduct',
            'expandedTotalPurchases',
            'expandedOrder',
            'pageOutputProduct',
        ]);

        if (queryLast !== queryString) {
            setOpenLoading(true);
            setQueryLast(queryString);
            try {
                const response = await api.get(`work-order?${queryString}`);
                setRowOrders(response.data.data);
                setTotal(response.data.count);
                setOpenLoading(false);
            } catch (error) {
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: '',
                });
                setOpenLoading(false);
            }
        }
    };

    useEffect(() => {
        if (
            expandedOutputProduct === 'panel-output' &&
            params.id &&
            queryOutputProduct.length > 0
        ) {
            getOutputProduct();
        }
    }, [expandedOutputProduct, queryOutputProduct]);

    useEffect(() => {
        let getParam = getParams();
        if (
            search &&
            search.length > 0 &&
            getParam.expandedOutputProduct === 'panel-output' &&
            !expandedOutputProduct
        ) {
            if (getParam.pageOutputProduct && getParam.pageOutputProduct > 0) {
                setPageOutputProduct(Number(getParam.pageOutputProduct) - 1);
            }

            setExpandedOutputProduct('panel-output');
        } else if (getParam.expandedOutputProduct === 'panel-output') {
            setQueryOutputProduct(objToQuery(getParam));
        }

        if (
            search &&
            search.length > 0 &&
            getParam.expandedOrder === 'panel-order' &&
            !expandedOrder
        ) {
            if (getParam.page && getParam.page > 0) {
                setPage(Number(getParam.page) - 1);
            }

            setExpandedOrder('panel-order');
        } else if (getParam.expandedOrder === 'panel-order') {
            getParam.page = Number(page) + 1;
            setQuery(objToQuery(getParam));
        }

        if (
            search &&
            search.length > 0 &&
            getParam.expandedTotalPurchases === 'panel-total' &&
            !expandedTotalPurchases
        ) {
            setExpandedTotalPurchases('panel-total');
        }
    }, [search]);

    useEffect(() => {
        const getParam = getParams();

        if (getParam.expandedOutputProduct === 'panel-output') {
            getParam.pageOutputProduct = Number(pageOutputProduct) + 1;
            const queryTemp = objToQuery(getParam);
            // setQueryOutputProduct(queryTemp);
            history.push(`?${queryTemp}`);
        }
    }, [pageOutputProduct]);

    const getOutputProduct = async () => {
        const queryTemp = queryToObj(queryOutputProduct);
        let queryString = objToQuery(queryTemp, [
            'expandedOutputProduct',
            'expandedTotalPurchases',
            'expandedOrder',
            'state',
            'page',
            'pageOutputProduct',
        ]);

        if (queryTemp.pageOutputProduct) {
            queryString += `&page=${queryTemp.pageOutputProduct}`;
        }

        if (queryOutputProductLast !== queryString) {
            try {
                setOpenLoading(true);
                setQueryOutputProductLast(queryString);
                const response = await api.get(`output-product?${queryString}`);
                setRowOutputProduts(response.data.data);
                setTotalOutputProduct(response.data.count);
                setOpenLoading(false);
            } catch (error) {
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                    description: '',
                });
                setOpenLoading(false);
            }
        }
    };

    useEffect(() => {
        if (expandedTotalPurchases === 'panel-total' && params.id) {
            getPurchasesOrders();
            getPurchasesOutput();
        }
    }, [expandedTotalPurchases]);

    const getPurchasesOrders = async () => {
        setOpenLoading(true);
        try {
            const response = await api.get(`work-order/purchases/${params.id}`);
            setTotalPurchasesOrder(response.data.total);
            setOpenLoading(false);
        } catch (error) {
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: '',
            });
            setOpenLoading(false);
        }
    };

    const getPurchasesOutput = async () => {
        setOpenLoading(true);
        try {
            const response = await api.get(
                `output-product/purchases/${params.id}`,
            );
            setTotalPurchasesOutput(response.data.total);
            setOpenLoading(false);
        } catch (error) {
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: '',
            });
            setOpenLoading(false);
        }
    };

    function getParams() {
        let paramsQueryString = {
            limit: 5,
            page: 0,
            pageOutputProduct: 0,
            'customer.id': '',
            expandedOutputProduct: '',
            expandedOrder: '',
            expandedTotalPurchases: '',
            state: '3,5,6',
        };
        if (search && search.length > 0) {
            paramsQueryString = queryToObj(search.replace('?', ''));
        }
        if (!paramsQueryString.limit) {
            paramsQueryString.limit = 5;
        }
        if (paramsQueryString.page) {
            paramsQueryString.page = Number(paramsQueryString.page) || 0;
        }

        if (paramsQueryString.pageOutputProduct) {
            paramsQueryString.pageOutputProduct =
                Number(paramsQueryString.pageOutputProduct) || 0;
        }
        if (params.id) {
            paramsQueryString['customer.id'] = params.id;
        }
        if (paramsQueryString.expandedOutputProduct) {
            paramsQueryString.expandedOutputProduct =
                paramsQueryString.expandedOutputProduct;
        }
        if (paramsQueryString.expandedTotalPurchases) {
            paramsQueryString.expandedTotalPurchases =
                paramsQueryString.expandedTotalPurchases;
        }
        if (paramsQueryString.expandedOrder) {
            paramsQueryString.expandedOrder = paramsQueryString.expandedOrder;
        }
        return paramsQueryString;
    }

    const setModelAddress = async (district: any) => {
        let city: any = await findCity(String(district?.cityIbgeId));

        if (city && city.UF) {
            const cities: any = await updateCity(city.UF.id);
            setOptionsCity(cities);

            setValue('state', {
                id: city.UF.id,
                description: city.UF.nome,
            });

            setValue('city', city);
        }
    };

    const useFormSaved = useForm<IInputFormSaved>({
        defaultValues: defaultValuesSaved,
    });
    const getValuesSave = useFormSaved.getValues;
    const setValueSave = useFormSaved.setValue;
    const controlSave = useFormSaved.control;
    const setErrorSave = useFormSaved.setError;
    const clearErrorsSave = useFormSaved.clearErrors;

    const setModel = (data: IFormInput) => {
        setValue('name', data.name);
        setValue('identification', cpfAndCnpjMask(data.identification, false));
        setValue('birthDate', data.birthDate);
        if (data.birthDate)
            setValue(
                'formattedBirthDate',
                format(new Date(data.birthDate), 'dd/MM/yyyy'),
            );
        setValue('email', data.email);
        setValue('phones', data.phones);
        setValue('publicPlace', data.publicPlace);
        setValue('number', data.number);
        setValue('district', data.district);
        setValue('cep', data.cep && cepMask(data.cep, false));
        setValue('email', data.email);
    };

    function isValidDate(dateString: string) {
        const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        if (!regex.test(dateString)) {
            return false;
        }
        const date = parse(dateString, 'dd/MM/yyyy', new Date());
        return isValid(date);
    }

    const submit = async (data: any) => {
        try {
            if (!fields || fields.length < 1) {
                addToast({
                    type: 'warn',
                    title: message.warn.save,
                    description: 'Tem que ter ao menos um número para contato.',
                });
                return;
            }

            const validBirthDate = isValidDate(data.formattedBirthDate);
            if (!validBirthDate) {
                setError('birthDate', {
                    type: 'custom_error',
                    message: 'Error',
                });
                return;
            } else {
                clearErrors('birthDate');
            }

            if (expanded !== 'panel-address') {
                delete data.publicPlace;
                delete data.number;
                delete data.district;
                delete data.cep;
            }

            data.identification = data.identification.replace(/\D/g, '');
            if (data.cep) {
                data.cep = data.cep.replace(/\D/g, '');
            }
            setOpenLoading(true);
            if (params && params.id) {
                await api.patch(`customer/${params.id}`, data);
            } else {
                await api.post('customer', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.customer}?`);
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`customer/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.customer}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const handleNewItemTable = () => {
        setIndexItem(-1);
        setOpenModalPhones(true);
    };

    const handleEditItemTable = (index: any) => {
        setIndexItem(index);
        setOpenModalPhones(true);
    };

    const handleDeleteItemTable = async (index: number) => {
        remove(index);
    };

    const handleChangeAccordion =
        (panel: 'panel-address') =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const handleChangeAccordionOrders =
        (panel: 'panel-order') =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedOrder(isExpanded ? panel : false);
            const getParam = getParams();
            if (isExpanded) {
                getParam.expandedOrder = 'panel-order';
            } else {
                getParam.expandedOrder = '';
            }
            const queryTemp = objToQuery(getParam);
            // setQueryOutputProduct(queryTemp);
            history.push(`?${queryTemp}`);
        };

    const handleChangeAccordionOutputProduct =
        (panel: 'panel-output') =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedOutputProduct(isExpanded ? panel : false);
            const getParam = getParams();
            if (isExpanded) {
                getParam.expandedOutputProduct = 'panel-output';
            } else {
                getParam.expandedOutputProduct = '';
            }
            const queryTemp = objToQuery(getParam);

            history.push(`?${queryTemp}`);
        };

    const handleChangeAccordionTotalPurchases =
        (panel: 'panel-total') =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedTotalPurchases(isExpanded ? panel : false);
            const getParam = getParams();
            if (isExpanded) {
                getParam.expandedTotalPurchases = 'panel-total';
            } else {
                getParam.expandedTotalPurchases = '';
            }
            const queryTemp = objToQuery(getParam);
            // setQueryOutputProduct(queryTemp);
            history.push(`?${queryTemp}`);
        };

    const updateState = async () => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: d.nome,
                      initials: d.sigla,
                  };
              })
            : [];

        setOptionsState(data);
    };

    const updateCity = async (idState: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${idState}/municipios`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: d.nome,
                      UF: findVal(d, 'UF'),
                  };
              })
            : [];
        setOptionsCity(data);
        return data;
    };

    const findCity = async (id: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${id}`,
        );

        return {
            id: response.data.id,
            description: response.data.nome,
            UF: findVal(response.data, 'UF'),
        };
    };

    const updateAutocompleteDistrict = async (description: any) => {
        if (description && description.length > 0) {
            try {
                setLoadingAutocomplete(true);
                const response = await api.get(
                    `district/detail?description=${description}`,
                );
                setOptionsDistrict(response.data.data);
                setLoadingAutocomplete(false);
            } catch (error) {
                setLoadingAutocomplete(false);
                console.error(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            }
        }
    };

    const onChangeState = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${option.id}/municipios`,
            );
            const data = response.data
                ? response.data.map((d: any) => {
                      return {
                          id: d.id,
                          description: d.nome,
                          UF: findVal(d, 'UF'),
                      };
                  })
                : [];
            const city = getValues('city');
            const filterCity = data.filter(
                (c: any) => !city || c.id === city.id,
            );
            if (filterCity.length === 0) {
                setValue('city', '');
                setValue('district', undefined);
            }
            setOptionsCity(data);
        }
    };

    const onChangeDistrict = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteDistrict(event.target.value);
        }
        if (option && option.cityIbgeId) {
            const city: any = await findCity(option.cityIbgeId);
            if (city && city.UF) {
                const state = optionsState.find(o => o.id === city.UF.id);
                setValue('state', state);
                await updateCity(String(state?.id));
                setValue('city', city);
            }
        }
    };

    const inputsSaveDistrict: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlSave,
            label: 'Descrição',
            autoFocus: focusDescriptionSaveDistrict,
            setFocusFirstField: setFocusDescriptionSaveDistrict,
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
            required: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'city',
            options: optionsCity,
            control: controlSave,
            label: 'Cidade',
            required: true,
            readOnly: false,
            setValue: setValueSave,
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
        },
    ];

    const actionSaveDistrict = async () => {
        if (getValuesSave('description')) {
            let dataSave: any = {};
            for (const key in inputsSaveDistrict) {
                const input = inputsSaveDistrict[key];
                dataSave = {
                    ...dataSave,
                    [input.name]: getValuesSave(input.name),
                };
            }

            const cityIbgeId = dataSave.city.id;
            const city = {
                ...dataSave.city,
            };
            delete dataSave.city;

            dataSave = {
                ...dataSave,
                cityIbgeId,
                cityName: `${city.description}-${city.UF.sigla}`,
            };

            try {
                setOpenLoading(true);
                const response = await api.post('district', {
                    ...dataSave,
                });

                setValue('city', city);
                setValue('state', {
                    id: city.UF.id,
                    description: city.UF.nome,
                });
                addToast({
                    type: 'success',
                    title: message.success.save,
                    description: 'Bairro adicionadado',
                });
                setOpenLoading(false);
                return response.data;
            } catch (error) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
                console.log(error);
            }
        }
        return {};
    };

    const handleOrderById = (event: MouseEvent<unknown>, id: string) => {
        history.push(`${paths.workOrderRegister}/${id}`);
    };

    const handleOutputProductById = (
        event: MouseEvent<unknown>,
        id: string,
    ) => {
        history.push(`${paths.outputProductRegister}/${id}`);
    };

    const emptyRows = () => {
        if (5 > rowOrders.length) {
            return 5 - rowOrders.length;
        }
        return 0;
    };

    const emptyRowsOutputProduct = () => {
        if (5 > rowOutputProduts.length) {
            return 5 - rowOutputProduts.length;
        }
        return 0;
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangePageOutputProduct = (event: unknown, newPage: number) => {
        setPageOutputProduct(newPage);
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="name"
                                control={control}
                                rules={rules.name}
                                label="Nome"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="identification"
                                control={control}
                                rules={rules.identification}
                                label="Identificação (CPF/CNPJ)"
                                mask="identification"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputDate
                                name="birthDate"
                                formattedInput={'formattedBirthDate'}
                                control={control}
                                rules={rules.birthDate}
                                label="Data de nascimento"
                                messageError={messageError}
                                setValue={setValue}
                                fullWidth={true}
                                inputFormat="dd/MM/yyyy"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="email"
                                control={control}
                                rules={rules.email}
                                label="E-mail"
                                messageError={messageError}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                sx={{ fontSize: '16px', pb: 1 }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                <FormButton
                                    label={'Adicionar'}
                                    typeButton={'addRegister'}
                                    onClick={() => handleNewItemTable()}
                                />
                            </Typography>
                            <Typography
                                sx={{ fontSize: '16px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Telefones
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tipo</TableCell>
                                            <TableCell align="center">
                                                DDD
                                            </TableCell>
                                            <TableCell align="center">
                                                Número
                                            </TableCell>
                                            <TableCell align="center">
                                                Ramal
                                            </TableCell>
                                            <TableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fields.map((item, index) => (
                                            <TableRow
                                                hover
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {
                                                        typeNumberPhoneFind(
                                                            item.phoneType,
                                                        )?.description
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.phoneDdd}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {phoneMask(
                                                        item.phoneNumber,
                                                        false,
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.branch || ''}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleEditItemTable(
                                                                index,
                                                            )
                                                        }>
                                                        <ModeEdit />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleDeleteItemTable(
                                                                index,
                                                            );
                                                        }}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion
                                expanded={expanded === 'panel-address'}
                                onChange={handleChangeAccordion(
                                    'panel-address',
                                )}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Endereço</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item md={5} xs={12}>
                                            <FormInputText
                                                name="publicPlace"
                                                control={control}
                                                rules={rules.publicPlace}
                                                label="Logradouro"
                                                messageError={messageError}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <FormInputText
                                                name="number"
                                                control={control}
                                                label="Número"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                            // sx={{ display: 'none' }}
                                        >
                                            <FormAutocompleteInitialized
                                                name={`district`}
                                                rules={rules.district}
                                                control={control}
                                                label="Bairro / Localidade"
                                                options={optionsDistrict}
                                                loading={loadingAutocomplete}
                                                activeDebounce={true}
                                                setValue={setValue}
                                                handleChange={onChangeDistrict}
                                                messageError={messageError}
                                                actionSave={actionSaveDistrict}
                                                getValuesSave={getValuesSave}
                                                setValueSave={setValueSave}
                                                arrInput={inputsSaveDistrict}
                                                dialogTitle={'Adicionar bairro'}
                                            />
                                        </Grid>
                                        <Grid item md={5} xs={12}>
                                            <FormAutocompleteInitialized
                                                name={`city`}
                                                rules={rules.city}
                                                control={control}
                                                label="Cidade"
                                                options={optionsCity}
                                                loading={loadingAutocomplete}
                                                setValue={setValue}
                                                messageError={messageError}
                                            />
                                        </Grid>
                                        <Grid item md={5} xs={12}>
                                            <FormAutocompleteInitialized
                                                name={`state`}
                                                rules={rules.state}
                                                control={control}
                                                label="Estado"
                                                options={optionsState}
                                                loading={loadingAutocomplete}
                                                setValue={setValue}
                                                messageError={messageError}
                                                handleChange={onChangeState}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <FormInputText
                                                name="cep"
                                                control={control}
                                                rules={rules.cep}
                                                label="CEP"
                                                mask="cep"
                                                messageError={messageError}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion
                                expanded={expandedOrder === 'panel-order'}
                                onChange={handleChangeAccordionOrders(
                                    'panel-order',
                                )}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Ordens de serviço</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <TableContainer>
                                            <Table
                                                sx={{ minWidth: 750 }}
                                                aria-labelledby="tableTitle"
                                                size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        {headCells.map(
                                                            headCell => (
                                                                <TableCell
                                                                    key={
                                                                        headCell.id
                                                                    }
                                                                    align={
                                                                        headCell.numeric
                                                                            ? 'right'
                                                                            : 'left'
                                                                    }
                                                                    padding={
                                                                        headCell.disablePadding
                                                                            ? 'none'
                                                                            : 'normal'
                                                                    }>
                                                                    {
                                                                        headCell.label
                                                                    }
                                                                </TableCell>
                                                            ),
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rowOrders.map(row => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={row.id}>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {row.code}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {
                                                                        stateFind(
                                                                            row?.state,
                                                                        )
                                                                            ?.description
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {formatDate(
                                                                        row?.dateEntry,
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {formatDate(
                                                                        row?.dateCompletion,
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {`${row?.modelBrand?.brand?.description} - ${row?.modelBrand?.description}`}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {
                                                                        row
                                                                            .technician
                                                                            .name
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    align="left"
                                                                    onClick={event =>
                                                                        handleOrderById(
                                                                            event,
                                                                            row.id,
                                                                        )
                                                                    }>
                                                                    {formatNumber(
                                                                        row.serviceValue,
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {emptyRows() > 0 && (
                                                        <>
                                                            <TableRow
                                                                style={{
                                                                    height:
                                                                        32 *
                                                                        emptyRows(),
                                                                }}></TableRow>
                                                        </>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            component="div"
                                            count={total}
                                            labelRowsPerPage={
                                                'Linhas por página'
                                            }
                                            labelDisplayedRows={
                                                defaultLabelDisplayedRows
                                            }
                                            rowsPerPageOptions={[]}
                                            rowsPerPage={5}
                                            page={total > 0 ? page : 0}
                                            onPageChange={handleChangePage}
                                        />
                                    </Paper>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion
                                expanded={
                                    expandedOutputProduct === 'panel-output'
                                }
                                onChange={handleChangeAccordionOutputProduct(
                                    'panel-output',
                                )}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Saídas de produtos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <TableContainer>
                                            <Table
                                                sx={{ minWidth: 750 }}
                                                aria-labelledby="tableTitle"
                                                size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        {headCellsOutput.map(
                                                            headCell => (
                                                                <TableCell
                                                                    key={
                                                                        headCell.id
                                                                    }
                                                                    align={
                                                                        headCell.numeric
                                                                            ? 'right'
                                                                            : 'left'
                                                                    }
                                                                    padding={
                                                                        headCell.disablePadding
                                                                            ? 'none'
                                                                            : 'normal'
                                                                    }>
                                                                    {
                                                                        headCell.label
                                                                    }
                                                                </TableCell>
                                                            ),
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rowOutputProduts.map(
                                                        row => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={
                                                                        -1
                                                                    }
                                                                    key={
                                                                        row.id
                                                                    }>
                                                                    <TableCell
                                                                        align="left"
                                                                        onClick={event =>
                                                                            handleOutputProductById(
                                                                                event,
                                                                                row.id,
                                                                            )
                                                                        }>
                                                                        {
                                                                            row.code
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="left"
                                                                        onClick={event =>
                                                                            handleOutputProductById(
                                                                                event,
                                                                                row.id,
                                                                            )
                                                                        }>
                                                                        {
                                                                            row
                                                                                .seller
                                                                                ?.name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="left"
                                                                        onClick={event =>
                                                                            handleOutputProductById(
                                                                                event,
                                                                                row.id,
                                                                            )
                                                                        }>
                                                                        {formatDate(
                                                                            row?.dateOutput,
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="left"
                                                                        onClick={event =>
                                                                            handleOutputProductById(
                                                                                event,
                                                                                row.id,
                                                                            )
                                                                        }>
                                                                        {formatNumber(
                                                                            row?.total,
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        },
                                                    )}
                                                    {emptyRowsOutputProduct() >
                                                        0 && (
                                                        <>
                                                            <TableRow
                                                                style={{
                                                                    height:
                                                                        32 *
                                                                        emptyRowsOutputProduct(),
                                                                }}></TableRow>
                                                        </>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            component="div"
                                            count={totalOutputProduct}
                                            rowsPerPageOptions={[]}
                                            labelDisplayedRows={
                                                defaultLabelDisplayedRows
                                            }
                                            rowsPerPage={5}
                                            page={
                                                totalOutputProduct > 0
                                                    ? pageOutputProduct
                                                    : 0
                                            }
                                            onPageChange={
                                                handleChangePageOutputProduct
                                            }
                                        />
                                    </Paper>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion
                                expanded={
                                    expandedTotalPurchases === 'panel-total'
                                }
                                onChange={handleChangeAccordionTotalPurchases(
                                    'panel-total',
                                )}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>
                                        Total de compras e solicitações de
                                        serviços
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item md={4} xs={12}>
                                                <Typography
                                                    id="total"
                                                    component="div">
                                                    {`Total em O.S.: R$ ${formatNumber(
                                                        totalPurchasesOrder,
                                                    )}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <Typography
                                                    id="total"
                                                    component="div">
                                                    {`Total em compras: R$ ${formatNumber(
                                                        totalPurchasesOutput,
                                                    )}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                <Typography
                                                    id="total"
                                                    component="div">
                                                    {`Total geral: R$ ${formatNumber(
                                                        Number(
                                                            totalPurchasesOrder,
                                                        ) +
                                                            Number(
                                                                totalPurchasesOutput,
                                                            ),
                                                    )}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                    <br />
                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
                <ModalPhones
                    open={openModalPhones}
                    setOpen={setOpenModalPhones}
                    index={indexItem}
                    setIndex={setIndexItem}
                    getValues={getValues}
                    append={append}
                    update={update}
                    fields={fields}
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalPhones({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    fields,
}: ModalProps) {
    const [focusTypeNumberPhoneOption, setFocusTypeNumberPhoneOption] =
        useState(true);

    const { addToast } = useToast();

    const defaultValuesItems = {
        phoneType: undefined,
        phoneDdd: undefined,
        phoneNumber: undefined,
        brandh: undefined,
    };

    const rulesItems = {
        phoneType: {
            required: true,
        },
        phoneTypeOption: {
            required: true,
        },
        phoneDdd: {
            required: true,
            minLength: 3,
            maxLength: 3,
        },
        phoneNumber: {
            required: true,
        },
        branch: {
            required: true,
        },
    };

    const messageErrorItems = (errors: any, field: any) => {
        // phoneTypeOption
        if (
            errors &&
            errors.type === 'required' &&
            field === 'phoneTypeOption'
        ) {
            return 'O campo tipo é obrigátorio.';
        }

        // phoneDdd
        if (errors && errors.type === 'required' && field === 'phoneDdd') {
            return 'O campo DDD é obrigátorio.';
        }
        if (errors && errors.type === 'minLength' && field === 'phoneDdd') {
            return 'O campo DDD tem que ter no mínimo 3 números.';
        }
        if (errors && errors.type === 'maxLength' && field === 'phoneDdd') {
            return 'O campo DDD não pode ter mais que 3 números.';
        }

        // phoneNumber
        if (errors && errors.type === 'required' && field === 'phoneNumber') {
            return 'O campo número é obrigátorio.';
        }

        return '';
    };

    const useFormItems = useForm<IPhone>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        } else {
            setValueItems('phoneDdd', '047');
        }
        setFocusTypeNumberPhoneOption(true);
    }, [index, open]);

    const setModel = (index: number) => {
        const phoneTypeId = getValues(`phones[${index}].phoneType`);
        const pType = typeNumberPhoneFind(phoneTypeId);
        if (pType?.id) {
            setValueItems('phoneType', phoneTypeId);
            setValueItems('phoneTypeOption', {
                id: pType?.id,
                description: pType?.description,
            });
        }
        setValueItems('phoneType', getValues(`phones[${index}].phoneType`));
        setValueItems('phoneDdd', getValues(`phones[${index}].phoneDdd`));
        setValueItems(
            'phoneNumber',
            phoneMask(getValues(`phones[${index}].phoneNumber`), false),
        );
        setValueItems('branch', getValues(`phones[${index}].branch`));
    };

    const handleClose = () => {
        setIndex(-1);
        reset(defaultValuesItems);
        setOpen(false);
    };

    const onChangeInputWarrantyTime = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            setFocusTypeNumberPhoneOption(false);
        }
        setValueItems(`phoneType`, option?.id);
    };

    const onBlurInputTypeNumberPhone = (event: any) => {
        setFocusTypeNumberPhoneOption(false);
    };

    const submitItem = (data: IPhone) => {
        const repeatItem = fields
            ? fields.filter(
                  (f, i) => index != i && f.phoneNumber == data.phoneNumber,
              )
            : [];

        if (repeatItem.length > 0) {
            addToast({
                type: 'warn',
                title: 'Item repedito',
                description: 'O item já foi informado.',
            });
            return;
        }

        data.phoneNumber = data.phoneNumber.replace(/\D/g, '');

        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Inserir telefone
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <Grid item md={5} xs={12}>
                                <FormAutocompleteInitialized
                                    name={`phoneTypeOption`}
                                    rules={rulesItems.phoneTypeOption}
                                    control={controlItems}
                                    label="Tipo"
                                    options={optionsTypeNumberPhone()}
                                    loading={false}
                                    setValue={setValueItems}
                                    handleChange={onChangeInputWarrantyTime}
                                    messageError={messageErrorItems}
                                    handleOnBlur={onBlurInputTypeNumberPhone}
                                    autoFocus={focusTypeNumberPhoneOption}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <FormInputText
                                    name={`phoneDdd`}
                                    rules={rulesItems.phoneDdd}
                                    control={controlItems}
                                    label="DDD"
                                    mask="number"
                                    messageError={messageErrorItems}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <FormInputText
                                    name={`phoneNumber`}
                                    control={controlItems}
                                    rules={rulesItems.phoneNumber}
                                    label="Número"
                                    mask="phone"
                                    messageError={messageErrorItems}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <FormInputText
                                    name={`branch`}
                                    control={controlItems}
                                    label="Ramal"
                                    messageError={messageErrorItems}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}></Grid>
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default Form;

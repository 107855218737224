import { Delete, ModeEdit } from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
//
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormInputDate } from '../../componets/form/formInputDate';
import { FormInputNumber } from '../../componets/form/formInputNumber';
import { FormInputText } from '../../componets/form/formInputText';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { momentZoneToDate } from '../../util/dateUtil';
import {
    handleExceptionMessage,
    handleExceptionMultipleMessages,
} from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { floatValue, formatNumber } from '../../util/infoFormat';
import { useWindowSize } from '../../util/responsiveness';

interface IItemProduct {
    product: {
        id: string;
        code: string;
        description: string;
        brand?: {
            id: string;
            description: string;
        };
    };
    valueEntry: number | undefined;
    saleValue: number | undefined | string;
    quantity: number;
    productProviderCode?: string;
}

interface IFormInput {
    dateEntry: Date;
    noteCode: string;
    provider: {
        id: string;
        description: string;
    };
    itemProducts: IItemProduct[];
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    getValues: any;
    append: any;
    update: any;
    setIndex: (index: number) => void;
    provider?: {
        id: string;
        description: string;
    };
    fields: any[];
    width: number;
}

const defaultValues = {
    noteCode: '',
    dateEntry: momentZoneToDate(undefined),
    itemProducts: [],
};

const rules = {
    noteCode: {
        required: true,
        minLength: 5,
        // pattern: /^(?!.*-).*/, // ignora apenas o - (traço)
    },
    dateEntry: {
        required: true,
    },
    provider: {
        required: true,
    },
};

const messageError = (errors: any, field: any) => {
    // dateEntry
    if (errors && errors.type === 'required' && field === 'dateEntry') {
        return 'O campo data da entrada é obrigátorio.';
    }
    // noteCode
    if (errors && errors.type === 'required' && field === 'noteCode') {
        return 'O campo código da nota é obrigátorio.';
    }
    if (errors && errors.type === 'minLength' && field === 'noteCode') {
        return `O campo código da nota tem que ser maior que ${rules.noteCode.minLength} caracteres.`;
    }

    // providerId
    if (errors && errors.type === 'required' && field === 'provider') {
        return 'O campo fornecedor é obrigátorio.';
    }
    return '';
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const [width] = useWindowSize();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [options, setOptions] = useState<
        { description: string; id: string; codeProvider: string }[]
    >([]);
    const [total, setTotal] = useState<number>(0);

    const [openModalSalesValue, setOpenModalSalesValue] = useState(false);
    const [indexItem, setIndexItem] = useState<number>(-1);

    const { handleSubmit, control, reset, setValue, getValues } =
        useForm<IFormInput>({
            defaultValues,
        });

    const { fields, append, remove, update } = useFieldArray<
        IFormInput,
        'itemProducts',
        'itemProductsId'
    >({
        control,
        name: 'itemProducts',
        keyName: 'itemProductsId',
    });

    useEffect(() => {
        api.get(`provider?limit=1000`)
            .then(response => {
                const data = response.data.data.map((d: any) => {
                    return {
                        description: d.description,
                        id: d.id,
                    };
                });
                setOptions(data);
            })
            .catch(e => {
                console.error(e);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            });
    }, []);

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`entry-product/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    useEffect(() => {
        const totalSale = fields.reduce((accumulator, item) => {
            const quantity = Number(item.quantity);
            const valueEntry = Number(item.valueEntry);
            return accumulator + quantity * valueEntry;
        }, 0);

        setTotal(totalSale);
    }, [fields]);

    const setModel = (data: any) => {
        setValue('noteCode', data.noteCode);
        setValue('dateEntry', data.dateEntry);
        if (data.provider && data.provider.id) {
            setValue('provider', {
                description: data.provider.description,
                id: data.provider.id,
            });
        }

        // Buscando e setando valor de venda, caso tenha
        data.itemProducts.forEach((p: any) => {
            if (p && p.product.providers.length > 0) {
                const findProvider = p.product.providers.find(
                    (p: any) => p.provider.id === data.provider.id,
                );
                if (findProvider && findProvider.saleValue) {
                    p.saleValue = Number(findProvider.saleValue);
                }
            }
        });
        setValue('itemProducts', data.itemProducts);
    };

    const submit = async (data: any, e: any) => {
        e.preventDefault();
        try {
            if (!fields || fields.length === 0) {
                addToast({
                    type: 'warn',
                    title: message.warn.save,
                    description: 'A entrada tem que ter pelo menos um item.',
                });
                return;
            }
            delete data.definitionFrom;
            setOpenLoading(true);
            // const categoryId = options.find(o => o.value == )
            if (params && params.id) {
                await api.patch(`entry-product/${params.id}`, data);
            } else {
                await api.post('entry-product', data);
            }
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            reset(defaultValues);
            history.push(`${paths.entryProduct}?`);
        } catch (error) {
            setOpenLoading(false);
            const messagesResponse = handleExceptionMultipleMessages(error);
            for (const messageResponse of messagesResponse) {
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
            }
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`entry-product/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.entryProduct}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const handleDeleteItemTable = async (index: number) => {
        remove(index);
    };

    const handleNewItemTable = () => {
        if (getValues('provider') && getValues('provider').id) {
            setIndexItem(-1);
            setOpenModalSalesValue(true);
        } else {
            addToast({
                type: 'warn',
                title: 'Não pode adicionar itens sem antes informar o fornecedor',
            });
        }
    };

    const handleEditItemTable = (index: any) => {
        setIndexItem(index);
        setOpenModalSalesValue(true);
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(submit)}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="noteCode"
                                control={control}
                                rules={rules.noteCode}
                                label="Código da nota"
                                messageError={messageError}
                                mask={'toUpperCase'}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputDate
                                name="dateEntry"
                                control={control}
                                rules={null}
                                label="Data da entrada"
                                messageError={messageError}
                                setValue={setValue}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name="provider"
                                // readOnly={params?.id ? true : false}
                                rules={rules.provider}
                                control={control}
                                label="Fornecedor"
                                loading={false}
                                messageError={messageError}
                                options={options}
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    flex: '1 1 100%',
                                    fontSize: '16px',
                                    pb: 1,
                                }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                <FormButton
                                    label={'Adicionar'}
                                    typeButton={'addRegister'}
                                    onClick={() => handleNewItemTable()}
                                />
                            </Typography>
                            <Typography
                                sx={{ flex: '1 1 100%', fontSize: '16px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Itens da entrada
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Produto</TableCell>
                                            <TableCell>Marca</TableCell>
                                            <TableCell align="center">
                                                Valor de entrada&nbsp;(R$)
                                            </TableCell>
                                            <TableCell align="center">
                                                Valor de venda&nbsp;(R$)
                                            </TableCell>
                                            <TableCell align="center">
                                                Quantidade
                                            </TableCell>
                                            <TableCell align="center">
                                                Sub total da entrada
                                            </TableCell>
                                            <TableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fields.map((item, index) => (
                                            <TableRow
                                                hover
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.product?.code}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.product?.description}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.product?.brand
                                                        ?.description || ''}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {formatNumber(
                                                        item.valueEntry,
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {formatNumber(
                                                        item.saleValue,
                                                    )}
                                                </TableCell>

                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.quantity}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item?.valueEntry
                                                        ? formatNumber(
                                                              item.valueEntry *
                                                                  item.quantity,
                                                          )
                                                        : ''}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleEditItemTable(
                                                                index,
                                                            )
                                                        }>
                                                        <ModeEdit />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleDeleteItemTable(
                                                                index,
                                                            );
                                                        }}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <br />

                    <div className="info-footer">
                        <div className="footer-buttons">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            {params.id && (
                                <FormButton
                                    label={'Excluir'}
                                    typeButton={'delete'}
                                    onClick={() => setOpenModalDelete(true)}
                                />
                            )}
                            <FormButton
                                label={'Voltar'}
                                typeButton={'cancel'}
                                onClick={() => handleCancel()}
                            />
                        </div>
                        <Typography id="total" component="div">
                            {`Total: ${formatNumber(total)}`}
                        </Typography>
                    </div>
                </form>
                <ModalChangeSaleValue
                    open={openModalSalesValue}
                    setOpen={setOpenModalSalesValue}
                    index={indexItem}
                    setIndex={setIndexItem}
                    getValues={getValues}
                    append={append}
                    update={update}
                    provider={getValues('provider')}
                    fields={fields}
                    width={width}
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalChangeSaleValue({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    provider,
    fields,
    width,
}: ModalProps) {
    const [optionsAutocomplete, setOptionsAutocomplete] = useState<
        {
            description: string;
            id: string;
            codeProvider?: string;
            brand?: { id: string; description: string };
        }[]
    >([]);
    const [focusValueEntry, setFocusValueEntry] = useState(false);
    const [focusProduct, setFocusProduct] = useState(false);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const { addToast } = useToast();

    const defaultValuesItems = {
        valueEntry: undefined,
        quantity: undefined,
        productProviderCode: undefined,
        saleValue: undefined,
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const extraValidationOutputNumber = (valueInput: string | number) => {
        if (typeof valueInput === 'number') {
            if (valueInput <= 0.0) {
                return false;
            }
        }
        if (typeof valueInput === 'string') {
            valueInput = valueInput.replace(',', '.');
            valueInput = Number(valueInput);
            if (valueInput <= 0.0) {
                return false;
            }
        }
        return true;
    };

    const rulesItems = {
        valueEntry: {
            required: true,
            validate: extraValidationOutputNumber,
        },
        quantity: {
            required: true,
            min: 1,
        },
        product: {
            required: true,
        },
    };

    const messageErrorItems = (errors: any, field: any) => {
        // valueEntry
        if (errors && errors.type === 'required' && field === 'valueEntry') {
            return 'O campo valor de entrada é obrigátorio.';
        }
        if (errors && errors.type === 'validate' && field === 'valueEntry') {
            return 'O campo valor de entrada tem que ser maior que 0,00.';
        }

        // quantity
        if (errors && errors.type === 'required' && field === 'quantity') {
            return 'O campo quantidade é obrigátorio.';
        }
        if (errors && errors.type === 'min' && field === 'quantity') {
            return 'O campo quantidade tem que ser maior que 0.';
        }

        // product
        if (errors && errors.type === 'required' && field === 'product') {
            return 'O campo produto é obrigátorio.';
        }
        return '';
    };

    const useFormItems = useForm<IItemProduct>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
        setFocusProduct(true);
    }, [index, open]);

    const setModel = (index: number) => {
        setValueItems('product', getValues(`itemProducts[${index}].product`));
        setValueItems(
            'valueEntry',
            getValues(`itemProducts[${index}].valueEntry`),
        );
        setValueItems('quantity', getValues(`itemProducts[${index}].quantity`));
        setValueItems(
            'productProviderCode',
            getValues(`itemProducts[${index}].productProviderCode`),
        );
        setValueItems(
            'saleValue',
            getValues(`itemProducts[${index}].saleValue`),
        );
    };

    const handleClose = () => {
        setIndex(-1);
        reset(defaultValuesItems);
        setOpen(false);
    };

    const updateAutocomplete = async (
        description: any,
        provider?: { id: string; description: string },
    ) => {
        let queryComplement = `?description=${description}`;
        if (provider && provider.id && description && description.length > 0) {
            queryComplement += `&provider.id=${provider.id}`;
        }
        setLoadingAutocomplete(true);
        const response = await api.get(`product/detail${queryComplement}`);
        setOptionsAutocomplete(response.data.data);
        setLoadingAutocomplete(false);
    };

    const onChangeInputDefinitionFrom = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.codeProvider) {
            setValueItems('productProviderCode', option.codeProvider);
            setFocusValueEntry(true);
            setTimeout(() => setFocusValueEntry(false), 500);
            setFocusProduct(false);

            setValueItems('saleValue', '');
            if (option && option.providers.length > 0) {
                const findProvider = option.providers.find(
                    (p: any) => p.provider.id === getValues('provider').id,
                );
                if (findProvider && findProvider.saleValue) {
                    setValueItems('saleValue', Number(findProvider.saleValue));
                }
            }
        } else if (!option) {
            updateAutocomplete(event.target.value, provider);
        }
    };

    const onBlurInputProduct = (event: any) => {
        setFocusProduct(false);
    };

    const submitItem = (data: IItemProduct) => {
        data.valueEntry = floatValue(data.valueEntry);
        data.saleValue = floatValue(data.saleValue);

        const repeatItem = fields
            ? fields.filter(
                  (f, i) => index != i && f.product.id == data.product.id,
              )
            : [];

        if (repeatItem.length > 0) {
            addToast({
                type: 'warn',
                title: 'Item repedito',
                description: 'O item já foi informado.',
            });
            return;
        }

        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Inserir item
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <Grid item xs={12}>
                                <FormAutocompleteInitialized
                                    name={`product`}
                                    rules={rulesItems.product}
                                    control={controlItems}
                                    label="Produto"
                                    activeDebounce={true}
                                    options={optionsAutocomplete}
                                    loading={loadingAutocomplete}
                                    handleChange={onChangeInputDefinitionFrom}
                                    setValue={setValueItems}
                                    messageError={messageErrorItems}
                                    autoFocus={focusProduct}
                                    handleOnBlur={onBlurInputProduct}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`valueEntry`}
                                    rules={rulesItems.valueEntry}
                                    control={controlItems}
                                    label="Valor de entrada"
                                    decimalScale={2}
                                    messageError={messageErrorItems}
                                    autoFocus={focusValueEntry}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`saleValue`}
                                    control={controlItems}
                                    label="Valor de venda"
                                    decimalScale={2}
                                    // autoFocus={focusSaleValue}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`quantity`}
                                    rules={rulesItems.quantity}
                                    control={controlItems}
                                    label="Quantidade"
                                    decimalScale={0}
                                    messageError={messageErrorItems}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputText
                                    name={`productProviderCode`}
                                    control={controlItems}
                                    label="Código do produto no fornecedor"
                                    messageError={messageErrorItems}
                                    mask={'toUpperCase'}
                                    // autoFocus={focusProductProviderCode}
                                />
                            </Grid>
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default Form;

import { Grid, Paper, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
//
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormAutocompleteMultiple } from '../../componets/form/formAutocompleteMultiple';
import { FormButton } from '../../componets/form/formButton';
import { FormCheckbox } from '../../componets/form/formCheckbox';
import { FormInputText } from '../../componets/form/formInputText';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';

interface IFormInput {
    name: string;
    userName: string;
    email: string;
    typeUser: {
        description: string;
        id: string;
    };
    profiles: {
        description: string;
        id: string;
    }[];
    active: boolean;
    password?: string;
    passwordConfirmation?: string;
}

const defaultValues = {
    name: '',
    userName: '',
    email: '',
    typeUser: undefined,
    active: true,
    profiles: [],
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [options, setOptions] = useState<
        { description: string; id: string }[]
    >([]);
    const [optionsProfile, setOptionsProfile] = useState<
        { description: string; id: string }[]
    >([]);

    useEffect(() => {
        api.get(`users/typeuser?limit=1000`)
            .then(response => {
                const data = response.data.data.map((d: any) => {
                    return {
                        description: d.typeUser,
                        id: d.id,
                    };
                });
                setOptions(data);
            })
            .catch(e => {
                console.error(e);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            });
        api.get(`profile?limit=1000`)
            .then(response => {
                const data = response.data.data.map((d: any) => {
                    return {
                        description: d.typeProfile,
                        id: d.id,
                    };
                });
                setOptionsProfile(data);
            })
            .catch(e => {
                console.error(e);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            });
    }, []);

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`users/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        setError,
        clearErrors,
        watch,
    } = useForm<IFormInput>({
        defaultValues,
    });

    const rules = {
        name: {
            required: true,
            minLength: 5,
        },
        email: {
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        },
        typeUser: {
            required: true,
        },
        profiles: {
            required: true,
        },
        password: {
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,15}$/, // de 6 a 15 caracteres, tem que ter letras e números (Obs.: Nas anotações do oneNote tem um regex com mais validações)
        },
        passwordConfirmation: {
            validate: (val: string) => {
                if (val && watch('password') != val) {
                    return 'As senhas não conferem';
                }
            },
        },
    };

    const messageError = (errors: any, field: any) => {
        // name
        if (errors && errors.type === 'required' && field === 'name') {
            return 'O campo nome é obrigátorio.';
        }
        if (errors && errors.type === 'minLength' && field === 'name') {
            return `O campo nome tem que ser maior que ${rules.name.minLength} caracteres.`;
        }

        // email
        if (errors && errors.type === 'required' && field === 'email') {
            return 'O campo e-mail é obrigátorio.';
        }
        if (errors && errors.type === 'pattern' && field === 'email') {
            return 'E-mail inválido.';
        }

        // typeUser
        if (errors && errors.type === 'required' && field === 'typeUser') {
            return 'O campo tipo de usuário é obrigátorio.';
        }

        // profiles
        if (errors && errors.type === 'required' && field === 'profiles') {
            return 'Tem que definir ao menos um perfil.';
        }
        return errors?.message || '';
    };

    const setModel = (data: any) => {
        setValue('name', data.name);
        setValue('userName', data.userName);
        setValue('email', data.email);
        setValue('typeUser', {
            description: data.typeUsers[0].typeUser,
            id: data.typeUsers[0].id,
        });
        data.profiles = data.profiles.map((p: any) => {
            return {
                id: p.id,
                description: p.typeProfile,
            };
        });
        setValue('profiles', data.profiles);
        setValue('active', data.active);
    };

    const submit = async (data: any) => {
        try {
            setOpenLoading(true);
            const typeUsers = [data.typeUser];
            delete data.typeUser;
            data.typeUsers = typeUsers;
            data.profiles = data.profiles.map((p: any) => {
                return {
                    id: p.id,
                    typeProfile: p.description,
                };
            });

            if (params && params.id) {
                await api.patch(`users/${params.id}`, data);
            } else {
                await api.post('users', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.user}?`);
        } catch (error) {
            setOpenLoading(false);
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`users/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.user}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="name"
                                control={control}
                                rules={rules.name}
                                label="Nome"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="userName"
                                control={control}
                                rules={null}
                                label="Login"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="email"
                                control={control}
                                rules={rules.email}
                                label="E-mail"
                                messageError={messageError}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name="typeUser"
                                control={control}
                                rules={rules.typeUser}
                                label="Tipo de usuário"
                                messageError={messageError}
                                options={options}
                                loading={false}
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormAutocompleteMultiple
                                name="profiles"
                                control={control}
                                rules={rules.profiles}
                                label="Perfis"
                                messageError={messageError}
                                options={optionsProfile}
                                setValue={setValue}
                                getValues={getValues}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormInputText
                                name="password"
                                rules={rules.password}
                                control={control}
                                label="Senha"
                                type="password"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormInputText
                                name="passwordConfirmation"
                                rules={rules.passwordConfirmation}
                                control={control}
                                label="Confirmar senha"
                                type="password"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            Ativo:{''}
                            <FormCheckbox name={'active'} control={control} />
                        </Grid>
                    </Grid>
                    <br />

                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
            </Paper>
        </div>
    );
};

export default Form;

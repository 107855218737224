import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Checkbox, FormControlLabel } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import Copyright from '../../componets/footer';
import { FormInputText } from '../../componets/form/formInputText';
import { useAuth } from '../../context/AuthContext';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import image from '../../assets/images/home.svg';
import './styles.scss';
import Footer from '../../componets/footer';

interface IFormInput {
    email: string;
    password: string;
}

const theme = createTheme();

const SignIn: React.FC = () => {
    const { addToast } = useToast();
    const { signIn } = useAuth();
    const { setOpenLoading } = useContextGlobal();

    const defaultValues = {
        email: '',
        password: '',
    };

    const rules = {
        email: {
            required: true,
        },
        password: {
            required: true,
        },
    };

    const messageError = (errors: any, field: any) => {
        // email
        if (errors && errors.type === 'required' && field === 'email') {
            return 'O campo e-mail é obrigátorio.';
        }
        // password
        if (errors && errors.type === 'required' && field === 'password') {
            return 'O campo senha é obrigátorio.';
        }
        return '';
    };

    const { handleSubmit, control } = useForm<IFormInput>({
        defaultValues,
    });

    const submit = async (data: IFormInput, e: any) => {
        e.preventDefault();
        setOpenLoading(true);

        try {
            await signIn({
                email: data.email,
                password: data.password,
            });
            setOpenLoading(false);
        } catch (error) {
            setOpenLoading(false);

            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.login,
                description: messageResponse,
            });
        }
    };

    return (
        <div className="container">
            <div className="main-container">
                {/* <CssBaseline /> */}
                <div className="main">
                    <Avatar sx={{ bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        GESSIS
                    </Typography>
                    <br />
                    <form onSubmit={handleSubmit(submit)}>
                        <FormInputText
                            id="email"
                            name="email"
                            control={control}
                            rules={rules.email}
                            label="E-mail"
                            size="medium"
                            autoFocus
                            autoComplete="email"
                            messageError={messageError}
                            sx={{ marginBottom: '16px' }}
                            inactiveRandomId={true}
                        />
                        <FormInputText
                            name="password"
                            control={control}
                            rules={rules.password}
                            label="Senha"
                            size="medium"
                            type="password"
                            autoComplete="password"
                            messageError={messageError}
                            inactiveRandomId={true}
                        />
                        {/* <FormControlLabel
                            control={
                                <Checkbox value="remember" color="primary" />
                            }
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}>
                            Entrar
                        </Button>
                        {/* <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid> */}
                    </form>
                </div>
                <div className="main-image">
                    <div className="container-title">
                        <h2>GESSIS - Gestão com sistema</h2>
                        <h6>Gerenciamento de orçamentos, estoques e vendas</h6>
                        <img src={image} alt="Gessis" width={'100%'} />
                    </div>
                </div>
            </div>
            <footer className="footer" style={{}}>
                <Typography variant="body2" color="textPrimary" align="center">
                    {'Copyright © Gessis - Gestão com sistema '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </footer>
        </div>
    );
};

export default SignIn;

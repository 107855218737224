import { Backdrop, CircularProgress } from '@mui/material';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import api from '../services/api';

interface ContextData {
    setOpenLoading(open: boolean): void;
    handleOpenCollapseRecords(open: boolean): void;
    openCollapseRecords: boolean;
    setNotificationInventory(open: boolean): void;
    notificationInventory: boolean;
    handleOpenCollapseInvoicing(open: boolean): void;
    openCollapseInvoicing: boolean;
}

type Props = { children: React.ReactNode; message: any };

const ContextGlobal = createContext<ContextData>({} as ContextData);

const ProviderGlobal: React.FC<Props> = ({ children, message }) => {
    const [open, setOpen] = useState(false);
    const [notificationInventory, setNotificationInventory] = useState(false);
    const [openCollapseRecords, setOpenCollapseRecords] = useState(() => {
        const openCollapseRecords = localStorage.getItem(
            '@GESSIS:openCollapseRecords',
        );

        if (openCollapseRecords) {
            return true;
        }
        return false;
    });

    const [openCollapseInvoicing, setOpenCollapseInvoicing] = useState(() => {
        const openCollapseInvoicing = localStorage.getItem(
            '@GESSIS:openCollapseInvoicing',
        );

        if (openCollapseInvoicing) {
            return true;
        }
        return false;
    });

    useEffect(() => {
        if (message && message.type === 'inventory') {
            loadInventory();
        }
        if (message && message.type === 'award') {
            loadAward();
        }
        if (message && message.type === 'birthday') {
            loadBirthdayPerson();
        }
        if (message && message.type === 'overdueInstallments') {
            loadOverdueInstallments();
        }
    }, [message]);

    const loadInventory = useCallback(async () => {
        const response = await api.get('product/inventory');
        let { data } = response;

        // Condição para disparar notificação
        let items: any = localStorage.getItem('@GESSIS:inventory');
        if (items) {
            items = JSON.parse(items);
        }
        if (
            items &&
            items.length > 0 &&
            items[0].token != localStorage.getItem('@GESSIS:token')
        ) {
            setNotificationInventory(true);
        }
        data.forEach((d: any) => {
            d.token = localStorage.getItem('@GESSIS:token');
        });
        if (data.length > 0) {
            localStorage.setItem('@GESSIS:inventory', JSON.stringify(data));
        } else if (localStorage.getItem('@GESSIS:inventory')) {
            localStorage.setItem('@GESSIS:inventory', JSON.stringify([]));
        }
    }, [message]);

    const loadAward = useCallback(async () => {
        const response = await api.get('customer/awarded');
        let { data } = response;

        data.forEach((d: any) => {
            d.token = localStorage.getItem('@GESSIS:token');
        });
        if (data.length > 0) {
            localStorage.setItem(
                '@GESSIS:awardedCustomers',
                JSON.stringify(data),
            );
        } else if (localStorage.getItem('@GESSIS:awardedCustomers')) {
            localStorage.setItem(
                '@GESSIS:awardedCustomers',
                JSON.stringify([]),
            );
        }
    }, [message]);

    const loadBirthdayPerson = useCallback(async () => {
        const response = await api.get('customer/birthday');
        let { data } = response;

        data.forEach((d: any) => {
            d.token = localStorage.getItem('@GESSIS:token');
        });
        if (data.length > 0) {
            localStorage.setItem('@GESSIS:birthday', JSON.stringify(data));
        } else if (localStorage.getItem('@GESSIS:birthday')) {
            localStorage.setItem('@GESSIS:birthday', JSON.stringify([]));
        }
    }, [message]);

    const loadOverdueInstallments = useCallback(async () => {
        const response = await api.get('invoice-detail/not-downloaded');
        let { data } = response;

        data.forEach((d: any) => {
            d.token = localStorage.getItem('@GESSIS:token');
        });
        if (data.length > 0) {
            localStorage.setItem(
                '@GESSIS:overdueInstallments',
                JSON.stringify(data),
            );
        } else if (localStorage.getItem('@GESSIS:overdueInstallments')) {
            localStorage.setItem(
                '@GESSIS:overdueInstallments',
                JSON.stringify([]),
            );
        }
    }, [message]);

    const handleOpenCollapseRecords = (value: boolean) => {
        if (value) {
            localStorage.setItem('@GESSIS:openCollapseRecords', 'true');
            setOpenCollapseRecords(true);
        } else {
            localStorage.removeItem('@GESSIS:openCollapseRecords');
            setOpenCollapseRecords(false);
        }
    };

    const handleOpenCollapseInvoicing = (value: boolean) => {
        if (value) {
            localStorage.setItem('@GESSIS:openCollapseInvoicing', 'true');
            setOpenCollapseInvoicing(true);
        } else {
            localStorage.removeItem('@GESSIS:openCollapseInvoicing');
            setOpenCollapseInvoicing(false);
        }
    };

    return (
        <ContextGlobal.Provider
            value={{
                setOpenLoading: setOpen,
                openCollapseRecords,
                handleOpenCollapseRecords,
                notificationInventory,
                setNotificationInventory,
                handleOpenCollapseInvoicing,
                openCollapseInvoicing,
            }}>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1300,
                }}
                open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {children}
        </ContextGlobal.Provider>
    );
};

function useContextGlobal(): ContextData {
    const context = useContext(ContextGlobal);
    if (!context) {
        throw new Error('useToast must be used within a ProviderGlobal');
    }
    return context;
}

export { ProviderGlobal, useContextGlobal };

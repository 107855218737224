import axios from 'axios';
import { urls } from '../config';

const api = axios.create({
    baseURL:
        process.env.REACT_APP_ENVIROMENT === 'local'
            ? urls.api.local
            : urls.api.prod,
});

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            localStorage.removeItem('@GESSIS:token');
            setTimeout(() => window.location.reload(), 500);
            return {
                data: { data: [], token: null },
            };
        }
        return Promise.reject(error);
    },
);

export default api;

import { Delete, ModeEdit } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Collapse from '@mui/material/Collapse';
import { Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import FilterSearch from '../../componets/filter/filterSearch';
import { FormButton } from '../../componets/form/formButton';
import { IFormInputProps } from '../../componets/form/formInputText';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { downloadReport } from '../../services/download-report';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { formatNumber } from '../../util/infoFormat';
import { objToQuery, queryToObj } from '../../util/query';
import './styles.scss';

interface IProvider {
    amountPaid: number;
    codeProvider?: string;
    profitPercentage?: number | undefined;
    saleValue?: number;
    provider: {
        id: string;
        description: string;
    };
}

interface Data {
    id: string;
    description: string;
    localization?: string;
    code?: string;
    category?: {
        id: string;
        description: string;
    };
    brand?: {
        id: string;
        description: string;
    };
    quantity?: number;
    valueOutput?: number;
    providers: IProvider[];
    saleValue?: number;
    activeNotification: boolean;
}

interface IRow {
    handleClick: (event: MouseEvent<unknown>, id: string) => void;
    row: Data;
    setOpenModalDelete: (open: boolean) => void;
    setIdDelete: (id: string) => void;
    handleEdit: (id: string) => void;
}

interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date' | 'autocomplete';
    loadingAutocomplete?: boolean;
    options?: any[];
    setValue?: any;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'Código',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Descrição',
    },
    {
        id: 'localization',
        numeric: false,
        disablePadding: false,
        label: 'Localização',
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'Categoria',
    },
    {
        id: 'brand',
        numeric: false,
        disablePadding: false,
        label: 'Marca',
    },
    {
        id: 'quantity',
        numeric: false,
        disablePadding: false,
        label: 'QTD estoque',
    },
    {
        id: 'valueOutput',
        numeric: false,
        disablePadding: false,
        label: 'Valor de venda',
    },
];

const defaultValues = {
    category: {
        id: '',
        description: '',
    },
    product: {
        code: '',
        descriptionDefault: '',
        description: '',
        localization: '',
    },
    profitLarger: '',
    providers: {
        codeProvider: '',
    },
};

function Row({
    row,
    handleClick,
    setOpenModalDelete,
    setIdDelete,
    handleEdit,
}: IRow) {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell
                    scope="row"
                    component={'th'}
                    sx={{ cursor: 'pointer' }}
                    onClick={event => handleClick(event, row.id)}>
                    {row.code}
                </TableCell>
                <TableCell
                    align="left"
                    sx={{ cursor: 'pointer' }}
                    onClick={event => handleClick(event, row.id)}>
                    {row.description}
                </TableCell>
                <TableCell
                    align="left"
                    sx={{ cursor: 'pointer' }}
                    onClick={event => handleClick(event, row.id)}>
                    {row.localization}
                </TableCell>
                <TableCell
                    align="left"
                    sx={{ cursor: 'pointer' }}
                    onClick={event => handleClick(event, row.id)}>
                    {row.category?.description}
                </TableCell>
                <TableCell
                    align="left"
                    sx={{ cursor: 'pointer' }}
                    onClick={event => handleClick(event, row.id)}>
                    {row?.brand?.description || ''}
                </TableCell>
                <TableCell
                    align="left"
                    sx={{ cursor: 'pointer' }}
                    onClick={event => handleClick(event, row.id)}>
                    {row?.quantity}
                </TableCell>
                <TableCell
                    align="left"
                    sx={{ cursor: 'pointer' }}
                    onClick={event => handleClick(event, row.id)}>
                    {formatNumber(row.providers[0]?.saleValue)}
                </TableCell>
                <TableCell align="right">
                    <IconButton onClick={() => handleEdit(row.id)}>
                        <ModeEdit />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            setIdDelete(row.id);
                            setOpenModalDelete(true);
                        }}>
                        <Delete />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div">
                                Fornecedores
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fornecedor</TableCell>
                                        <TableCell align="center">
                                            Valor pago&nbsp;(R$)
                                        </TableCell>
                                        <TableCell align="center">
                                            Código no fornecedor
                                        </TableCell>
                                        <TableCell align="center">
                                            Percentual de lucro&nbsp;(%)
                                        </TableCell>
                                        <TableCell align="center">
                                            Valor de venda&nbsp;(R$)
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.providers.map(item => (
                                        <TableRow key={item.provider.id}>
                                            <TableCell
                                                component="th"
                                                scope="row">
                                                {item.provider.description}
                                            </TableCell>
                                            <TableCell align="center">
                                                {formatNumber(item.amountPaid)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.codeProvider ||
                                                    'Não definido'}
                                            </TableCell>
                                            <TableCell align="center">
                                                {(item.profitPercentage &&
                                                    formatNumber(
                                                        item.profitPercentage,
                                                    )) ||
                                                    'Não definido'}
                                            </TableCell>
                                            <TableCell align="center">
                                                {formatNumber(item.saleValue)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const ProductList: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<Data[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [deleteRegister, setDeleteRegister] = useState<boolean>(false);
    const params = useParams<{ id?: string }>();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');
    const [options, setOptions] = useState<
        { id: string; description: string }[]
    >([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();

    const { control, setValue, getValues } = useForm({
        defaultValues,
    });

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);
        setRows([]);
        try {
            const response = await api.get(`product?${queryString}`);
            const data = response.data.data.map((d: any) => {
                return {
                    ...d,
                    saleValue:
                        d.providers && d.providers.length > 0
                            ? formatNumber(d.providers[0].saleValue)
                            : 0,
                };
            });
            setRows(data);
            setTotal(response.data.count);
            setOpenLoading(false);
            if (deleteRegister) {
                setDeleteRegister(false);
            }
        } catch (error) {
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: '',
            });
            setOpenLoading(false);
        }
    };

    useEffect(() => {
        let queryT = '';
        if (search && search.length > 0 && (!params || !params.id)) {
            const { limit, pageParam } = getParams();
            setRowsPerPage(Number(limit));
            setPage(Number(pageParam - 1));
            queryT = search.replace('?', '');
            setQuery(queryT);
        } else if (rowsPerPage === 0 && (!params || !params.id)) {
            let limit = rowsPerPage === 0 ? rowsPerPageOptions[0] : rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
            };
            const queryTemp = objToQuery(queryVO);
            history.push(`?${queryTemp}`);
        }

        if (queryT.length > 0) {
            loadRows(queryT);
        }
    }, [search]);

    useEffect(() => {
        const { limit, pageParam, description } = getParams();
        if (
            rowsPerPage > 0 &&
            (limit != rowsPerPage || page + 1 != pageParam)
        ) {
            let limit = rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
                description,
            };
            const queryTemp = objToQuery(queryVO);
            history.push(`?${queryTemp}`);
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (deleteRegister) {
            loadRows(query);
        }
    }, [deleteRegister]);

    const handleClick = (event: MouseEvent<unknown>, id: string) => {
        handleEdit(id);
    };

    function getParams() {
        let paramsQueryString = {
            limit: 0,
            pageParam: 0,
            page: 0,
            description: null,
        };
        if (search && search.length > 0) {
            paramsQueryString = queryToObj(search.replace('?', ''));
        }
        if (paramsQueryString.limit) {
            paramsQueryString.limit = Number(paramsQueryString.limit) || 0;
        }
        if (paramsQueryString.page) {
            paramsQueryString.pageParam = Number(paramsQueryString.page) || 0;
        }
        if (paramsQueryString.description) {
            paramsQueryString.description =
                paramsQueryString.description || null;
        }
        return paramsQueryString;
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = (
        data: any,
        defaultFilter: boolean | undefined,
    ) => {
        if (data.charCode === 13) {
            const queryObj = queryToObj(query);
            const newQuery = {
                ...queryObj,
                'product.description': defaultFilter
                    ? getValues('product.descriptionDefault')
                    : getValues('product.description'),
                'product.code': getValues('product.code'),
                'product.localization': getValues('product.localization'),
                'category.description': getValues('category.description'),
                profitLarger: getValues('profitLarger'),
                'providers.codeProvider': getValues('providers.codeProvider'),
                page: 1,
            };

            setQuery(objToQuery(newQuery));
            history.push(`?${objToQuery(newQuery)}`);

            if (window.screen.width < 900) {
                setToggleSearch(false);
            }
        }
    };

    const handleOnKeyPress = (data: any) => {
        handleByOnKeyPressEnterNewQuery(data, false);
    };

    const updateAutocomplete = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(
                `category?description=${description}`,
            );
            setOptions(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const onChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocomplete(event.target.value);
        }
    };

    const inputsFilter: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'product.code',
            variant: 'standard',
            control: control,
            label: 'Código',
            handleOnKeyPress,
        },
        {
            typeInput: 'text',
            name: 'product.description',
            variant: 'standard',
            control: control,
            label: 'Descrição',
            handleOnKeyPress,
        },
        {
            typeInput: 'text',
            name: 'product.localization',
            variant: 'standard',
            control: control,
            label: 'Localização',
            handleOnKeyPress,
        },
        {
            typeInput: 'autocomplete',
            name: 'category',
            variant: 'standard',
            control: control,
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            label: 'Categoria',
            options,
            handleChange: onChange,
            handleOnKeyPress,
        },
        {
            typeInput: 'text',
            name: 'providers.codeProvider',
            variant: 'standard',
            control: control,
            label: 'Código do fornecedor',
            handleOnKeyPress,
        },
        {
            typeInput: 'text',
            name: 'profitLarger',
            variant: 'standard',
            control: control,
            label: 'Estoque maior que',
            handleOnKeyPress,
            mask: 'number',
        },
    ];

    const clearInputsFilter = () => {
        setValue('product.code', '');
        setValue('product.description', '');
        setValue('product.localization', '');
        setValue('product.descriptionDefault', '');
        setValue('category', { id: '', description: '' });
        setValue('profitLarger', '');
        setValue('providers.codeProvider', '');
    };

    const clearFilters = () => {
        const queryObj = queryToObj(query);
        const newQuery = {
            ...queryObj,
            'product.code': null,
            'product.description': null,
            'product.localization': null,
            'product.descriptionDefault': null,
            'category.description': null,
            profitLarger: null,
            'providers.codeProvider': null,
        };
        setQuery(objToQuery(newQuery));
        history.push(`?${objToQuery(newQuery)}`);
    };

    const handleClearFilters = (): void => {
        clearInputsFilter();
        clearFilters();
    };

    const handleReport = async () => {
        setOpenLoading(true);
        let params = {};
        if (getValues('product.code') && getValues('product.code').length > 0) {
            params = {
                ...params,
                'product.code': getValues('product.code'),
            };
        }
        if (
            getValues('product.description') &&
            getValues('product.description').length > 0
        ) {
            params = {
                ...params,
                'product.description': getValues('product.description'),
            };
        }
        if (
            getValues('product.localization') &&
            getValues('product.localization').length > 0
        ) {
            params = {
                ...params,
                'product.localization': getValues('product.localization'),
            };
        }
        if (
            getValues('product.descriptionDefault') &&
            getValues('product.descriptionDefault').length > 0
        ) {
            params = {
                ...params,
                'product.description': getValues('product.descriptionDefault'),
            };
        }
        if (
            getValues('category.description') &&
            getValues('category.description').length > 0
        ) {
            params = {
                ...params,
                'category.description': getValues('category.description'),
            };
        }
        if (getValues('profitLarger') && getValues('profitLarger').length > 0) {
            params = {
                ...params,
                profitLarger: getValues('profitLarger'),
            };
        }
        if (
            getValues('providers.codeProvider') &&
            getValues('providers.codeProvider').length > 0
        ) {
            params = {
                ...params,
                'providers.codeProvider': getValues('providers.codeProvider'),
            };
        }
        try {
            await downloadReport({
                nameReport: 'estoque_produtos_admin',
                params,
            });
            setOpenLoading(false);
        } catch (error: any) {
            setOpenLoading(false);
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse || error.message,
            });
        }
    };

    const handleEdit = (id: string) => {
        history.push(`${paths.productRegister}/${id}`);
    };
    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`product/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setDeleteRegister(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalDelete(false);
            setIdDelete('');
        }
    };

    const emptyRows = () => {
        if (rowsPerPage > rows.length) {
            return rowsPerPage - rows.length;
        }
        return 0;
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Produtos
                            </Typography>
                        </div>
                        <div className="header-list-search">
                            <Stack
                                spacing={2}
                                direction="row"
                                width={'100%'}
                                justifyContent="space-between"
                                alignItems="flex-end">
                                <Controller
                                    name={'product.descriptionDefault'}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <TextField
                                            size="small"
                                            onChange={event => {
                                                onChange(event);
                                            }}
                                            onKeyPress={(data: any) => {
                                                handleByOnKeyPressEnterNewQuery(
                                                    data,
                                                    true,
                                                );
                                            }}
                                            value={value}
                                            fullWidth
                                            label="Pesquisa por descrição"
                                            variant="standard"
                                        />
                                    )}
                                />
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title="Gerar relatório"
                                    onClick={() => handleReport()}>
                                    <IconButton>
                                        <ReceiptLongIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                    <Typography
                        sx={{
                            pl: { xs: 1, sm: 2 },
                            pr: { xs: 1, sm: 1 },
                        }}>
                        <FormButton
                            label={'Novo'}
                            typeButton="addRegister"
                            onClick={() =>
                                history.push(`${paths.productRegister}`)
                            }
                        />
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-label="collapsible table"
                            size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    {headCells.map(headCell => (
                                        <TableCell
                                            key={headCell.id}
                                            align={
                                                headCell.numeric
                                                    ? 'right'
                                                    : 'left'
                                            }
                                            padding={
                                                headCell.disablePadding
                                                    ? 'none'
                                                    : 'normal'
                                            }>
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                    <TableCell align="center" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => {
                                    return (
                                        // <TableRow
                                        //     // hover
                                        //     // role="checkbox"
                                        //     // tabIndex={-1}
                                        //     key={`${row.id}-${description}`}>
                                        //     <TableCell
                                        //         scope="row"
                                        //         id={`${row.description}-${row.code}`}
                                        //         onClick={event =>
                                        //             handleClick(event, row.id)
                                        //         }>
                                        //         {row.code}
                                        //     </TableCell>
                                        //     <TableCell
                                        //         align="left"
                                        //         scope="row"
                                        //         id={`${row.description}`}
                                        //         onClick={event =>
                                        //             handleClick(event, row.id)
                                        //         }>
                                        //         {row.description}
                                        //     </TableCell>
                                        //     <TableCell
                                        //         scope="row"
                                        //         align="left"
                                        //         onClick={event =>
                                        //             handleClick(event, row.id)
                                        //         }>
                                        //         {row.localization}
                                        //     </TableCell>
                                        //     <TableCell
                                        //         align="left"
                                        //         onClick={event =>
                                        //             handleClick(event, row.id)
                                        //         }>
                                        //         {row.category?.description}
                                        //     </TableCell>
                                        //     <TableCell
                                        //         align="left"
                                        //         onClick={event =>
                                        //             handleClick(event, row.id)
                                        //         }>
                                        //         {row?.activeNotification
                                        //             ? 'Sim'
                                        //             : 'Não'}
                                        //     </TableCell>
                                        //     <TableCell
                                        //         align="left"
                                        //         onClick={event =>
                                        //             handleClick(event, row.id)
                                        //         }>
                                        //         {row?.quantity}
                                        //     </TableCell>
                                        //     <TableCell
                                        //         align="left"
                                        //         onClick={event =>
                                        //             handleClick(event, row.id)
                                        //         }>
                                        //         {row?.saleValue}
                                        //     </TableCell>
                                        //     <TableCell align="right">
                                        //         <IconButton
                                        //             onClick={() =>
                                        //                 handleEdit(row.id)
                                        //             }>
                                        //             <ModeEdit />
                                        //         </IconButton>
                                        //         <IconButton
                                        //             onClick={() => {
                                        //                 setIdDelete(row.id);
                                        //                 setOpenModalDelete(
                                        //                     true,
                                        //                 );
                                        //             }}>
                                        //             <Delete />
                                        //         </IconButton>
                                        //     </TableCell>
                                        // </TableRow>
                                        <Row
                                            key={row.id}
                                            handleClick={handleClick}
                                            row={row}
                                            handleEdit={handleEdit}
                                            setOpenModalDelete={
                                                setOpenModalDelete
                                            }
                                            setIdDelete={setIdDelete}
                                        />
                                    );
                                })}
                                {emptyRows() > 0 && (
                                    <>
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows(),
                                            }}>
                                            <TableCell colSpan={6}>
                                                {/* 1em é o mesmo tamanho do Icon svg */}
                                                <IconButton>
                                                    <div
                                                        style={{
                                                            width: '1em',
                                                            height: '1em',
                                                        }}></div>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>

            {toggleSearch && (
                <FilterSearch
                    inputs={inputsFilter}
                    handleClearFilters={handleClearFilters}
                    setToggleSearch={setToggleSearch}
                />
            )}
        </div>
    );
};

export default ProductList;

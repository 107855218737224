import React from 'react';
import { AuthProvider } from './AuthContext';
import { ProviderGlobal } from './ContextGlobal';
import { ToastProvider } from './ToastContext';

type Props = { children: React.ReactNode; message?: any };

const AppProvider: React.FC<Props> = ({ children, message }) => (
    <AuthProvider>
        <ProviderGlobal message={message}>
            <ToastProvider>{children}</ToastProvider>
        </ProviderGlobal>
    </AuthProvider>
);

export default AppProvider;

import MainContainer from '../main-container';
import Form from './userForm';
import List from './userList';

const User: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const UserList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { User, UserList };

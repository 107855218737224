import { ModeEdit } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
//
import { IFormInputProps } from '../../componets/form/formInputText';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormCheckbox } from '../../componets/form/formCheckbox';
import { FormInputNumber } from '../../componets/form/formInputNumber';
import { FormInputText } from '../../componets/form/formInputText';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { floatValue, formatNumber } from '../../util/infoFormat';
import { optionsList } from '../../communs/enums/originMerchandise';
import { optionsCstIpint } from '../../communs/enums/cstIpint';
import { optionsCstPisnt } from '../../communs/enums/cstPisnt';
import { optionsCstIpiTrib } from '../../communs/enums/cstIpiTrib';
import { FormInputProps } from '../../componets/form/formInterfaces';

interface IProvider {
    provider: {
        id: string;
        description: string;
    };
    definitionFrom: {
        id: string;
        description: string;
    };
    amountPaid: number;
    codeProvider?: string;
    profitPercentage?: number | undefined;
    saleValue?: number;
}

interface IFormInput {
    description: string;
    code: string;
    localization: string;
    category: {
        description: string;
        id: string;
    };
    brand: {
        description: string;
        id: string;
    };
    providers: IProvider[];
    used: boolean;
    activeNotification: boolean;
    cean: string;
    cfop: string;
    ncm: string;
    orig: {
        description: string;
        id: string;
    };
    csosn: string;
    cenq: string;
    cstIpint: {
        description: string;
        id: string;
    };
    cstIpiTrib: {
        description: string;
        id: string;
    };
    vbcIpiTrib: string;
    pIpiTrib: string;
    vIpiTrib: string;
    cstPisnt: {
        description: string;
        id: string;
    };
    cstCofinsnt: {
        description: string;
        id: string;
    };
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    control: any;
    index: number;
    setValue: any;
    getValues: any;
    update: any;
    setError: any;
    clearErrors: any;
    fields: any[];
}

interface Expanded {
    infoProductNfe: boolean;
    imposto: boolean;
    icmssn102: boolean;
    ipi: boolean;
    pis: boolean;
    cofins: boolean;
}

interface IInputFormSaved {
    description: string;
    brand: {
        id: string;
        description: string;
    };
}

const defaultValues = {
    code: '',
    description: '',
    localization: '',
    providers: [],
    used: false,
    activeNotification: true,
};

const defaultValuesSaved = {};

const rules = {
    description: {
        required: true,
        minLength: 3,
    },
    category: {
        required: true,
    },
    brand: {
        required: true,
    },
};

const messageError = (errors: any, field: any) => {
    // code
    if (errors && errors.type === 'required' && field === 'code') {
        return 'O campo código é obrigátorio.';
    }
    // description
    if (errors && errors.type === 'required' && field === 'description') {
        return 'O campo descrição é obrigátorio.';
    }
    if (errors && errors.type === 'minLength' && field === 'description') {
        return `O campo descrição tem que ser maior que ${rules.description.minLength} caracteres.`;
    }

    // categoryId
    if (errors && errors.type === 'required' && field === 'category') {
        return 'O campo categória é obrigátorio.';
    }

    // brand
    if (errors && errors.type === 'required' && field === 'brand') {
        return 'O campo marca é obrigatório.';
    }
    return '';
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [options, setOptions] = useState<
        { description: string; id: string }[]
    >([]);
    const [openModalSalesValue, setOpenModalSalesValue] = useState(false);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
    const [indexProviderSelected, setIndexProviderSelected] =
        useState<number>(-1);
    const [expanded, setExpaded] = useState<Expanded>({
        infoProductNfe: false,
        imposto: false,
        icmssn102: false,
        ipi: false,
        pis: false,
        cofins: false,
    });
    const [optionBrands, setOptionBrands] = useState<
        { description: string; id: string }[]
    >([]);

    useEffect(() => {
        api.get(`category?limit=1000`)
            .then(response => {
                const data = response.data.data.map((d: any) => {
                    return {
                        description: d.description,
                        id: d.id,
                    };
                });
                setOptions(data);
            })
            .catch(e => {
                console.error(e);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            });
    }, []);

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`product/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        } else {
            setOpenLoading(true);
            api.get(`product/last`)
                .then(response => {
                    setValue('code', response.data.code);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        setError,
        clearErrors,
    } = useForm<IFormInput>({
        defaultValues,
    });

    const { fields, update } = useFieldArray<
        IFormInput,
        'providers',
        'providersId'
    >({
        control,
        name: 'providers',
        keyName: 'providersId',
    });

    const useFormSaved = useForm<IInputFormSaved>({
        defaultValues: defaultValuesSaved,
    });
    const getValuesSave = useFormSaved.getValues;
    const setValueSave = useFormSaved.setValue;
    const controlSave = useFormSaved.control;
    const setErrorSave = useFormSaved.setError;
    const clearErrorsSave = useFormSaved.clearErrors;

    const infoProductForNfe: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'cean',
            control: control,
            label: 'CEAN / GTIN',
            md: 4,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'cfop',
            control: control,
            label: 'CFOP',
            md: 4,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'ncm',
            control: control,
            label: 'NCM',
            md: 4,
            xs: 12,
        },
    ];

    const icmssn102: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'orig',
            control: control,
            label: 'Origem da mercadoria',
            loadingAutocomplete: false,
            setValue: setValue,
            options: optionsList(),
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'csosn',
            control: control,
            label: 'CSOSN',
            md: 6,
            xs: 12,
        },
    ];

    const ipi: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'cenq',
            control: control,
            label: 'Código de enquadramento legal do IPI',
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'cstIpint',
            control: control,
            label: 'CST IPINT',
            loadingAutocomplete: false,
            setValue: setValue,
            options: optionsCstIpint(),
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'cstIpiTrib',
            control: control,
            label: 'Código da situação tributária do IPI',
            loadingAutocomplete: false,
            setValue: setValue,
            options: optionsCstIpiTrib(),
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'vbcIpiTrib',
            control: control,
            label: 'Valor cálculo de base',
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'pIpiTrib',
            control: control,
            label: 'Alíquota do IPI',
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'vIpiTrib',
            control: control,
            label: 'Valor do IPI',
            md: 6,
            xs: 12,
        },
    ];

    const pis: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'cstPisnt',
            control: control,
            label: 'CST PISNT',
            loadingAutocomplete: false,
            setValue: setValue,
            options: optionsCstPisnt(),
            md: 6,
            xs: 12,
        },
    ];

    const cofins: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'cstCofinsnt',
            control: control,
            label: 'CST COFINSNT',
            loadingAutocomplete: false,
            setValue: setValue,
            options: optionsCstPisnt(),
            md: 6,
            xs: 12,
        },
    ];

    const inputsSaveBrand: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlSave,
            label: 'Descrição',
            autoFocus: true,
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
            mask: 'toUpperCase',
            required: true,
        },
    ];

    const setModel = (data: any) => {
        setValue('code', data.code);
        setValue('localization', data.localization);
        setValue('description', data.description);
        setValue('category', {
            description: data.category.description,
            id: data.category.id,
        });
        if (data.brand) {
            setValue('brand', {
                description: data.brand.description,
                id: data.brand.id,
            });
        }
        setValue('used', data.used);
        setValue('activeNotification', data.activeNotification);
        data.providers.forEach((p: IProvider) => {
            if (p.profitPercentage && Number(p.profitPercentage) > 0) {
                const definitionFrom = {
                    id: 'P',
                    description: 'Percentual',
                };
                p.definitionFrom = definitionFrom;
            } else {
                const definitionFrom = {
                    id: 'M',
                    description: 'Manualmente',
                };
                p.definitionFrom = definitionFrom;
                p.profitPercentage = 0;
            }
            p.profitPercentage = Number(p.profitPercentage);
            p.saleValue = Number(p.saleValue);
            p.amountPaid = Number(p.amountPaid);
        });
        setValue('providers', data.providers);
        // Informações da NF
        setValue('cean', data.cean);
        setValue('cfop', data.cfop);
        setValue('ncm', data.ncm);
        setValue('orig', data.orig);
        setValue('csosn', data.csosn);
        setValue('cenq', data.cenq);
        setValue('cstIpint', data.cstIpint);
        setValue('cstIpiTrib', data.cstIpiTrib);
        setValue('vbcIpiTrib', data.vbcIpiTrib);
        setValue('pIpiTrib', data.pIpiTrib);
        setValue('vIpiTrib', data.vIpiTrib);
        setValue('cstPisnt', data.cstPisnt);
        setValue('cstCofinsnt', data.cstCofinsnt);
    };

    const updateAutocompleteCategory = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(
                `category?description=${description}&limit=1000`,
            );
            setOptions(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const updateAutocompleteBrand = async (description: any) => {
        if (description && description.length > 0) {
            try {
                setLoadingAutocomplete(true);
                const response = await api.get(
                    `brand?description=${description}`,
                );
                setOptionBrands(response.data.data);
                setLoadingAutocomplete(false);
            } catch (error) {
                setLoadingAutocomplete(false);
                console.error(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            }
        }
    };

    const onChangeInputCategory = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteCategory(event.target.value);
        }
    };

    const onChangeBrand = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteBrand(event.target.value);
        }

        if (option) {
            setValueSave('brand', {
                id: option.id,
                description: option.description,
            });
        }
    };

    const actionSaveBrand = async () => {
        if (getValuesSave('description')) {
            const dataSave = {
                description: getValuesSave('description'),
            };

            try {
                setOpenLoading(true);
                const response = await api.post('brand', {
                    ...dataSave,
                });
                setValueSave('brand', {
                    id: response.data.id,
                    description: response.data.description,
                });
                addToast({
                    type: 'success',
                    title: message.success.save,
                    description: 'Marca adicionada',
                });
                setOpenLoading(false);
                return response.data;
            } catch (error) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
                console.log(error);
            }
        }
        return {};
    };

    const submit = async (data: any) => {
        try {
            delete data.definitionFrom;
            setOpenLoading(true);
            // const categoryId = options.find(o => o.value == )
            if (params && params.id) {
                await api.patch(`product/${params.id}`, data);
            } else {
                await api.post('product', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.product}?`);
        } catch (error) {
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.save,
                description: '',
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`product/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.product}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const handleEditSaleValue = (index: any) => {
        setIndexProviderSelected(index);
        setOpenModalSalesValue(true);
    };

    const handleChangeAccordionInfoProductNf =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                infoProductNfe: isExpanded,
            }));
        };

    const handleChangeAccordionImposto =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                imposto: isExpanded,
            }));
        };

    const handleChangeAccordionIcmssn102 =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                icmssn102: isExpanded,
            }));
        };

    const handleChangeAccordionIpi =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                ipi: isExpanded,
            }));
        };

    const handleChangeAccordionPis =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                pis: isExpanded,
            }));
        };

    const handleChangeAccordionCofins =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                cofins: isExpanded,
            }));
        };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="code"
                                control={control}
                                // rules={rules.code}
                                label="Código"
                                readOnly={true}
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="localization"
                                control={control}
                                rules={null}
                                label="Localização"
                                messageError={messageError}
                                mask={'toUpperCase'}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="description"
                                control={control}
                                rules={rules.description}
                                label="Descrição"
                                messageError={messageError}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name="category"
                                control={control}
                                rules={rules.category}
                                label="Categoria"
                                messageError={messageError}
                                options={options}
                                loading={loadingAutocomplete}
                                handleChange={onChangeInputCategory}
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name={`brand`}
                                rules={rules.brand}
                                control={control}
                                label="Marca"
                                options={optionBrands}
                                loading={loadingAutocomplete}
                                activeDebounce={true}
                                setValue={setValue}
                                handleChange={onChangeBrand}
                                messageError={messageError}
                                actionSave={actionSaveBrand}
                                getValuesSave={getValuesSave}
                                setValueSave={setValueSave}
                                arrInput={inputsSaveBrand}
                                mask={'toUpperCase'}
                                dialogTitle={'Adicionar marca'}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            Usado:{''}
                            <FormCheckbox name={'used'} control={control} />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            Notificar estoque em alerta:{''}
                            <FormCheckbox
                                name={'activeNotification'}
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{ flex: '1 1 100%', fontSize: '16px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Definir valores de venda
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Fornecedor</TableCell>
                                            <TableCell align="center">
                                                Valor pago&nbsp;(R$)
                                            </TableCell>
                                            <TableCell align="center">
                                                Código no fornecedor
                                            </TableCell>
                                            <TableCell align="center">
                                                Percentual de lucro&nbsp;(%)
                                            </TableCell>
                                            <TableCell align="center">
                                                Valor de venda&nbsp;(R$)
                                            </TableCell>
                                            <TableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fields.map((item, index) => (
                                            <TableRow
                                                hover
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}
                                                onClick={event =>
                                                    handleEditSaleValue(index)
                                                }>
                                                <TableCell
                                                    component="th"
                                                    scope="row">
                                                    {item.provider.description}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {formatNumber(
                                                        item.amountPaid,
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {item.codeProvider ||
                                                        'Não definido'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {(item.profitPercentage &&
                                                        formatNumber(
                                                            item.profitPercentage,
                                                        )) ||
                                                        'Não definido'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {formatNumber(
                                                        item.saleValue,
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleEditSaleValue(
                                                                index,
                                                            )
                                                        }>
                                                        <ModeEdit />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion
                                expanded={expanded.infoProductNfe}
                                onChange={handleChangeAccordionInfoProductNf()}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>
                                        Informações do produto para a NF
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {infoProductForNfe.map(
                                            (input, index) => {
                                                if (input.typeInput == 'text') {
                                                    return (
                                                        <Grid
                                                            item
                                                            md={input.md}
                                                            xs={input.xs}>
                                                            <FormInputText
                                                                key={index}
                                                                name={
                                                                    input.name
                                                                }
                                                                control={
                                                                    input.control
                                                                }
                                                                label={
                                                                    input.label
                                                                }
                                                                handleOnKeyPress={
                                                                    input.handleOnKeyPress
                                                                }
                                                                variant={
                                                                    input?.variant
                                                                }
                                                                mask={
                                                                    input.mask
                                                                }
                                                            />
                                                        </Grid>
                                                    );
                                                }
                                                if (
                                                    input.typeInput ===
                                                    'autocomplete'
                                                ) {
                                                    return (
                                                        <FormAutocompleteInitialized
                                                            key={index}
                                                            name={input.name}
                                                            control={
                                                                input.control
                                                            }
                                                            label={input.label}
                                                            loading={
                                                                input.loadingAutocomplete ||
                                                                false
                                                            }
                                                            options={
                                                                input.options ||
                                                                []
                                                            }
                                                            setValue={
                                                                input.setValue
                                                            }
                                                            handleChange={
                                                                input.handleChange
                                                            }
                                                            variant={
                                                                input.variant
                                                            }
                                                            handleOnKeyPress={
                                                                input.handleOnKeyPress
                                                            }
                                                        />
                                                    );
                                                }
                                                return <></>;
                                            },
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion
                                expanded={expanded.imposto}
                                onChange={handleChangeAccordionImposto()}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Impostos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expanded.icmssn102}
                                            onChange={handleChangeAccordionIcmssn102()}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>
                                                    ICMSSN102
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {icmssn102.map(
                                                        (input, index) => {
                                                            if (
                                                                input.typeInput ==
                                                                'text'
                                                            ) {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        md={
                                                                            input.md
                                                                        }
                                                                        xs={
                                                                            input.xs
                                                                        }>
                                                                        <FormInputText
                                                                            key={
                                                                                index
                                                                            }
                                                                            name={
                                                                                input.name
                                                                            }
                                                                            control={
                                                                                input.control
                                                                            }
                                                                            label={
                                                                                input.label
                                                                            }
                                                                            handleOnKeyPress={
                                                                                input.handleOnKeyPress
                                                                            }
                                                                            variant={
                                                                                input?.variant
                                                                            }
                                                                            mask={
                                                                                input.mask
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                );
                                                            }
                                                            if (
                                                                input.typeInput ===
                                                                'autocomplete'
                                                            ) {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        md={
                                                                            input.md
                                                                        }
                                                                        xs={
                                                                            input.xs
                                                                        }>
                                                                        <FormAutocompleteInitialized
                                                                            key={
                                                                                index
                                                                            }
                                                                            name={
                                                                                input.name
                                                                            }
                                                                            control={
                                                                                input.control
                                                                            }
                                                                            label={
                                                                                input.label
                                                                            }
                                                                            loading={
                                                                                input.loadingAutocomplete ||
                                                                                false
                                                                            }
                                                                            options={
                                                                                input.options ||
                                                                                []
                                                                            }
                                                                            setValue={
                                                                                input.setValue
                                                                            }
                                                                            handleChange={
                                                                                input.handleChange
                                                                            }
                                                                            variant={
                                                                                input.variant
                                                                            }
                                                                            handleOnKeyPress={
                                                                                input.handleOnKeyPress
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                );
                                                            }
                                                            return <></>;
                                                        },
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expanded.ipi}
                                            onChange={handleChangeAccordionIpi()}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>IPI</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {ipi.map((input, index) => {
                                                        if (
                                                            input.typeInput ==
                                                            'text'
                                                        ) {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    md={
                                                                        input.md
                                                                    }
                                                                    xs={
                                                                        input.xs
                                                                    }>
                                                                    <FormInputText
                                                                        key={
                                                                            index
                                                                        }
                                                                        name={
                                                                            input.name
                                                                        }
                                                                        control={
                                                                            input.control
                                                                        }
                                                                        label={
                                                                            input.label
                                                                        }
                                                                        handleOnKeyPress={
                                                                            input.handleOnKeyPress
                                                                        }
                                                                        variant={
                                                                            input?.variant
                                                                        }
                                                                        mask={
                                                                            input.mask
                                                                        }
                                                                    />
                                                                </Grid>
                                                            );
                                                        }
                                                        if (
                                                            input.typeInput ===
                                                            'autocomplete'
                                                        ) {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    md={
                                                                        input.md
                                                                    }
                                                                    xs={
                                                                        input.xs
                                                                    }>
                                                                    <FormAutocompleteInitialized
                                                                        key={
                                                                            index
                                                                        }
                                                                        name={
                                                                            input.name
                                                                        }
                                                                        control={
                                                                            input.control
                                                                        }
                                                                        label={
                                                                            input.label
                                                                        }
                                                                        loading={
                                                                            input.loadingAutocomplete ||
                                                                            false
                                                                        }
                                                                        options={
                                                                            input.options ||
                                                                            []
                                                                        }
                                                                        setValue={
                                                                            input.setValue
                                                                        }
                                                                        handleChange={
                                                                            input.handleChange
                                                                        }
                                                                        variant={
                                                                            input.variant
                                                                        }
                                                                        handleOnKeyPress={
                                                                            input.handleOnKeyPress
                                                                        }
                                                                    />
                                                                </Grid>
                                                            );
                                                        }
                                                        return <></>;
                                                    })}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expanded.pis}
                                            onChange={handleChangeAccordionPis()}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>PIS</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {pis.map((input, index) => {
                                                        if (
                                                            input.typeInput ===
                                                            'autocomplete'
                                                        ) {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    md={
                                                                        input.md
                                                                    }
                                                                    xs={
                                                                        input.xs
                                                                    }>
                                                                    <FormAutocompleteInitialized
                                                                        key={
                                                                            index
                                                                        }
                                                                        name={
                                                                            input.name
                                                                        }
                                                                        control={
                                                                            input.control
                                                                        }
                                                                        label={
                                                                            input.label
                                                                        }
                                                                        loading={
                                                                            input.loadingAutocomplete ||
                                                                            false
                                                                        }
                                                                        options={
                                                                            input.options ||
                                                                            []
                                                                        }
                                                                        setValue={
                                                                            input.setValue
                                                                        }
                                                                        handleChange={
                                                                            input.handleChange
                                                                        }
                                                                        variant={
                                                                            input.variant
                                                                        }
                                                                        handleOnKeyPress={
                                                                            input.handleOnKeyPress
                                                                        }
                                                                    />
                                                                </Grid>
                                                            );
                                                        }
                                                        return <></>;
                                                    })}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expanded.cofins}
                                            onChange={handleChangeAccordionCofins()}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>COFINS</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {cofins.map(
                                                        (input, index) => {
                                                            if (
                                                                input.typeInput ===
                                                                'autocomplete'
                                                            ) {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        md={
                                                                            input.md
                                                                        }
                                                                        xs={
                                                                            input.xs
                                                                        }>
                                                                        <FormAutocompleteInitialized
                                                                            key={
                                                                                index
                                                                            }
                                                                            name={
                                                                                input.name
                                                                            }
                                                                            control={
                                                                                input.control
                                                                            }
                                                                            label={
                                                                                input.label
                                                                            }
                                                                            loading={
                                                                                input.loadingAutocomplete ||
                                                                                false
                                                                            }
                                                                            options={
                                                                                input.options ||
                                                                                []
                                                                            }
                                                                            setValue={
                                                                                input.setValue
                                                                            }
                                                                            handleChange={
                                                                                input.handleChange
                                                                            }
                                                                            variant={
                                                                                input.variant
                                                                            }
                                                                            handleOnKeyPress={
                                                                                input.handleOnKeyPress
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                );
                                                            }
                                                            return <></>;
                                                        },
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                    <br />
                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
                <ModalChangeSaleValue
                    open={openModalSalesValue}
                    setOpen={setOpenModalSalesValue}
                    index={indexProviderSelected}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    update={update}
                    setError={setError}
                    clearErrors={clearErrors}
                    fields={fields}
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalChangeSaleValue({
    open,
    setOpen,
    control,
    index,
    setValue,
    getValues,
    update,
    fields,
    setError,
    clearErrors,
}: ModalProps) {
    const [visiblePercentage, setVisiblePercentage] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const updateSaleValues = (saleValue: number) => {
        for (const index in fields) {
            setValue(`providers[${index}].saleValue`, saleValue);
        }
    };

    const defaultValuesProviders = {
        amountPaid: undefined,
        codeProvider: '',
        profitPercentage: undefined,
        saleValue: undefined,
    };

    const extraValidationProfitPercentage = (valueInput: string | number) => {
        const profitPercentage = floatValue(getValuesItems('profitPercentage'));

        if (
            getValuesItems('definitionFrom') &&
            getValuesItems('definitionFrom').id === 'P' &&
            (!profitPercentage || profitPercentage <= 0)
        ) {
            return false;
        }
        return true;
    };

    const rulesItems = {
        definitionFrom: {
            required: true,
        },
        saleValue: {
            required: true,
            min: 1,
        },
        profitPercentage: {
            validate: extraValidationProfitPercentage,
        },
    };

    const messageErrorItems = (errors: any, field: any) => {
        // definitionFrom
        if (
            errors &&
            errors.type === 'required' &&
            field === 'definitionFrom'
        ) {
            return 'O campo forma de definição é obrigátorio.';
        }

        // saleValue
        if (errors && errors.type === 'required' && field === 'saleValue') {
            return 'O campo valor de venda é obrigátorio.';
        }
        if (errors && errors.type === 'min' && field === 'saleValue') {
            return 'O campo valor de venda tem que ser maior que 0.';
        }

        // profitPercentage
        if (
            errors &&
            errors.type === 'validate' &&
            field === 'profitPercentage'
        ) {
            return 'O percentual tem que ser maior que zero.';
        }
        return '';
    };

    const useFormItems = useForm<IProvider>({
        defaultValues: defaultValuesProviders,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const getValuesItems = useFormItems.getValues;
    const handleSubmit = useFormItems.handleSubmit;

    const valuesSelect = [
        {
            id: 'M',
            description: 'Manualmente',
        },
        {
            id: 'P',
            description: 'Percentual',
        },
    ];

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
    }, [index]);

    const setModel = (index: number) => {
        if (getValues(`providers[${index}].definitionFrom.id`) === 'M') {
            setVisiblePercentage(false);
            setValueItems('profitPercentage', undefined);
        }
        if (getValues(`providers[${index}].definitionFrom.id`) === 'P') {
            setVisiblePercentage(true);
        }
        setValueItems(
            'amountPaid',
            getValues(`providers[${index}].amountPaid`),
        );
        setValueItems(
            'profitPercentage',
            getValues(`providers[${index}].profitPercentage`),
        );
        setValueItems('saleValue', getValues(`providers[${index}].saleValue`));
        setValueItems(
            'codeProvider',
            getValues(`providers[${index}].codeProvider`),
        );
        setValueItems(
            'definitionFrom',
            getValues(`providers[${index}].definitionFrom`),
        );
        setValueItems('provider', getValues(`providers[${index}].provider`));
    };

    const onChangeInputDefinitionFrom = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option.id && option.id === 'M') {
            setVisiblePercentage(false);
            setValueItems('profitPercentage', undefined);
        }
        if (option.id && option.id === 'P') {
            setVisiblePercentage(true);
        }
    };

    const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value && String(value).replace('.', '').replace(',', '.');
        const newValue = Number(value);

        if (Number(newValue) > 0) {
            const calcInit =
                (Number(newValue) / 100) *
                    Number(getValuesItems('amountPaid')) +
                Number(getValuesItems('amountPaid'));
            setValueItems('saleValue', calcInit);
        }
    };

    const submitItem = (data: any) => {
        const provider = data;

        provider.profitPercentage = floatValue(provider.profitPercentage);
        provider.amountPaid = floatValue(provider.amountPaid);
        provider.saleValue = floatValue(provider.saleValue);
        if (provider.profitPercentage > 0) {
            const calcInit =
                (provider.profitPercentage / 100) * provider.amountPaid +
                provider.amountPaid;
            provider.saleValue = floatValue(calcInit);
        }

        update(index, {
            ...provider,
        });

        // updateSaleValues(provider.saleValue);
        handleClose();
        return;
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Definir valor de venda
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`amountPaid`}
                                    control={controlItems}
                                    label="Valor pago"
                                    decimalScale={2}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputText
                                    name={`codeProvider`}
                                    control={controlItems}
                                    messageError={messageErrorItems}
                                    label="Código no fornecedor"
                                    mask={'toUpperCase'}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormAutocompleteInitialized
                                    name={`definitionFrom`}
                                    control={controlItems}
                                    rules={rulesItems.definitionFrom}
                                    messageError={messageErrorItems}
                                    label="Forma de definição"
                                    options={valuesSelect}
                                    loading={false}
                                    setValue={setValueItems}
                                    handleChange={onChangeInputDefinitionFrom}
                                />
                            </Grid>
                            {visiblePercentage && (
                                <Grid item md={6} xs={12}>
                                    <FormInputNumber
                                        name={`profitPercentage`}
                                        control={controlItems}
                                        rules={rulesItems.profitPercentage}
                                        messageError={messageErrorItems}
                                        label="Percentual de lucro"
                                        handleOnBlur={handleOnBlur}
                                        decimalScale={2}
                                    />
                                </Grid>
                            )}
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`saleValue`}
                                    control={controlItems}
                                    rules={rulesItems.saleValue}
                                    messageError={messageErrorItems}
                                    label="Valor de venda"
                                    decimalScale={2}
                                />
                            </Grid>
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default Form;

import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Typography,
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import logoImg from '../../assets/images/icone_alterado.png';
import ChartComponent from '../../componets/chart/chart';
import api from '../../services/api';
import { formatUnixDate, momentZoneToUnix } from '../../util/dateUtil';
import { formatNumber } from '../../util/infoFormat';
import MainContainer from '../main-container';
import './styles.scss';
import { AxiosResponse } from 'axios';
import { useAuth } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { paths } from '../../config';

interface Data {
    total: number;
    data: {
        value: number;
        date: string;
    }[];
}

const Component: React.FC = () => {
    const [data, setData] = useState<Data>();
    const [dataEntry, setDataEntry] = useState<Data>();

    const start = moment().startOf('month');
    const end = moment().endOf('month');
    const [dateInitial, setDateInitial] = useState(
        momentZoneToUnix(start.toDate()),
    );
    const [dateFinal, setDateFinal] = useState(momentZoneToUnix(end.toDate()));
    const [loading, setLoading] = useState(true);
    const [control, setControl] = useState(0);

    const loadData = async () => {
        const responsePurchases = api.get(
            `customer/purchasesbydate?dateInitial=${dateInitial}&dateFinal=${dateFinal}`,
        );

        const responseEntry = api.get(
            `entry-product/purchasesbydate?dateInitial=${dateInitial}&dateFinal=${dateFinal}`,
        );

        const dataT = await Promise.allSettled([
            responsePurchases,
            responseEntry,
        ]);

        const response = dataT.filter(
            res => res.status === 'fulfilled',
        ) as PromiseFulfilledResult<any>[];

        const arrData = response[0].value.data.data;
        setData({
            data: arrData,
            total: response[0].value.data.total,
        });

        const arrDataEntry = response[1].value.data.data;

        setDataEntry({
            data: arrDataEntry,
            total: response[1].value.data.total,
        });

        if (arrData.length === 0 && control == 0) {
            setControl(1);
            start.subtract(1, 'month').startOf('month');
            end.subtract(1, 'month').endOf('month');

            setDateInitial(momentZoneToUnix(start.toDate()));
            setDateFinal(momentZoneToUnix(end.toDate()));
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, [dateInitial, dateFinal]);

    return (
        <div className="principal-container-dashboard">
            <div className="container-title">
                <h2>GESSIS - Gestão com sistema</h2>
                <h6>Gerenciamento de orçamentos, estoques e vendas</h6>
                <img src={logoImg} alt="Gessis" width={100} height={79} />
            </div>
            <div className="container-image">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={9}>
                        <Paper
                            sx={{
                                p: 0,
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 500,
                            }}>
                            {loading ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 500,
                                        padding: '16px',
                                    }}>
                                    <CircularProgress />
                                </Box>
                            ) : data?.data && data?.data?.length > 0 ? (
                                <ChartComponent data={data?.data} />
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 500,
                                    }}>
                                    <Typography variant="h6">
                                        Nenhum dado encontrado
                                    </Typography>
                                </Box>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}>
                            {loading ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 240,
                                    }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Deposits
                                    timestampInitial={dateInitial}
                                    timestampFinal={dateFinal}
                                    total={data?.total}
                                    title={`Total de saídas`}
                                />
                            )}
                        </Paper>
                        <Paper
                            sx={{
                                p: 2,
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}>
                            {loading ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 240,
                                    }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Deposits
                                    timestampInitial={dateInitial}
                                    timestampFinal={dateFinal}
                                    total={dataEntry?.total}
                                    title={`Total de entradas`}
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

interface Total {
    total: number | undefined;
    timestampInitial: number;
    timestampFinal: number;
    title: string;
}

const Deposits: React.FC<Total> = ({
    total,
    timestampInitial,
    timestampFinal,
    title,
}) => {
    const [visible, setVisible] = useState(false);
    return (
        <React.Fragment>
            <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom>
                {title}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1,
                }}>
                {visible && (
                    <VisibilityOff
                        onClick={() => {
                            setVisible(false);
                        }}
                        sx={{
                            fontSize: 50,
                            cursor: 'pointer',
                            marginRight: '8px',
                        }}
                    />
                )}
                {!visible && (
                    <Visibility
                        onClick={() => {
                            setVisible(true);
                        }}
                        sx={{
                            fontSize: 50,
                            cursor: 'pointer',
                            marginRight: '8px',
                        }}
                    />
                )}
                {visible && (
                    <Typography component="p" variant="h4">
                        {formatNumber(total)}
                    </Typography>
                )}
                {!visible && (
                    <Typography component="p" variant="h4">
                        ----------
                    </Typography>
                )}
            </Box>

            <Typography color="text.secondary" sx={{ flex: 1 }}>
                {`de ${formatUnixDate(timestampInitial)} até ${formatUnixDate(
                    timestampFinal,
                )}`}
            </Typography>
        </React.Fragment>
    );
};

const Dashboard: React.FC = () => {
    return (
        <MainContainer>
            <Component />
        </MainContainer>
    );
};

export default Dashboard;

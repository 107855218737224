import { Delete, ModeEdit } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ColumnDef } from '@tanstack/react-table';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import FilterSearch from '../../componets/filter/filterSearch';
import { FormButton } from '../../componets/form/formButton';
import { IFormInputProps } from '../../componets/form/formInputText';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { phoneMask } from '../../componets/form/mask/phone';
import { TableGeneric } from '../../componets/table';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import {
    handleExceptionMessage,
    handleExceptionMultipleMessages,
} from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { objToQuery, queryToObj } from '../../util/query';
import {
    formatDateWithoutHours,
    momentZoneToDateAddDays,
    momentZoneToUnix,
    unixToDate,
} from '../../util/dateUtil';
import { IconComponent, icons } from '../../componets/icons';
import { formatNumber } from '../../util/infoFormat';
import {
    GenericEnum,
    yesOrNoOrBothOptions,
} from '../../communs/enums/generic-enum';
import './styles.scss';
import { FormInputDateWithoutHour } from '../../componets/form/formInputDateWithoutHour';
import { colors } from '../../util/colors';
import { downloadReport } from '../../services/download-report';

interface IPhone {
    phoneType: string;
    phoneDdd: string;
    phoneNumber: string;
    branch: string;
    id?: string;
}

interface IInvoiceDetail {
    formPayment: string;
    typePayment: string;
    description: string;
    dueDate: Date;
    installmentValue: number;
    downloaded: boolean;
    workOrder?: {
        id: string;
        code: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
    };
    outputProduct?: {
        id: string;
        code: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
    };
}

interface Data {
    id: string;
    description: string;
    email: string;
    identification?: string;
    phones: IPhone[];
}

interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date' | 'autocomplete';
    loadingAutocomplete?: boolean;
    options?: any[];
    setValue?: any;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Descrição',
    },
    {
        id: 'identification',
        numeric: false,
        disablePadding: false,
        label: 'Identificação',
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'E-mail',
    },
    {
        id: 'phones',
        numeric: false,
        disablePadding: false,
        label: 'Telefones',
    },
];

const defaultValues = {
    description: '',
    descriptionDefault: '',
    workOrder: {
        code: '',
    },
    outputProduct: {
        code: '',
    },
    customer: {
        name: '',
    },
    downloaded: {
        id: '',
        description: '',
    },
    dateInitial: momentZoneToDateAddDays({ days: -90, startOfType: 'day' }),
    dateFinal: momentZoneToDateAddDays({ days: 0, endOfType: 'day' }),
};

const messageWriteOff = (data: IInvoiceDetail) => {
    if (data.outputProduct) {
        return `Tem certeza que dejesa dar baixa na parcela com a descrição "${data.description}" da Venda ${data.outputProduct.code}?`;
    }
    return `Tem certeza que dejesa dar baixa na parcela com a descrição "${data.description}" da O.S. ${data.workOrder?.code}?`;
};

const messageUnsubscribe = (data: IInvoiceDetail) => {
    if (data.outputProduct) {
        return `Tem certeza que dejesa cancelar a baixa da parcela com a descrição "${data.description}" da Venda ${data.outputProduct.code}?`;
    }
    return `Tem certeza que dejesa cancelar a baixa da parcela com a descrição "${data.description}" da O.S. ${data.workOrder?.code}?`;
};

const paramsModais = {
    titleConfirm: {
        writeOff: {
            message: 'Deseja dar baixa da fatura?',
        },
        unsubscribe: {
            message: 'Deseja cancelar a baixa da fatura?',
        },
    },
    titlePrint: 'Deseja imprimir o recibo?',
};

const yesOrNoOrBothInstance = new GenericEnum(yesOrNoOrBothOptions);
const yesOrNoOrBothEnum = yesOrNoOrBothInstance.optionsList();

const ListEntity: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<IInvoiceDetail[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [deleteRegister, setDeleteRegister] = useState<boolean>(false);
    const params = useParams<{ id?: string }>();
    const [openModalAction, setOpenModalAction] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');
    const [additionalParams, setAdditionalParams] = useState<any>({});
    const [openModalDownloadReport, setOpenModalDownloadReport] = useState<{
        open: boolean;
        id?: string;
    }>({ open: false, id: undefined });
    const [messageDialogTitle, setMessageDialogTitle] = useState<
        string | undefined
    >(undefined);
    const [messageDialogContent, setMessageDialogContent] = useState<
        string | undefined
    >(undefined);

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();

    const {
        control,
        setValue,
        getValues,
        setError,
        clearErrors,
        getFieldState,
    } = useForm({
        defaultValues,
    });

    // Removendo parâmetro modalAction
    const parametersOfQuery = (queryString: string) => {
        const queryUseInFind = queryToObj(queryString);
        if (queryUseInFind.action && queryUseInFind.invoiceId) {
            delete queryUseInFind.action;
            delete queryUseInFind.invoiceId;
        }
        const queryStringUseInFind = objToQuery(queryUseInFind);
        return queryStringUseInFind;
    };

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);

        const queryStringUseInFind = parametersOfQuery(queryString);
        try {
            const response = await api.get(
                `invoice-detail?${queryStringUseInFind}`,
            );
            setRows(response.data.data);
            setTotal(response.data.count);
            setAdditionalParams(response.data.additionalParams);
            setOpenLoading(false);
            if (deleteRegister) {
                setDeleteRegister(false);
            }
        } catch (error) {
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: '',
            });
            setOpenLoading(false);
        }
    };

    const loadInvoice = async ({
        id,
        action,
    }: {
        id: string;
        action: 'unsubscribe' | 'writeOff';
    }): Promise<void> => {
        setOpenLoading(true);

        try {
            const response = await api.get(`invoice-detail/${id}`);
            let code = response.data?.outputProduct?.code;
            let description = 'venda';
            if (response.data?.workOrder) {
                code = response.data.workOrder.code;
                description = 'O.S.';
            }

            setMessageDialogContent(`
                                    <p style="margin: 0 0 8px 0;"><strong>Descrição da fatura:</strong> ${response.data.description}</p>
                                    <strong>Código da ${description}</strong>: ${code}`);

            setMessageDialogTitle(paramsModais.titleConfirm[action].message);
            setOpenModalAction(true);
        } catch (error) {
            addToast({
                type: 'error',
                title: message.error.selectOne,
                description: '',
            });
            setOpenLoading(false);
        }
    };

    useEffect(() => {
        let queryT = '';
        if (
            window.location.search &&
            window.location.search.length > 0 &&
            (!params || !params.id)
        ) {
            const { limit, pageParam } = getParams();
            setRowsPerPage(Number(limit));
            setPage(Number(pageParam - 1));
            queryT = window.location.search.replace('?', '');
            setQuery(queryT);
        } else if (rowsPerPage === 0 && (!params || !params.id)) {
            let limit = rowsPerPage === 0 ? rowsPerPageOptions[0] : rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
                downloaded: '0',
                dateInitial: momentZoneToUnix(getValues('dateInitial')),
                dateFinal: momentZoneToUnix(getValues('dateFinal')),
            };
            const queryTemp = objToQuery(queryVO);
            history.push(`?${queryTemp}`);
        }

        const queryToObjParams = queryToObj(queryT);
        if (queryToObjParams?.action && queryToObjParams?.invoiceId) {
            loadInvoice({
                id: queryToObjParams.invoiceId,
                action: queryToObjParams.action,
            });
        }

        if (queryT.length > 0) {
            loadRows(queryT);
            setParams(queryT);
        }
    }, [window.location.search]);

    useEffect(() => {
        const { limit, pageParam, description } = getParams();
        if (
            rowsPerPage > 0 &&
            (limit != rowsPerPage || page + 1 != pageParam)
        ) {
            let limit = rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
                description,
            };
            const queryTemp = objToQuery(queryVO);
            history.push(`?${queryTemp}`);
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (deleteRegister) {
            loadRows(query);
        }
    }, [deleteRegister]);

    const columnsDetPag = React.useMemo<ColumnDef<IInvoiceDetail>[]>(
        () => [
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Código / Número
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell align="left" key={row.id}>
                            {row.original.outputProduct
                                ? row.original.outputProduct?.code
                                : row.original.workOrder?.code}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'description',
                cell: (cell: any) => (
                    <TableCell align="left">{cell?.getValue()}</TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Descrição
                    </TableCell>
                ),
            },
            {
                accessorKey: 'dueDate',
                cell: (cell: any) => (
                    <TableCell align="left">
                        {formatDateWithoutHours(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data vencimento
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Cliente
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell align="left" key={row.id}>
                            {row.original.outputProduct
                                ? row.original.outputProduct?.customer?.name
                                : row.original.workOrder?.customer?.name}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Identificação
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell key={row.id} align="left">
                            {row.original.outputProduct
                                ? cpfAndCnpjMask(
                                      row.original.outputProduct?.customer
                                          ?.identification,
                                      false,
                                  )
                                : cpfAndCnpjMask(
                                      row.original.workOrder?.customer
                                          ?.identification,
                                      false,
                                  )}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'installmentValue',
                cell: (cell: any) => (
                    <TableCell align="left">
                        {formatNumber(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Valor
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <Tooltip
                            title="Dar baixa"
                            onClick={() => {
                                const objQuery = queryToObj(
                                    window.location.search.replace('?', ''),
                                );
                                const action = 'writeOff';
                                objQuery.action = action;
                                objQuery.invoiceId = row.original.id;

                                // let code = row.original?.outputProduct?.code;
                                // let description = 'venda';
                                // if (row.original?.workOrder) {
                                //     code = row.original.workOrder.code;
                                //     description = 'O.S.';
                                // }

                                // setMessageDialogContent(`
                                //     <p style="margin: 0 0 8px 0;"><strong>Descrição da fatura:</strong> ${row.original.description}</p>
                                //     <strong>Código da ${description}</strong>: ${code}
                                // `);

                                // setMessageDialogTitle(
                                //     paramsModais.titleConfirm[action].message,
                                // );
                                // setOpenModalAction(true);
                                history.push(`?${objToQuery(objQuery)}`);
                            }}>
                            <IconButton disabled={row.original.downloaded}>
                                <IconComponent icon={icons.downloadDone} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Cancelar baixa"
                            onClick={() => {
                                const objQuery = queryToObj(
                                    window.location.search.replace('?', ''),
                                );
                                const action = 'unsubscribe';
                                objQuery.action = action;
                                objQuery.invoiceId = row.original.id;

                                history.push(`?${objToQuery(objQuery)}`);
                            }}>
                            <IconButton disabled={!row.original.downloaded}>
                                <IconComponent icon={icons.notInterested} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Imprimir"
                            onClick={() => {
                                setMessageDialogTitle(paramsModais.titlePrint);
                                setOpenModalDownloadReport(prevState => ({
                                    ...prevState,
                                    id: row.original.id,
                                    open: true,
                                }));
                            }}>
                            <IconButton disabled={!row.original.downloaded}>
                                <IconComponent icon={icons.print} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const handleConfirmeDownloadReport = async (confirm: boolean) => {
        if (confirm) {
            setOpenLoading(true);
            try {
                await downloadReport({
                    nameReport: 'recibo',
                    params: { id: openModalDownloadReport.id },
                });
                setOpenLoading(false);
            } catch (error: any) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.delete,
                    description: messageResponse || error.message,
                });
            }
        }
        setMessageDialogTitle(undefined);
        setOpenModalDownloadReport({ open: false, id: undefined });
    };

    const handleConfirmAction = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalAction(false);
            const queryObj = queryToObj(query);
            handleRunLow({ id: queryObj.invoiceId, action: queryObj.action });
        } else {
            setOpenModalAction(false);
        }

        const queryStringUseInFind = parametersOfQuery(
            window.location.search.replace('?', ''),
        );
        history.push(`?${queryStringUseInFind}`);
    };

    const handleCloseModalAction = () => {
        setOpenModalAction(false);
        const queryStringUseInFind = parametersOfQuery(
            window.location.search.replace('?', ''),
        );
        history.push(`?${queryStringUseInFind}`);
    };

    const handleRunLow = async ({
        id,
        action,
    }: {
        id: string;
        action: 'unsubscribe' | 'writeOff';
    }) => {
        try {
            setOpenLoading(true);
            await api.patch(`invoice-detail/${id}/action/${action}`, {});
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            setDeleteRegister(true);

            if (action === 'writeOff') {
                setMessageDialogTitle(paramsModais.titlePrint);
                setOpenModalDownloadReport(prevState => ({
                    ...prevState,
                    id: id,
                    open: true,
                }));
            }
        } catch (error) {
            setOpenLoading(false);
            const messagesResponse = handleExceptionMultipleMessages(error);
            for (const messageResponse of messagesResponse) {
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
            }
            console.log(error);
        }
    };

    function getParams() {
        let paramsQueryString = {
            limit: 0,
            pageParam: 0,
            page: 0,
            description: null,
            dateInitial: defaultValues.dateInitial,
            dateFinal: defaultValues.dateFinal,
            typeQuery: null,
            'customer.name': null,
            'workOrder.code': null,
            'outputProduct.code': null,
        };
        if (search && search.length > 0) {
            paramsQueryString = queryToObj(search.replace('?', ''));
        }
        if (paramsQueryString.limit) {
            paramsQueryString.limit = Number(paramsQueryString.limit) || 0;
        }
        if (paramsQueryString.page) {
            paramsQueryString.pageParam = Number(paramsQueryString.page) || 0;
        }
        if (paramsQueryString.description) {
            paramsQueryString.description =
                paramsQueryString.description || null;
        }
        if (paramsQueryString.dateInitial) {
            paramsQueryString.dateInitial = paramsQueryString.dateInitial || '';
        }
        if (paramsQueryString.dateFinal) {
            paramsQueryString.dateFinal = paramsQueryString.dateFinal || '';
        }
        if (paramsQueryString['customer.name']) {
            paramsQueryString['customer.name'] =
                paramsQueryString['customer.name'] || null;
        }
        if (paramsQueryString['workOrder.code']) {
            paramsQueryString['workOrder.code'] =
                paramsQueryString['workOrder.code'] || null;
        }
        if (paramsQueryString['outputProduct.code']) {
            paramsQueryString['outputProduct.code'] =
                paramsQueryString['outputProduct.code'] || null;
        }
        return paramsQueryString;
    }

    function setParams(query: any) {
        const objQuery = queryToObj(query);
        if (objQuery?.downloaded) {
            const optionOperation = yesOrNoOrBothInstance.getObject(
                objQuery?.downloaded,
            );
            setValue('downloaded', optionOperation);
        }
        if (objQuery?.dateInitial) {
            setValue('dateInitial', unixToDate(objQuery?.dateInitial));
        }
        if (objQuery?.dateFinal) {
            setValue('dateFinal', unixToDate(objQuery?.dateFinal));
        }
    }

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = () => {
        if (getFieldState('dateInitial').invalid) {
            return;
        }
        if (getFieldState('dateFinal').invalid) {
            return;
        }

        const queryObj = queryToObj(query);
        const newQuery = {
            ...queryObj,
            description: getValues('description'),
            'workOrder.code': getValues('workOrder.code'),
            'outputProduct.code': getValues('outputProduct.code'),
            'customer.name': getValues('customer.name'),
            dateInitial: momentZoneToUnix(getValues('dateInitial')),
            dateFinal: momentZoneToUnix(getValues('dateFinal')),
            downloaded: getValues('downloaded')?.id || '',
            page: 1,
        };
        setQuery(objToQuery(newQuery));
        history.push(`?${objToQuery(newQuery)}`);

        if (window.screen.width < 900) {
            setToggleSearch(false);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            handleByOnKeyPressEnterNewQuery();
        }
    };

    const inputsFilter: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'description',
            variant: 'standard',
            control: control,
            label: 'Descrição',
            handleOnKeyPress,
        },
        {
            typeInput: 'text',
            name: 'workOrder.code',
            variant: 'standard',
            control: control,
            label: 'Número da O.S.',
            handleOnKeyPress,
        },
        {
            typeInput: 'text',
            name: 'outputProduct.code',
            variant: 'standard',
            control: control,
            label: 'Código da venda',
            handleOnKeyPress,
        },
        {
            typeInput: 'text',
            name: 'customer.name',
            variant: 'standard',
            control: control,
            label: 'Cliente',
            handleOnKeyPress,
        },
        {
            typeInput: 'autocomplete',
            name: 'downloaded',
            variant: 'standard',
            control: control,
            loadingAutocomplete: false,
            setValue: setValue,
            label: 'Fatura baixada',
            options: yesOrNoOrBothEnum,
            handleOnKeyPress,
        },
    ];

    const clearInputsFilter = () => {
        setValue('description', '');
        setValue('outputProduct.code', '');
        setValue('workOrder.code', '');
        setValue('customer', { name: '' });
        setValue('downloaded', { id: '', description: '' });
    };

    const clearFilters = () => {
        const queryObj = queryToObj(query);
        const newQuery = {
            ...queryObj,
            description: null,
            code: null,
            'customer.name': null,
            downloaded: yesOrNoOrBothInstance.getObject('0').id,
        };
        setQuery(objToQuery(newQuery));
        history.push(`?${objToQuery(newQuery)}`);
    };

    const handleClearFilters = (): void => {
        clearInputsFilter();
        clearFilters();
    };

    const handleEdit = (id: string) => {
        history.push(`${paths.providerRegister}/${id}`);
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`provider/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setDeleteRegister(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalAction(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalAction(false);
            setIdDelete('');
        }
    };

    const handleOnChange = () => {
        handleByOnKeyPressEnterNewQuery();
    };

    const emptyRows = () => {
        if (rowsPerPage > rows.length) {
            return rowsPerPage - rows.length;
        }
        return 0;
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDownloadReport}
                open={openModalDownloadReport.open}
                messageDialogTitle={messageDialogTitle}
            />
            <AlertDialog
                handleConfirmation={handleConfirmAction}
                open={openModalAction}
                messageDialogTitle={messageDialogTitle}
                handleCloseProps={handleCloseModalAction}
                messageDetail={messageDialogContent}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }} component="div">
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Faturas
                            </Typography>
                        </div>
                        <div className="header-list-search">
                            <Stack spacing={2} direction="row" width={'100%'}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name="dateInitial"
                                            control={control}
                                            rules={null}
                                            label="Data de início"
                                            setValue={setValue}
                                            fullWidth={true}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            handleChange={(_: any) =>
                                                handleOnChange()
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name="dateFinal"
                                            control={control}
                                            rules={null}
                                            label="Data de fim"
                                            setValue={setValue}
                                            fullWidth={true}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            handleChange={(_: any) =>
                                                handleOnChange()
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>

                    <TableGeneric
                        data={rows}
                        columns={columnsDetPag}
                        minWidth={650}
                    />
                    <br />
                    <div className="info-footer">
                        <div className="footer-buttons"></div>
                        <Typography
                            id="total"
                            component="div"
                            sx={{ fontWeight: 'bold' }}>
                            {`Total:`}
                            <Typography
                                id="total"
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                    color: colors.success,
                                }}>
                                {`Recebido: ${formatNumber(
                                    additionalParams?.received,
                                )}`}
                            </Typography>
                            <Typography
                                id="total"
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                    color: colors.info,
                                }}>
                                {`À receber: ${formatNumber(
                                    additionalParams?.toReceive,
                                )}`}
                            </Typography>
                            <Typography
                                id="total"
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                    color: colors.error,
                                }}>
                                {`Vencido: ${formatNumber(
                                    additionalParams?.overdue,
                                )}`}
                            </Typography>
                            <Typography
                                id="total"
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                }}>
                                {`Geral: ${formatNumber(
                                    additionalParams?.grandTotal,
                                )}`}
                            </Typography>
                        </Typography>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>

            {toggleSearch && (
                <FilterSearch
                    inputs={inputsFilter}
                    handleClearFilters={handleClearFilters}
                    setToggleSearch={setToggleSearch}
                />
            )}
        </div>
    );
};

export default ListEntity;

import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import MainContainer from '../main-container';
import Form from './customerForm';
import List from './customerList';

const Customer: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};
const CustomerList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Customer, CustomerList };

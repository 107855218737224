import { Delete, ModeEdit } from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
    LaborEnum,
    laborFind,
    laborFindDescription,
    optionsLabor,
} from '../../communs/enums/labor';
import { optionsState, StateEnum, stateFind } from '../../communs/enums/state';
import {
    optionsWarrantyTime,
    warrantyTimeFind,
} from '../../communs/enums/warrantyTime';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormAutocompleteMultiple } from '../../componets/form/formAutocompleteMultiple';
import { FormButton } from '../../componets/form/formButton';
import { FormInputDate } from '../../componets/form/formInputDate';
import { FormInputNumber } from '../../componets/form/formInputNumber';
import {
    FormInputText,
    IFormInputProps,
} from '../../componets/form/formInputText';
import { phoneMask } from '../../componets/form/mask/phone';
import { paths } from '../../config';
import { useAuth } from '../../context/AuthContext';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { downloadReport } from '../../services/download-report';
import { formatDate } from '../../util/dateUtil';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { floatValue, formatNumber } from '../../util/infoFormat';
import { useWindowSize } from '../../util/responsiveness';

interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date' | 'autocomplete';
    name: 'description' | 'brand.description' | 'brand.id' | 'labor';
    setError?: any;
    clearErrors?: any;
    required: boolean;
    hidden?: boolean;
    options?: {
        name?: string;
        description?: string;
        id?: string;
        code?: string;
    }[];
    setValue?: any;
}

interface Phone {
    id: string;
    description: string;
}

interface IItemProduct {
    valueOutput: number;
    quantity: number;
    product: {
        id: string;
        code: string;
        description: string;
        quantity: number;
        localization: string;
        brand?: {
            id: string;
            description: string;
        };
    };
    warrantyTime: string;
    warrantyTimeOption?: {
        id: string;
        description: any;
    };
}

interface IItemTechniciaService {
    technicianService: {
        id: string;
        description: string;
        labor: string;
    };
    labor?: string;
    valueService: number;
}

interface HistoricOrder {
    state: {
        id: string;
        state: string;
    };
    createAt: Date;
}

interface IFormInput {
    code?: string;
    customer: {
        id: string;
        name: string;
        phones: Phone[];
    };
    phones?: Phone[];
    technician: {
        id: string;
        name: string;
    };
    warrantyTimeOption?: {
        id: string;
        description: string;
    };
    warrantyTime: string;
    state: string;
    stateOption?: {
        id: string;
        description: string;
    };
    dateEntry: Date;
    dateCompletion?: Date | null;
    modelBrand: {
        id: string;
        description: string;
        brand?: {
            id: string;
            description: string;
        };
    } | null;
    brand?: {
        id: string;
        description: string;
    };
    reportedDefect: string;
    technicalReport?: string;
    serviceValue?: number;
    note?: string;
    itemProductWorkOrders: IItemProduct[];
    itemTechnicianServices: IItemTechniciaService[];
    historicOrder: HistoricOrder[];
}

interface ModalPropsItem {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    setIndex: (index: number) => void;
    getValues: any;
    appendItem: any;
    updateItem: any;
    fieldsItemProduct: any[];
    fieldsItemTechnicianService: any[];
    setValueFormInput: (
        input: 'serviceValue',
        value: number | undefined,
    ) => void;
    setOpenLoading?: (loading: boolean) => void;
    width: number;
}

interface ILoadAutocomplete {
    customerId: string | null;
    technicianId: string | null;
}

interface IInputFormSaved {
    description: string;
    brand: {
        id: string;
        description: string;
    };
    labor: {
        id: string;
        description: string;
    };
}

const defaultValuesSaved = {
    description: '',
};

const messageError = (errors: any, field: any) => {
    // customer
    if (errors && errors.type === 'required' && field === 'customer') {
        return 'O campo cliente é obrigátorio.';
    }

    // technician
    if (errors && errors.type === 'required' && field === 'technician') {
        return 'O campo responsável é obrigátorio.';
    }

    // state
    if (errors && errors.type === 'required' && field === 'state') {
        return 'O campo estado é obrigátorio.';
    }

    // dateEntry
    if (errors && errors.type === 'required' && field === 'dateEntry') {
        return 'O campo data de entrada é obrigátorio.';
    }

    // reportedDefect
    if (errors && errors.type === 'required' && field === 'reportedDefect') {
        return 'O campo defeito informado é obrigátorio.';
    }

    // brand
    if (errors && errors.type === 'custom_brand' && field === 'brand') {
        return errors?.message;
    }

    // brand
    if (errors && errors.type === 'required' && field === 'brand') {
        return 'O campo marca é obrigatório.';
    }

    // modelBrand
    if (errors && errors.type === 'required' && field === 'modelBrand') {
        return 'O campo modelo é obrigatório.';
    }

    // dateCompletion
    if (
        errors &&
        errors.type === 'custom_dateCompletion' &&
        field === 'dateCompletion'
    ) {
        return errors?.message;
    }

    return errors?.message || '';
};

const WorkOrderForm: React.FC = () => {
    const defaultValues = {
        dateEntry: new Date(),
        dateCompletion: null,
        itemProductWorkOrders: [],
        itemTechnicianServices: [],
        phones: [],
    };

    const history = useHistory();
    const params = useParams<'id' | any>();
    const [width] = useWindowSize();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);

    const [options, setOptions] = useState<{ name: string; id: string }[]>([]);
    const [optionTechnicians, setOptionTechnicians] = useState<
        { name: string; id: string }[]
    >([]);
    const [optionBrands, setOptionBrands] = useState<
        { description: string; id: string }[]
    >([]);
    const [optionModelBrands, setOptionModelBrands] = useState<
        { description: string; id: string }[]
    >([]);

    const [openModalItemTechnicianService, setOpenModalItemTechnicianService] =
        useState(false);
    const [indexItemTechnicianService, setIndexItemTechnicianService] =
        useState<number>(-1);
    const [openModalItemProduct, setOpenModalItemProduct] = useState(false);
    const [indexItemProduct, setIndexItemProduct] = useState<number>(-1);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
    const [messageDialogTitle, setMessageDialogTitle] = useState<
        string | undefined
    >(undefined);
    const [openModalDownloadReport, setOpenModalDownloadReport] = useState<{
        open: boolean;
        id?: string;
    }>({ open: false, id: undefined });
    const [optionsPhone, setOptionsPhone] = useState<
        {
            id: string;
            description: string;
        }[]
    >([]);

    const { user } = useAuth();

    const findEnumState = new StateEnum();

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        getValues,
        setError,
        clearErrors,
        watch,
    } = useForm<IFormInput>({
        defaultValues,
    });

    const useFormSaved = useForm<IInputFormSaved>({
        defaultValues: defaultValuesSaved,
    });
    const getValuesSave = useFormSaved.getValues;
    const setValueSave = useFormSaved.setValue;
    const controlSave = useFormSaved.control;
    const setErrorSave = useFormSaved.setError;
    const clearErrorsSave = useFormSaved.clearErrors;

    const rules = {
        customer: {
            required: true,
        },
        technician: {
            required: true,
        },
        warrantyTime: {
            validate: (val: string) => {
                if (
                    !val &&
                    watch('state') &&
                    (findEnumState.getId(watch('state')) == 'ENTREGADO' ||
                        findEnumState.getId(watch('state')) ==
                            'ENTREGADO_SEM_CONSERTO' ||
                        findEnumState.getId(watch('state')) ==
                            'GARANTIA_CONCLUIDA' ||
                        findEnumState.getId(watch('state')) ==
                            'SERVICO_RELIAZADO')
                ) {
                    const stateObj = stateFind(watch('state'));
                    return `Como o estado da ordem é ${stateObj.description}, o campo garantia é obrigatório.`;
                }
            },
        },
        state: {
            required: true,
        },
        dateEntry: {
            required: true,
        },
        dateCompletion: {
            validate: (value: any) => {
                if (
                    !value &&
                    watch('state') &&
                    (findEnumState.getId(watch('state')) == 'ENTREGADO' ||
                        findEnumState.getId(watch('state')) ==
                            'ENTREGADO_SEM_CONSERTO' ||
                        findEnumState.getId(watch('state')) ==
                            'GARANTIA_CONCLUIDA' ||
                        findEnumState.getId(watch('state')) ==
                            'SERVICO_RELIAZADO')
                ) {
                    const stateObj = stateFind(watch('state'));
                    return `Como o estado da ordem é ${stateObj.description}, o campo data de conclusão é obrigatório.`;
                }
                if (
                    value &&
                    value &&
                    moment(watch('dateEntry')).isAfter(moment(value))
                ) {
                    return `A data de conclusão não pode ser menor que a data de entrada.`;
                }
            },
        },
        modelBrand: {
            required: true,
        },
        brand: {
            required: true,
        },
        reportedDefect: {
            required: true,
        },
        technicalReport: {
            validate: (val: string) => {
                if (
                    (!val || val?.length == 0) &&
                    watch('state') &&
                    (findEnumState.getId(watch('state')) == 'ENTREGADO' ||
                        findEnumState.getId(watch('state')) ==
                            'ENTREGADO_SEM_CONSERTO' ||
                        findEnumState.getId(watch('state')) ==
                            'GARANTIA_CONCLUIDA' ||
                        findEnumState.getId(watch('state')) ==
                            'SERVICO_RELIAZADO')
                ) {
                    const stateObj = stateFind(watch('state'));
                    return `Como o estado da ordem é ${stateObj.description}, o campo laúdo técnico é obrigatório.`;
                }
            },
        },
        serviceValue: {
            validate: (val: any) => {
                if (
                    (!val || (val && floatValue(val) <= 0)) &&
                    watch('state') &&
                    (findEnumState.getId(watch('state')) == 'ENTREGADO' ||
                        findEnumState.getId(watch('state')) ==
                            'SERVICO_RELIAZADO')
                ) {
                    const stateObj = stateFind(watch('state'));
                    return `Como o estado da ordem é ${stateObj.description}, o valor do serviço tem que ser maior que zero.`;
                }
            },
        },
    };

    const itemProduct = useFieldArray<
        IFormInput,
        'itemProductWorkOrders',
        'itemProductWorkOrdersId'
    >({
        control,
        name: 'itemProductWorkOrders',
        keyName: 'itemProductWorkOrdersId',
    });
    const fieldsItemProduct = itemProduct.fields;
    const appendItemProduct = itemProduct.append;
    const removeItemProduct = itemProduct.remove;
    const updateItemProduct = itemProduct.update;

    const itemTechnicianService = useFieldArray<
        IFormInput,
        'itemTechnicianServices',
        'itemTechnicianServicesId'
    >({
        control,
        name: 'itemTechnicianServices',
        keyName: 'itemTechnicianServicesId',
    });
    const fieldsItemTechnicianService = itemTechnicianService.fields;
    const appendItemTechnicianService = itemTechnicianService.append;
    const removeItemTechnicianService = itemTechnicianService.remove;
    const updateItemTechnicianService = itemTechnicianService.update;

    const historicOrder = useFieldArray<
        IFormInput,
        'historicOrder',
        'historicOrderId'
    >({
        control,
        name: 'historicOrder',
        keyName: 'historicOrderId',
    });
    const fieldsHistoricOrder = historicOrder.fields;

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get<IFormInput>(`work-order/${params.id}`)
                .then(response => {
                    loadAutocompletes({
                        customerId: response.data.customer.id,
                        technicianId: response.data.technician.id,
                    });
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        } else if (
            user.profiles.filter(t => t.typeProfile === 'Técnico').length > 0
        ) {
            loadAutocompletes({
                customerId: null,
                technicianId: user?.sub,
            });
        }
    }, []);

    useEffect(() => {
        if (optionsPhone.length > 0) {
            setValue('phones', optionsPhone);
        }
    }, [optionsPhone]);

    const setModel = (data: any) => {
        setValue('code', data.code);
        if (data.customer && data.customer.id) {
            const phones: Phone[] = data.customer.phones.map((p: any) => {
                return {
                    id: p.id,
                    description: `${phoneMask(p.phoneNumber, false)}`,
                };
            });
            setOptionsPhone(phones);
            setValue('customer', {
                name: data.customer.name,
                id: data.customer.id,
                phones,
            });
        }
        if (data.technician && data.technician.id) {
            setValue('technician', {
                name: data.technician.name,
                id: data.technician.id,
            });
        }
        const wTime = warrantyTimeFind(data.warrantyTime);
        setValue('warrantyTime', data.warrantyTime);
        setValue('warrantyTimeOption', {
            id: wTime?.id,
            description: wTime?.description,
        });

        const state = stateFind(data.state);
        setValue('state', data.state);
        setValue('stateOption', {
            id: state?.id,
            description: state?.description,
        });

        setValue('state', data.state);
        setValue('dateEntry', data.dateEntry);
        setValue('dateCompletion', data.dateCompletion);
        if (data.modelBrand && data.modelBrand.brand?.id) {
            setValue('brand', {
                description: data.modelBrand.brand.description,
                id: data.modelBrand.brand.id,
            });
        }
        if (data.modelBrand && data.modelBrand.id) {
            setValue('modelBrand', {
                description: data.modelBrand.description,
                id: data.modelBrand.id,
            });
        }
        setValue('reportedDefect', data.reportedDefect);
        setValue('technicalReport', data.technicalReport);
        setValue('serviceValue', data.serviceValue);
        setValue('note', data.note);
        setValue('itemProductWorkOrders', data.itemProductWorkOrders);
        setValue('itemTechnicianServices', data.itemTechnicianServices);
        setValue('historicOrder', data.historicOrder);
    };

    const loadAutocompletes = async ({
        customerId,
        technicianId,
    }: ILoadAutocomplete) => {
        try {
            if (customerId) {
                const responseCustomer = await api.get<{
                    id: string;
                    name: string;
                }>(`customer/${customerId}`);
                const dataCustomer = [responseCustomer.data];
                setOptions(dataCustomer);
            }

            const responseTechnician = await api.get<{
                id: string;
                name: string;
            }>(`users/${technicianId}`);
            const dataTechnician = [responseTechnician.data];
            setOptionTechnicians(dataTechnician);
            if (!params || !params.id) {
                setValue('technician', dataTechnician[0]);
            }
        } catch (error) {
            console.error(error);
            addToast({
                type: 'error',
                title: message.error.selectOne,
            });
        }
    };

    const submit = async (data: IFormInput, e: any) => {
        e.preventDefault();

        delete data.phones;

        const stateEnum = findEnumState.getId(data.state);
        const stateObj = stateFind(data.state);
        let validation = true;

        if (!validation) {
            return;
        }

        if (
            (stateEnum == 'APROVADO' ||
                stateEnum == 'ENTREGADO' ||
                stateEnum == 'ENTREGADO_SEM_CONSERTO' ||
                stateEnum == 'GARANTIA_CONCLUIDA' ||
                stateEnum == 'SERVICO_RELIAZADO') &&
            data.itemTechnicianServices.length == 0
        ) {
            addToast({
                type: 'warn',
                title: 'Informações inválidas',
                description: `Como o estado da Ordem está ${stateObj.description}, é preciso informar ao menos um serviço realizado.`,
            });
            return;
        }

        const enumLabor = new LaborEnum();
        const filterItemTechnicianServiceWithProduct =
            data.itemTechnicianServices.filter(
                i =>
                    enumLabor.getId(i.technicianService.labor) == 'COM_PRODUTO',
            );

        if (
            data.itemTechnicianServices.length > 0 &&
            data.itemProductWorkOrders.length <
                filterItemTechnicianServiceWithProduct.length
        ) {
            addToast({
                type: 'warn',
                title: 'Informações inválidas',
                description:
                    'A quantidade de peças utilizadas tem que ser maior ou igual a quantidade de serviços realizados com produto.',
            });
            return;
        }

        try {
            setOpenLoading(true);
            data.serviceValue = floatValue(data.serviceValue);

            if (data.warrantyTimeOption || data.stateOption || data.brand) {
                delete data.warrantyTimeOption;
                delete data.stateOption;
                delete data.brand;
            }

            let id: string | undefined = undefined;
            if (params && params.id) {
                await api.patch(`work-order/${params.id}`, data);
                id = params.id;
            } else {
                const response = await api.post('work-order', data);
                id = response.data.id;
            }
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);

            if (
                stateEnum == 'ENTREGADO' ||
                stateEnum == 'ENTREGADO_SEM_CONSERTO' ||
                stateEnum == 'GARANTIA_CONCLUIDA' ||
                stateEnum == 'SERVICO_RELIAZADO'
            ) {
                setMessageDialogTitle('Deseja baixar o recibo?');

                setOpenModalDownloadReport(prevState => ({
                    ...prevState,
                    id: id,
                    open: true,
                }));
            } else {
                reset(defaultValues);
                history.push(`${paths.workOrder}?`);
            }
        } catch (error) {
            setOpenLoading(false);
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`work-order/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.workOrder}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const handleConfirmeDownloadReport = async (confirm: boolean) => {
        if (confirm) {
            setOpenLoading(true);
            try {
                await downloadReport({
                    nameReport: 'comp_solicitacao_servico',
                    params: { id: openModalDownloadReport.id },
                });
                setOpenLoading(false);
            } catch (error: any) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.delete,
                    description: messageResponse || error.message,
                });
            }
        }
        setMessageDialogTitle(undefined);
        setOpenModalDownloadReport({ open: false, id: undefined });
        reset(defaultValues);
        history.push(`${paths.workOrder}?`);
    };

    const handleDeleteItemTableTechnicianService = async (index: number) => {
        removeItemTechnicianService(index);
        let serviceValue = fieldsItemProduct.reduce((accumulator, item) => {
            const quantity = Number(item.quantity);
            const valueOutput = Number(item.valueOutput);
            return accumulator + quantity * valueOutput;
        }, 0);

        serviceValue =
            serviceValue +
            fieldsItemTechnicianService.reduce((accumulator, item, i) => {
                const valueOutput = index != i ? Number(item.valueService) : 0;
                return accumulator + valueOutput;
            }, 0);
        setValueFormInput('serviceValue', serviceValue);
    };

    const handleNewItemTableTechnicianService = () => {
        setIndexItemTechnicianService(-1);
        setOpenModalItemTechnicianService(true);
    };

    const handleEditItemTableTechnicianService = (index: any) => {
        setIndexItemTechnicianService(index);
        setOpenModalItemTechnicianService(true);
    };

    const handleDeleteItemTableProduct = async (index: number) => {
        removeItemProduct(index);
        let serviceValue = fieldsItemProduct.reduce((accumulator, item, i) => {
            const quantity = Number(item.quantity);
            const valueOutput =
                index != i ? quantity * Number(item.valueOutput) : 0;
            return accumulator + valueOutput;
        }, 0);

        serviceValue =
            serviceValue +
            fieldsItemTechnicianService.reduce((accumulator, item, i) => {
                const valueOutput = Number(item.valueService);
                return accumulator + valueOutput;
            }, 0);
        setValueFormInput('serviceValue', serviceValue);
    };

    const handleNewItemTableProduct = () => {
        setIndexItemProduct(-1);
        setOpenModalItemProduct(true);
    };

    const handleEditItemTableProduct = (index: any) => {
        setIndexItemProduct(index);
        setOpenModalItemProduct(true);
    };

    const updateAutocompleteCustomer = async (name: any) => {
        if (name && name.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(`customer/detail?name=${name}`);
            setOptions(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const updateAutocompleteTechnician = async (name: any) => {
        if (name && name.length > 0) {
            try {
                setLoadingAutocomplete(true);
                const response = await api.get(`users?name=${name}`);
                setOptionTechnicians(response.data.data);
                setLoadingAutocomplete(false);
            } catch (error) {
                setLoadingAutocomplete(false);
                console.error(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            }
        }
    };

    const updateAutocompleteBrand = async (description: any) => {
        if (description && description.length > 0) {
            try {
                const customerId = getValues('customer')?.id
                    ? `&customer.id=${getValues('customer').id}`
                    : '';
                setLoadingAutocomplete(true);
                const response = await api.get(
                    `brand/detail?brand.description=${description}${customerId}`,
                );
                setOptionBrands(response.data.data);
                setLoadingAutocomplete(false);
            } catch (error) {
                setLoadingAutocomplete(false);
                console.error(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            }
        }
    };

    const updateAutocompleteModelBrand = async (description: any) => {
        if (!getValues('brand')?.id) {
            setError('brand', {
                type: `custom_brand`,
                message: 'O campo marca não foi informado',
            });
        } else if (description && description.length > 0) {
            try {
                const customerId = getValues('customer')?.id
                    ? `&customer.id=${getValues('customer').id}`
                    : '';
                setLoadingAutocomplete(true);
                const response = await api.get(
                    `model-brand/detail?brand.id=${
                        getValues('brand')?.id
                    }&modelBrand.description=${description}${customerId}`,
                );
                setOptionModelBrands(response.data.data);
                setLoadingAutocomplete(false);
            } catch (error) {
                setLoadingAutocomplete(false);
                console.error(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            }
        }
    };

    const onChangeInputWarrantyTime = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option) {
            setValue(`warrantyTime`, option?.id);
        }
    };

    const onChangeInputState = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option) {
            setValue(`state`, option?.id);
            const stateEnum = findEnumState.getId(option.id);

            if (
                (stateEnum == 'ENTREGADO' ||
                    stateEnum == 'ENTREGADO_SEM_CONSERTO' ||
                    stateEnum == 'GARANTIA_CONCLUIDA' ||
                    stateEnum == 'SERVICO_REALIZADO') &&
                !watch('dateCompletion')
            ) {
                setValue('dateCompletion', new Date());
            }
        }
    };

    const onChangeCustomer = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteCustomer(event.target.value);
        }
        if (option && option?.phones?.length > 0) {
            const phones: Phone[] = option.phones.map((p: any) => {
                return {
                    id: p.id,
                    description: `${phoneMask(p.phoneNumber, false)}`,
                };
            });
            setOptionsPhone(phones);

            try {
                const response = await api.get(
                    `brand/customer?customer.id=${option.id}`,
                );

                setOptionBrands(response.data.data);
            } catch (error) {
                console.error(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            }
        }
    };

    const onChangeTechnician = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteTechnician(event.target.value);
        }
    };

    const onChangeBrand = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteBrand(event.target.value);
        }
        if (option) {
            try {
                const customerId = getValues('customer')?.id
                    ? `&customer.id=${getValues('customer').id}`
                    : '';

                const response = await api.get(
                    `model-brand/detail?brand.id=${option.id}${customerId}`,
                );

                setOptionModelBrands(response.data.data);

                if (getValues('modelBrand')?.id) {
                    setValue('modelBrand', null);
                }
            } catch (error) {
                console.error(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            }

            setValueSave('brand', {
                id: option.id,
                description: option.description,
            });
        }
    };

    const onChangeModelBrand = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteModelBrand(event.target.value);
        }
        if (option) {
            clearErrors('brand');
        }
    };

    const setValueFormInput = (
        input: 'serviceValue',
        value: number | undefined,
    ) => {
        setValue(input, value);
    };

    const inputsSaveBrand: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlSave,
            label: 'Descrição',
            autoFocus: true,
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
            required: true,
        },
    ];

    const inputsSaveModelBrand: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlSave,
            label: 'Descrição',
            autoFocus: true,
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
            required: true,
        },
        {
            typeInput: 'text',
            name: 'brand.description',
            control: controlSave,
            label: 'Marca',
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
            required: false,
            readOnly: true,
        },
        {
            typeInput: 'text',
            name: 'brand.id',
            control: controlSave,
            label: 'Id',
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
            required: false,
            readOnly: false,
            hidden: true,
        },
    ];

    const actionSaveBrand = async () => {
        if (getValuesSave('description')) {
            let dataSave: any = {};
            for (const key in inputsSaveBrand) {
                const input = inputsSaveBrand[key];
                dataSave = {
                    ...dataSave,
                    [input.name]: getValuesSave(input.name),
                };
            }

            try {
                setOpenLoading(true);
                const response = await api.post('brand', {
                    ...dataSave,
                });
                setValueSave('brand', {
                    id: response.data.id,
                    description: response.data.description,
                });
                addToast({
                    type: 'success',
                    title: message.success.save,
                    description: 'Marca adicionada',
                });
                setOpenLoading(false);
                return response.data;
            } catch (error) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
                console.log(error);
            }
        }
        return {};
    };

    const actionSaveModelBrand = async () => {
        if (getValuesSave('description')) {
            let dataSave: any = {};
            for (const key in inputsSaveModelBrand) {
                const input = inputsSaveModelBrand[key];
                dataSave = {
                    ...dataSave,
                    [input.name]: getValuesSave(input.name),
                };
            }

            const brand = {
                id: dataSave['brand.id'],
                description: dataSave['brand.description'],
            };
            delete dataSave['brand.id'];
            delete dataSave['brand.description'];
            dataSave = {
                ...dataSave,
                brand,
            };
            try {
                setOpenLoading(true);
                const response = await api.post('model-brand', {
                    ...dataSave,
                });
                addToast({
                    type: 'success',
                    title: message.success.save,
                    description: 'Modelo adicionado',
                });
                setOpenLoading(false);
                return response.data;
            } catch (error) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
                console.log(error);
            }
        }
        return {};
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDownloadReport}
                open={openModalDownloadReport.open}
                messageDialogTitle={messageDialogTitle}
            />
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(submit)}>
                    <Grid container spacing={2}>
                        {params && params.id && (
                            <Grid item xs={12}>
                                <Typography
                                    sx={{ flex: '1 1 100%', fontSize: '1rem' }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div">
                                    {getValues('code')
                                        ? `Código da O.S.: ${getValues('code')}`
                                        : ''}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item md={4} xs={12}>
                            <FormAutocompleteInitialized
                                name="customer"
                                rules={rules.customer}
                                control={control}
                                label="Cliente"
                                messageError={messageError}
                                options={options}
                                loading={loadingAutocomplete}
                                activeDebounce={true}
                                setValue={setValue}
                                handleChange={onChangeCustomer}
                                mask="identification"
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <FormAutocompleteMultiple
                                readOnly={true}
                                name="phones"
                                control={control}
                                label="Telefones"
                                messageError={messageError}
                                options={optionsPhone}
                                setValue={setValue}
                                getValues={getValues}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name="technician"
                                rules={rules.technician}
                                control={control}
                                label="Responsável"
                                messageError={messageError}
                                options={optionTechnicians}
                                loading={loadingAutocomplete}
                                activeDebounce={true}
                                setValue={setValue}
                                handleChange={onChangeTechnician}
                            />
                        </Grid>
                        {/* {Segunda linha} */}
                        <Grid item md={3} xs={12}>
                            <FormAutocompleteInitialized
                                name={`warrantyTimeOption`}
                                rules={rules.warrantyTime}
                                control={control}
                                label="Garantia"
                                options={optionsWarrantyTime()}
                                loading={false}
                                setValue={setValue}
                                handleChange={onChangeInputWarrantyTime}
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormAutocompleteInitialized
                                name={`stateOption`}
                                rules={rules.state}
                                control={control}
                                label="Estado"
                                options={optionsState()}
                                loading={false}
                                setValue={setValue}
                                handleChange={onChangeInputState}
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormInputDate
                                name="dateEntry"
                                control={control}
                                rules={null}
                                label="Data de entrada"
                                messageError={messageError}
                                setValue={setValue}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormInputDate
                                name="dateCompletion"
                                control={control}
                                rules={rules.dateCompletion}
                                label="Data de conclusão"
                                messageError={messageError}
                                setValue={setValue}
                                fullWidth={true}
                            />
                        </Grid>
                        {/* {Outra linha} */}
                        <Grid item xs={12}>
                            <Typography
                                sx={{ flex: '1 1 100%', fontSize: '16px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Estados da O.S.:
                            </Typography>
                        </Grid>
                        {/* {Outra linha} */}
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Estado</TableCell>
                                            <TableCell align="center">
                                                Data da alteração de estado
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fieldsHistoricOrder.map(
                                            (item, index) => (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row">
                                                        {
                                                            stateFind(
                                                                item.state
                                                                    .state,
                                                            )?.description
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {formatDate(
                                                            item.createAt,
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ),
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {/* {Terceira linha} */}
                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name={`brand`}
                                rules={rules.brand}
                                control={control}
                                label="Marca"
                                options={optionBrands}
                                loading={loadingAutocomplete}
                                activeDebounce={true}
                                setValue={setValue}
                                handleChange={onChangeBrand}
                                messageError={messageError}
                                actionSave={actionSaveBrand}
                                getValuesSave={getValuesSave}
                                setValueSave={setValueSave}
                                arrInput={inputsSaveBrand}
                                dialogTitle={'Adicionar marca'}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name={`modelBrand`}
                                rules={rules.modelBrand}
                                control={control}
                                label="Modelo"
                                options={optionModelBrands}
                                loading={loadingAutocomplete}
                                setValue={setValue}
                                handleChange={onChangeModelBrand}
                                activeDebounce={true}
                                messageError={messageError}
                                actionSave={actionSaveModelBrand}
                                getValuesSave={getValuesSave}
                                setValueSave={setValueSave}
                                arrInput={inputsSaveModelBrand}
                                dialogTitle={'Adicionar modelo'}
                            />
                        </Grid>
                        {/* {Quarta linha} */}
                        <Grid item xs={12}>
                            <FormInputText
                                name="reportedDefect"
                                control={control}
                                rules={rules.reportedDefect}
                                label="Defeito informado"
                                messageError={messageError}
                                multiline={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    flex: '1 1 100%',
                                    fontSize: '16px',
                                    pb: 1,
                                }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                <FormButton
                                    label={'Adicionar'}
                                    typeButton={'addRegister'}
                                    onClick={() =>
                                        handleNewItemTableTechnicianService()
                                    }
                                />
                            </Typography>
                            <Typography
                                sx={{ flex: '1 1 100%', fontSize: '16px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Serviços realizados:
                            </Typography>
                        </Grid>
                        {/* {outra linha} */}
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Descrição</TableCell>
                                            <TableCell align="center">
                                                Mão de obra
                                            </TableCell>
                                            <TableCell align="center">
                                                Valor
                                            </TableCell>
                                            <TableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fieldsItemTechnicianService.map(
                                            (item, index) => (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        onClick={event =>
                                                            handleEditItemTableTechnicianService(
                                                                index,
                                                            )
                                                        }>
                                                        {
                                                            item
                                                                .technicianService
                                                                .description
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        onClick={event =>
                                                            handleEditItemTableTechnicianService(
                                                                index,
                                                            )
                                                        }>
                                                        {
                                                            laborFind(
                                                                item
                                                                    .technicianService
                                                                    .labor,
                                                            )?.description
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        onClick={event =>
                                                            handleEditItemTableTechnicianService(
                                                                index,
                                                            )
                                                        }>
                                                        {formatNumber(
                                                            item.valueService,
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            onClick={() =>
                                                                handleEditItemTableTechnicianService(
                                                                    index,
                                                                )
                                                            }>
                                                            <ModeEdit />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => {
                                                                handleDeleteItemTableTechnicianService(
                                                                    index,
                                                                );
                                                            }}>
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ),
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {/* {outra linha} */}
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="technicalReport"
                                rules={rules.technicalReport}
                                control={control}
                                label="Laúdo técnico"
                                messageError={messageError}
                                multiline={true}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="note"
                                control={control}
                                label="Observação"
                                messageError={messageError}
                                multiline={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    flex: '1 1 100%',
                                    fontSize: '16px',
                                    pb: 1,
                                }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                <FormButton
                                    label={'Adicionar'}
                                    typeButton={'addRegister'}
                                    onClick={() => handleNewItemTableProduct()}
                                />
                            </Typography>
                            <Typography
                                sx={{ flex: '1 1 100%', fontSize: '16px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Peças utilizadas na ordem de serviço:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Produto</TableCell>
                                            <TableCell>Marca</TableCell>
                                            <TableCell>Localização</TableCell>
                                            <TableCell>Garantia</TableCell>
                                            <TableCell align="center">
                                                Valor pago&nbsp;(R$)
                                            </TableCell>
                                            <TableCell align="center">
                                                Quantidade
                                            </TableCell>
                                            <TableCell align="center">
                                                Sub total
                                            </TableCell>
                                            <TableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fieldsItemProduct.map(
                                            (item, index) => (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        onClick={event =>
                                                            handleEditItemTableProduct(
                                                                index,
                                                            )
                                                        }>
                                                        {item.product?.code}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        onClick={event =>
                                                            handleEditItemTableProduct(
                                                                index,
                                                            )
                                                        }>
                                                        {
                                                            item.product
                                                                ?.description
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        onClick={event =>
                                                            handleEditItemTableProduct(
                                                                index,
                                                            )
                                                        }>
                                                        {item.product?.brand
                                                            ?.description || ''}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        onClick={event =>
                                                            handleEditItemTableProduct(
                                                                index,
                                                            )
                                                        }>
                                                        {
                                                            item.product
                                                                ?.localization
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        onClick={event =>
                                                            handleEditItemTableProduct(
                                                                index,
                                                            )
                                                        }>
                                                        {
                                                            warrantyTimeFind(
                                                                item.warrantyTime,
                                                            )?.description
                                                        }
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        onClick={event =>
                                                            handleEditItemTableProduct(
                                                                index,
                                                            )
                                                        }>
                                                        {formatNumber(
                                                            item.valueOutput,
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        onClick={event =>
                                                            handleEditItemTableProduct(
                                                                index,
                                                            )
                                                        }>
                                                        {item.quantity}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        onClick={event =>
                                                            handleEditItemTableProduct(
                                                                index,
                                                            )
                                                        }>
                                                        {item?.valueOutput &&
                                                        item.quantity
                                                            ? formatNumber(
                                                                  item.valueOutput *
                                                                      item.quantity,
                                                              )
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            onClick={() =>
                                                                handleEditItemTableProduct(
                                                                    index,
                                                                )
                                                            }>
                                                            <ModeEdit />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => {
                                                                handleDeleteItemTableProduct(
                                                                    index,
                                                                );
                                                            }}>
                                                            <Delete />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ),
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormInputNumber
                                name={`serviceValue`}
                                rules={rules.serviceValue}
                                control={control}
                                label="Valor total"
                                decimalScale={2}
                                messageError={messageError}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
                <ModalItemTechnicianService
                    open={openModalItemTechnicianService}
                    setOpen={setOpenModalItemTechnicianService}
                    index={indexItemTechnicianService}
                    setIndex={setIndexItemTechnicianService}
                    getValues={getValues}
                    appendItem={appendItemTechnicianService}
                    updateItem={updateItemTechnicianService}
                    fieldsItemTechnicianService={fieldsItemTechnicianService}
                    fieldsItemProduct={fieldsItemProduct}
                    setValueFormInput={setValueFormInput}
                    setOpenLoading={setOpenLoading}
                    width={width}
                />
                <ModalItemProduct
                    open={openModalItemProduct}
                    setOpen={setOpenModalItemProduct}
                    index={indexItemProduct}
                    setIndex={setIndexItemProduct}
                    getValues={getValues}
                    appendItem={appendItemProduct}
                    updateItem={updateItemProduct}
                    fieldsItemProduct={fieldsItemProduct}
                    fieldsItemTechnicianService={fieldsItemTechnicianService}
                    setValueFormInput={setValueFormInput}
                    width={width}
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalItemTechnicianService({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    updateItem,
    appendItem,
    fieldsItemTechnicianService,
    fieldsItemProduct,
    setValueFormInput,
    setOpenLoading,
    width,
}: ModalPropsItem) {
    const [optionsAutocomplete, setOptionsAutocomplete] = useState<
        { description: string; id: string; labor?: string }[]
    >([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
    const [focusTechnicianService, setFocusTechnicianService] = useState(false);
    const [focusValueService, setFocusValueService] = useState(false);

    const { addToast } = useToast();

    const defaultValuesItems = {};

    const rulesItems = {
        technicianService: {
            required: true,
        },
        labor: {
            required: true,
        },
        valueService: {
            required: true,
        },
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const messageErrorItems = (errors: any, field: any) => {
        // technicianService
        if (
            errors &&
            errors.type === 'required' &&
            field === 'technicianService'
        ) {
            return 'O campo serviço é obrigátorio.';
        }
        if (errors && errors.type === 'required' && field === 'valueService') {
            return 'O campo valor é obrigátorio.';
        }
        return errors?.message || '';
    };

    const useFormItems = useForm<IItemTechniciaService>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    const useFormSaved = useForm<IInputFormSaved>({
        defaultValues: defaultValuesSaved,
    });
    const getValuesSave = useFormSaved.getValues;
    const setValueSave = useFormSaved.setValue;
    const controlSave = useFormSaved.control;
    const setErrorSave = useFormSaved.setError;
    const clearErrorsSave = useFormSaved.clearErrors;

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
        setFocusTechnicianService(true);
    }, [index, open]);

    const setModel = (index: number) => {
        setValueItems(
            'technicianService',
            getValues(`itemTechnicianServices[${index}].technicianService`),
        );
        const labor = getValues(
            `itemTechnicianServices[${index}].technicianService`,
        )?.labor;

        if (labor) {
            setValueItems('labor', laborFind(labor)?.description);
        }
        setValueItems(
            'valueService',
            getValues(`itemTechnicianServices[${index}].valueService`),
        );
    };

    const handleClose = () => {
        setIndex(-1);
        setOpen(false);
        reset(defaultValuesItems);
    };

    const handleUpdateTotal = (
        fieldsProduts: any[],
        fieldsTechnicianService: any[],
        index: number,
        data: IItemTechniciaService,
    ) => {
        if (index >= 0) {
            fieldsTechnicianService[index] = {
                ...data,
            };
        } else {
            fieldsTechnicianService.push(data);
        }
        let serviceValue = fieldsProduts.reduce((accumulator, item) => {
            const quantity = Number(item.quantity);
            const valueOutput = Number(item.valueOutput);
            return accumulator + quantity * valueOutput;
        }, 0);

        serviceValue =
            serviceValue +
            fieldsTechnicianService.reduce((accumulator, item) => {
                const valueOutput = Number(item.valueService);
                return accumulator + valueOutput;
            }, 0);
        setValueFormInput('serviceValue', serviceValue);
    };

    const updateAutocomplete = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(
                `technician-service?description=${description}`,
            );
            setOptionsAutocomplete(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const onChangeInputTechnicianService = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.labor) {
            setValueItems('labor', option.labor);
            const labor = option.labor;

            if (labor) {
                setValueItems('labor', laborFind(labor)?.description);
            }
            setFocusValueService(true);
        } else if (!option) {
            updateAutocomplete(event.target.value);
        }
    };

    const onBlurTechnicianService = (event: any) => {
        setFocusTechnicianService(false);
    };

    const onBlurValueService = (event: any) => {
        setFocusValueService(false);
    };

    const submitItem = (data: IItemTechniciaService) => {
        const repeatItem = fieldsItemTechnicianService
            ? fieldsItemTechnicianService.filter(
                  (f, i) =>
                      index != i &&
                      f.technicianService.id == data.technicianService.id,
              )
            : [];

        if (repeatItem.length > 0) {
            addToast({
                type: 'warn',
                title: 'Item repedito',
                description: 'O item já foi informado.',
            });
            return;
        }

        if (!data.technicianService.labor && data.labor) {
            const findLabor = laborFindDescription(data.labor);
            if (findLabor.id) {
                data.technicianService = {
                    ...data.technicianService,
                    labor: findLabor.id,
                };
            }
        }

        data.valueService = floatValue(data.valueService);
        if (index >= 0) {
            updateItem(index, {
                ...data,
            });
        } else {
            appendItem(data);
        }
        handleUpdateTotal(
            fieldsItemProduct,
            fieldsItemTechnicianService,
            index,
            data,
        );
        handleClose();
    };

    const inputsSaveModelTechnicianService: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlSave,
            label: 'Descrição',
            autoFocus: true,
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
            required: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'labor',
            options: optionsLabor(),
            control: controlSave,
            label: 'Mão de obra',
            required: true,
            setValue: setValueSave,
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
        },
    ];

    const actionSaveTechnicianService = async () => {
        if (getValuesSave('description')) {
            let dataSave: any = {};
            for (const key in inputsSaveModelTechnicianService) {
                const input = inputsSaveModelTechnicianService[key];
                dataSave = {
                    ...dataSave,
                    [input.name]: getValuesSave(input.name),
                };
            }

            const labor = dataSave.labor.id;
            delete dataSave.labor.id;
            delete dataSave.labor.description;
            dataSave = {
                ...dataSave,
                labor,
            };

            try {
                setOpenLoading && setOpenLoading(true);
                const response = await api.post('technician-service', {
                    ...dataSave,
                });
                setValueItems(
                    'labor',
                    laborFind(response.data.labor)?.description,
                );
                addToast({
                    type: 'success',
                    title: message.success.save,
                    description: 'Serviço adicionado',
                });
                setOpenLoading && setOpenLoading(false);
                return response.data;
            } catch (error) {
                setOpenLoading && setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
                console.log(error);
            }
        }
        return {};
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Inserir serviço
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <Grid item md={8} xs={12}>
                                <FormAutocompleteInitialized
                                    name={`technicianService`}
                                    rules={rulesItems.technicianService}
                                    control={controlItems}
                                    label="Serviço"
                                    options={optionsAutocomplete}
                                    loading={loadingAutocomplete}
                                    setValue={setValueItems}
                                    handleChange={
                                        onChangeInputTechnicianService
                                    }
                                    messageError={messageErrorItems}
                                    autoFocus={focusTechnicianService}
                                    handleOnBlur={onBlurTechnicianService}
                                    actionSave={actionSaveTechnicianService}
                                    getValuesSave={getValuesSave}
                                    setValueSave={setValueSave}
                                    arrInput={inputsSaveModelTechnicianService}
                                    dialogTitle={'Adicionar serviço'}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormInputText
                                    readOnly={true}
                                    name="labor"
                                    control={controlItems}
                                    label="Mão de obra"
                                    messageError={messageError}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <FormInputNumber
                                    name={`valueService`}
                                    rules={rulesItems.valueService}
                                    control={controlItems}
                                    label="Valor"
                                    decimalScale={2}
                                    messageError={messageErrorItems}
                                    autoFocus={focusValueService}
                                    handleOnBlur={onBlurValueService}
                                />
                            </Grid>
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

function ModalItemProduct({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    updateItem,
    appendItem,
    fieldsItemProduct,
    fieldsItemTechnicianService,
    setValueFormInput,
    width,
}: ModalPropsItem) {
    const [optionsAutocomplete, setOptionsAutocomplete] = useState<
        { description: string; id: string; saleValue?: number }[]
    >([]);
    const [focusValueOutput, setFocusValueOutput] = useState(false);
    const [focusProductOption, setFocusProductOption] = useState(false);
    const [focusWarrantyTimeOption, setFocusWarrantyTimeOption] =
        useState(false);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const { addToast } = useToast();

    const defaultValuesItems = {
        valueOutput: undefined,
        quantity: undefined,
        warrantyTime: '',
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const extraValidationOutputNumber = (valueInput: string | number) => {
        if (typeof valueInput === 'number') {
            if (valueInput <= 0.0) {
                return false;
            }
        }
        if (typeof valueInput === 'string') {
            valueInput = valueInput.replace(',', '.');
            valueInput = Number(valueInput);
            if (valueInput <= 0.0) {
                return false;
            }
        }
        return true;
    };

    const rulesItems = {
        valueOutput: {
            required: true,
            validate: extraValidationOutputNumber,
        },
        quantity: {
            required: true,
            min: 1,
        },
        warrantyTime: {
            required: true,
        },
        product: {
            required: true,
        },
    };

    const messageErrorItems = (errors: any, field: any) => {
        // valueOutput
        if (errors && errors.type === 'required' && field === 'valueOutput') {
            return 'O campo valor de saída é obrigátorio.';
        }
        if (errors && errors.type === 'validate' && field === 'valueOutput') {
            return 'O campo valor de saída tem que ser maior que 0,00.';
        }

        // quantity
        if (errors && errors.type === 'required' && field === 'quantity') {
            return 'O campo quantidade é obrigátorio.';
        }
        if (errors && errors.type === 'min' && field === 'quantity') {
            return 'O campo quantidade tem que ser maior que 0.';
        }

        // warrantyTime
        if (
            errors &&
            errors.type === 'required' &&
            field === 'warrantyTimeOption'
        ) {
            return 'O campo garantia é obrigátorio.';
        }

        // product
        if (errors && errors.type === 'required' && field === 'product') {
            return 'O campo produto é obrigátorio.';
        }
        return '';
    };

    const useFormItems = useForm<IItemProduct>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
        setFocusProductOption(true);
    }, [index, open]);

    const setModel = (index: number) => {
        setValueItems(
            'product',
            getValues(`itemProductWorkOrders[${index}].product`),
        );
        const warrantyTimeId = getValues(
            `itemProductWorkOrders[${index}].warrantyTime`,
        );
        const wTime = warrantyTimeFind(warrantyTimeId);
        setValueItems('warrantyTime', warrantyTimeId);
        setValueItems('warrantyTimeOption', {
            id: wTime?.id,
            description: wTime?.description,
        });
        setValueItems(
            'valueOutput',
            getValues(`itemProductWorkOrders[${index}].valueOutput`),
        );
        setValueItems(
            'quantity',
            getValues(`itemProductWorkOrders[${index}].quantity`),
        );
    };

    const handleClose = () => {
        setIndex(-1);
        setOpen(false);
        reset(defaultValuesItems);
    };

    const handleUpdateTotal = (
        fieldsProduts: any[],
        fieldsTechnicianService: any[],
        index: number,
        data: IItemProduct,
    ) => {
        if (index >= 0) {
            fieldsProduts[index] = {
                ...data,
            };
        } else {
            fieldsProduts.push(data);
        }
        let serviceValue = fieldsProduts.reduce((accumulator, item) => {
            const quantity = Number(item.quantity);
            const valueOutput = Number(item.valueOutput);
            return accumulator + quantity * valueOutput;
        }, 0);

        serviceValue =
            serviceValue +
            fieldsTechnicianService.reduce((accumulator, item) => {
                const valueOutput: Number = Number(item.valueService);
                return accumulator + valueOutput;
            }, 0);
        setValueFormInput('serviceValue', serviceValue);
    };

    const updateAutocomplete = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(
                `product/detail/entry?description=${description}`,
            );
            setOptionsAutocomplete(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const onChangeInputProduct = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.saleValue) {
            setValueItems('valueOutput', Number(option.saleValue));
            if (option.used) {
                const wTime = warrantyTimeFind('0');
                setValueItems('warrantyTime', '0');
                setValueItems('warrantyTimeOption', wTime);
                setFocusValueOutput(true);
                setTimeout(() => setFocusValueOutput(false), 500);
            } else {
                const wTime = warrantyTimeFind('3');
                setValueItems('warrantyTime', '3');
                setValueItems('warrantyTimeOption', wTime);
                setFocusWarrantyTimeOption(true);
                setTimeout(() => setFocusWarrantyTimeOption(false), 500);
            }
        } else if (!option) {
            updateAutocomplete(event.target.value);
        }
    };

    const onBlurInputProduct = (event: any) => {
        setFocusProductOption(false);
    };

    const onChangeInputWarrantyTime = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            setFocusWarrantyTimeOption(false);
        }
        setValueItems(`warrantyTime`, option?.id);
    };

    const onBlurInputWarrantyTime = (event: any) => {
        setFocusWarrantyTimeOption(false);
    };

    const submitItem = (data: IItemProduct) => {
        data.valueOutput = floatValue(data.valueOutput);
        delete data.warrantyTimeOption;
        data.warrantyTime = data.warrantyTime;

        const repeatItem = fieldsItemProduct
            ? fieldsItemProduct.filter(
                  (f, i) => index != i && f.product.id == data.product.id,
              )
            : [];

        if (repeatItem.length > 0) {
            addToast({
                type: 'warn',
                title: 'Item repedito',
                description: 'O item já foi informado.',
            });
            return;
        }

        if (data.product.quantity < Number(data.quantity)) {
            addToast({
                type: 'warn',
                title: 'Sem estoque',
                description: `Tem apenas ${data.product.quantity} unidade(s)`,
            });
            return;
        }

        if (index >= 0) {
            updateItem(index, {
                ...data,
            });
        } else {
            appendItem(data);
        }

        handleUpdateTotal(
            fieldsItemProduct,
            fieldsItemTechnicianService,
            index,
            data,
        );
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Inserir item
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <FormAutocompleteInitialized
                                    name={`product`}
                                    rules={rulesItems.product}
                                    control={controlItems}
                                    label="Produto"
                                    options={optionsAutocomplete}
                                    loading={loadingAutocomplete}
                                    setValue={setValueItems}
                                    handleChange={onChangeInputProduct}
                                    messageError={messageErrorItems}
                                    handleOnBlur={onBlurInputProduct}
                                    autoFocus={focusProductOption}
                                    activeDebounce={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormAutocompleteInitialized
                                    name={`warrantyTimeOption`}
                                    rules={rulesItems.warrantyTime}
                                    control={controlItems}
                                    label="Garantia"
                                    options={optionsWarrantyTime()}
                                    loading={false}
                                    setValue={setValueItems}
                                    handleChange={onChangeInputWarrantyTime}
                                    messageError={messageErrorItems}
                                    handleOnBlur={onBlurInputWarrantyTime}
                                    autoFocus={focusWarrantyTimeOption}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`valueOutput`}
                                    rules={rulesItems.valueOutput}
                                    control={controlItems}
                                    label="Valor de saída"
                                    decimalScale={2}
                                    messageError={messageErrorItems}
                                    autoFocus={focusValueOutput}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`quantity`}
                                    rules={rulesItems.quantity}
                                    control={controlItems}
                                    label="Quantidade"
                                    decimalScale={0}
                                    messageError={messageErrorItems}
                                />
                            </Grid>
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default WorkOrderForm;

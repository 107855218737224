export const message = {
    error: {
        delete: 'Erro ao excluír registro',
        selectAll: 'Erro ao selecionar registros',
        selectOne: 'Erro ao selecionar registro',
        save: 'Erro ao salvar registro',
        login: 'Erro ao fazer login',
        downloadPdf: 'Erro ao baixar arquivo',
    },
    success: {
        delete: 'Registro excluído com sucesso',
        selectAll: '',
        selectOne: '',
        save: 'Registro salvo com sucesso',
    },
    warn: {
        save: 'A regra para salvar o registro não foi atendida',
    },
};

import MainContainer from '../main-container';
import Form from './technicianServiceForm';
import List from './technicianServiceList';

const TechnicianService: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const TechnicianServiceList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { TechnicianService, TechnicianServiceList };

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Grid,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormInputNumber } from '../../componets/form/formInputNumber';
import {
    FormInputText,
    IFormInputProps,
} from '../../componets/form/formInputText';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { floatValue } from '../../util/infoFormat';
import { findVal } from '../../util/findValueObjectByKey';
import axios from 'axios';
import { cepMask } from '../../componets/form/mask/cep';
import { phoneMask } from '../../componets/form/mask/phone';

interface IFormInput {
    installmentMaturity: number;
    inventoryLessThan: number;
    awardValue: number;
    provider: {
        id: string;
        description: string;
    };
    appSecret: string;
    appKey: string;
    ie: string;
    publicPlace: string;
    number: string;
    district?: {
        id: string;
        description: string;
        cityIbgeId: string;
    };
    city?:
        | {
              id: string;
              description: string;
          }
        | '';
    state?: {
        id: string;
        description: string;
    };
    cep?: string;
    phoneDdd: string;
    phoneNumber: string;
    email: string;
}

interface IInputFormSaved {
    description: string;
    city: {
        id: string;
        description: string;
    };
}
interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date' | 'autocomplete';
    name: 'description' | 'city';
    setError?: any;
    clearErrors?: any;
    required: boolean;
    hidden?: boolean;
    options?: {
        name?: string;
        description?: string;
        id?: string;
        code?: string;
    }[];
    setValue?: any;
    setFocusFirstField?: (value: boolean) => void;
}

interface Expanded {
    address: boolean;
}

const defaultValues = {
    inventoryLessThan: undefined,
    awardValue: undefined,
};

const defaultValuesSaved = {
    description: '',
};

const messageError = (errors: any, field: any) => {
    // inventoryLessThan
    if (errors && errors.type === 'required' && field === 'inventoryLessThan') {
        return 'O campo notificação com estoque menor que é obrigátorio.';
    }

    // awardValue
    if (errors && errors.type === 'required' && field === 'awardValue') {
        return 'O campo valor de premiação para cliente é obrigátorio.';
    }

    // providerId
    if (errors && errors.type === 'required' && field === 'provider') {
        return 'O campo fornecedor é obrigátorio.';
    }
    return '';
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [options, setOptions] = useState<
        { description: string; id: string; codeProvider: string }[]
    >([]);
    const [expanded, setExpaded] = useState<Expanded>({
        address: false,
    });
    const [optionsDistrict, setOptionsDistrict] = useState([]);

    const [optionsState, setOptionsState] = useState<
        { id: string; description: string; initials: string }[]
    >([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
    const [focusDescriptionSaveDistrict, setFocusDescriptionSaveDistrict] =
        useState(true);

    const extraValidationPublicPlace = (value: string) => {
        if (expanded.address && (!value || value.length === 0)) {
            return `O campo logradouro é obrigatório.`;
        }
    };
    const [optionsCity, setOptionsCity] = useState([]);

    const extraValidationCity = (value: any) => {
        if (expanded.address && !value) {
            return `O campo cidade é obrigatório.`;
        }
    };

    const extraValidationState = (value: any) => {
        if (expanded.address && !value) {
            return `O campo estado é obrigatório.`;
        }
    };

    const extraValidationDistrict = (value: any) => {
        if (expanded.address && !value) {
            return `O campo bairro é obrigatório.`;
        }
    };

    const extraValidationCep = (value: any) => {
        if (expanded.address && !value) {
            return `O campo CEP é obrigatório.`;
        }
    };

    const rules = {
        inventoryLessThan: {
            required: true,
        },
        awardValue: {
            required: true,
        },
        installmentMaturity: {
            required: true,
        },
        provider: {
            required: true,
        },
        publicPlace: {
            validate: extraValidationPublicPlace,
        },
        district: {
            validate: extraValidationDistrict,
        },
        city: {
            validate: extraValidationCity,
        },
        state: {
            validate: extraValidationState,
        },
        cep: {
            validate: extraValidationCep,
        },
        email: {
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        },
        phoneDdd: {
            required: true,
            minLength: 3,
            maxLength: 3,
        },
        phoneNumber: {
            required: true,
        },
    };

    useEffect(() => {
        api.get(`provider?limit=1000`)
            .then(response => {
                const data = response.data.data.map((d: any) => {
                    return {
                        description: d.description,
                        id: d.id,
                    };
                });
                setOptions(data);
            })
            .catch(e => {
                console.error(e);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            });
    }, []);

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`configuration/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    useEffect(() => {
        if (expanded.address) {
            if (!getValues('phoneDdd')) {
                setValue('phoneDdd', '047');
            }
            updateState();
            const district = getValues('district');
            if (
                district &&
                district != undefined &&
                String(district?.cityIbgeId)
            ) {
                setModelAddress(district);
            } else {
                updateCity('42');
            }
        }
    }, [expanded]);

    const { handleSubmit, control, reset, setValue, getValues } =
        useForm<IFormInput>({
            defaultValues,
        });

    const useFormSaved = useForm<IInputFormSaved>({
        defaultValues: defaultValuesSaved,
    });
    const getValuesSave = useFormSaved.getValues;
    const setValueSave = useFormSaved.setValue;
    const controlSave = useFormSaved.control;
    const setErrorSave = useFormSaved.setError;
    const clearErrorsSave = useFormSaved.clearErrors;

    const inputsSaveDistrict: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlSave,
            label: 'Descrição',
            autoFocus: focusDescriptionSaveDistrict,
            setFocusFirstField: setFocusDescriptionSaveDistrict,
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
            required: true,
        },
        {
            typeInput: 'autocomplete',
            name: 'city',
            options: optionsCity,
            control: controlSave,
            label: 'Cidade',
            required: true,
            readOnly: false,
            setValue: setValueSave,
            setError: setErrorSave,
            clearErrors: clearErrorsSave,
        },
    ];

    const actionSaveDistrict = async () => {
        if (getValuesSave('description')) {
            let dataSave: any = {};
            for (const key in inputsSaveDistrict) {
                const input = inputsSaveDistrict[key];
                dataSave = {
                    ...dataSave,
                    [input.name]: getValuesSave(input.name),
                };
            }

            const cityIbgeId = dataSave.city.id;
            const city = {
                ...dataSave.city,
            };
            delete dataSave.city;

            dataSave = {
                ...dataSave,
                cityIbgeId,
                cityName: `${city.description}-${city.UF.sigla}`,
            };

            try {
                setOpenLoading(true);
                const response = await api.post('district', {
                    ...dataSave,
                });

                setValue('city', city);
                setValue('state', {
                    id: city.UF.id,
                    description: city.UF.nome,
                });
                addToast({
                    type: 'success',
                    title: message.success.save,
                    description: 'Bairro adicionadado',
                });
                setOpenLoading(false);
                return response.data;
            } catch (error) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
                console.log(error);
            }
        }
        return {};
    };

    const setModel = (data: IFormInput) => {
        setValue('installmentMaturity', data.installmentMaturity);
        setValue('inventoryLessThan', data.inventoryLessThan);
        setValue('awardValue', data.awardValue);
        setValue('appSecret', data.appSecret);
        setValue('appKey', data.appKey);
        setValue('ie', data.ie);
        if (data.provider && data.provider.id) {
            setValue('provider', {
                description: data.provider.description,
                id: data.provider.id,
            });
        }
        setValue('publicPlace', data.publicPlace);
        setValue('number', data.number);
        setValue('district', data.district);
        setValue('cep', data.cep && cepMask(data.cep, false));
        setValue('phoneDdd', data.phoneDdd);
        setValue('email', data.email);
        setValue(
            'phoneNumber',
            data.phoneNumber && phoneMask(data.phoneNumber, false),
        );
    };

    const submit = async (data: any) => {
        try {
            setOpenLoading(true);
            data.awardValue = floatValue(data.awardValue);

            if (!expanded.address) {
                delete data.publicPlace;
                delete data.number;
                delete data.district;
                delete data.cep;
            }

            if (data.cep) {
                data.cep = data.cep.replace(/\D/g, '');
            }

            if (data.phoneNumber) {
                data.phoneNumber = data.phoneNumber.replace(/\D/g, '');
            }

            if (params && params.id) {
                await api.patch(`configuration/${params.id}`, data);
            } else {
                await api.post('configuration', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.configuration}?`);
        } catch (error) {
            setOpenLoading(false);
            const messageResponse = handleExceptionMessage(error);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`configuration/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.configuration}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const findCity = async (id: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${id}`,
        );

        return {
            id: response.data.id,
            description: response.data.nome,
            UF: findVal(response.data, 'UF'),
        };
    };

    const updateState = async () => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: d.nome,
                      initials: d.sigla,
                  };
              })
            : [];

        setOptionsState(data);
    };

    const updateCity = async (idState: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${idState}/municipios`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: d.nome,
                      UF: findVal(d, 'UF'),
                  };
              })
            : [];
        setOptionsCity(data);
        return data;
    };

    const onChangeState = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${option.id}/municipios`,
            );
            const data = response.data
                ? response.data.map((d: any) => {
                      return {
                          id: d.id,
                          description: d.nome,
                          UF: findVal(d, 'UF'),
                      };
                  })
                : [];
            const city = getValues('city');
            const filterCity = data.filter(
                (c: any) => !city || c.id === city.id,
            );
            if (filterCity.length === 0) {
                setValue('city', '');
                setValue('district', undefined);
            }
            setOptionsCity(data);
        }
    };

    const setModelAddress = async (district: any) => {
        let city: any = await findCity(String(district?.cityIbgeId));

        if (city && city.UF) {
            const cities: any = await updateCity(city.UF.id);
            setOptionsCity(cities);

            setValue('state', {
                id: city.UF.id,
                description: city.UF.nome,
            });

            setValue('city', city);
        }
    };

    const onChangeDistrict = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocompleteDistrict(event.target.value);
        }
        if (option && option.cityIbgeId) {
            const city: any = await findCity(option.cityIbgeId);
            if (city && city.UF) {
                const state = optionsState.find(o => o.id === city.UF.id);
                setValue('state', state);
                await updateCity(String(state?.id));
                setValue('city', city);
            }
        }
    };

    const updateAutocompleteDistrict = async (description: any) => {
        if (description && description.length > 0) {
            try {
                setLoadingAutocomplete(true);
                const response = await api.get(
                    `district/detail?description=${description}`,
                );
                setOptionsDistrict(response.data.data);
                setLoadingAutocomplete(false);
            } catch (error) {
                setLoadingAutocomplete(false);
                console.error(error);
                addToast({
                    type: 'error',
                    title: message.error.selectAll,
                });
            }
        }
    };

    const handleChangeAccordionAddress =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                address: isExpanded,
            }));
        };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormInputNumber
                                name={`inventoryLessThan`}
                                rules={rules.inventoryLessThan}
                                control={control}
                                label="Notificação com estoque menor que"
                                decimalScale={0}
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputNumber
                                name={`awardValue`}
                                rules={rules.awardValue}
                                control={control}
                                label="Valor de premiação para cliente"
                                decimalScale={2}
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name="provider"
                                rules={rules.provider}
                                control={control}
                                label="Fornecedor para ajuste de estoque"
                                loading={false}
                                messageError={messageError}
                                options={options}
                                setValue={setValue}
                            />
                        </Grid>
                        <Tooltip
                            title={'Aviso de parcelas vencendo (dias)'}
                            arrow>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`installmentMaturity`}
                                    rules={rules.awardValue}
                                    control={control}
                                    label="Antecedência de aviso para parcelas vencendo"
                                    decimalScale={0}
                                    messageError={messageError}
                                />
                            </Grid>
                        </Tooltip>
                        <Grid item xs={12}>
                            <Typography
                                sx={{ flex: '1 1 100%', fontSize: '16px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                API de emissão de nota fiscal
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormInputText
                                name="appSecret"
                                control={control}
                                label="ID da API"
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormInputText
                                name="appKey"
                                control={control}
                                label="Chave da API"
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormInputText
                                name="ie"
                                control={control}
                                label="Inscrição estadual"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion
                                expanded={expanded.address}
                                onChange={handleChangeAccordionAddress()}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Endereço</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item md={5} xs={12}>
                                            <FormInputText
                                                name="publicPlace"
                                                control={control}
                                                rules={rules.publicPlace}
                                                label="Logradouro"
                                                messageError={messageError}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <FormInputText
                                                name="number"
                                                control={control}
                                                label="Número"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={5}
                                            // sx={{ display: 'none' }}
                                        >
                                            <FormAutocompleteInitialized
                                                name={`district`}
                                                rules={rules.district}
                                                control={control}
                                                label="Bairro / Localidade"
                                                options={optionsDistrict}
                                                loading={loadingAutocomplete}
                                                activeDebounce={true}
                                                setValue={setValue}
                                                handleChange={onChangeDistrict}
                                                messageError={messageError}
                                                actionSave={actionSaveDistrict}
                                                getValuesSave={getValuesSave}
                                                setValueSave={setValueSave}
                                                arrInput={inputsSaveDistrict}
                                                dialogTitle={'Adicionar bairro'}
                                            />
                                        </Grid>
                                        <Grid item md={5} xs={12}>
                                            <FormAutocompleteInitialized
                                                name={`city`}
                                                rules={rules.city}
                                                control={control}
                                                label="Cidade"
                                                options={optionsCity}
                                                loading={loadingAutocomplete}
                                                setValue={setValue}
                                                messageError={messageError}
                                            />
                                        </Grid>
                                        <Grid item md={5} xs={12}>
                                            <FormAutocompleteInitialized
                                                name={`state`}
                                                rules={rules.state}
                                                control={control}
                                                label="Estado"
                                                options={optionsState}
                                                loading={loadingAutocomplete}
                                                setValue={setValue}
                                                messageError={messageError}
                                                handleChange={onChangeState}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <FormInputText
                                                name="cep"
                                                control={control}
                                                rules={rules.cep}
                                                label="CEP"
                                                mask="cep"
                                                messageError={messageError}
                                            />
                                        </Grid>
                                        <Grid item md={7} xs={12}>
                                            <FormInputText
                                                name="email"
                                                control={control}
                                                rules={rules.email}
                                                label="E-mail"
                                                messageError={messageError}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <FormInputText
                                                name={`phoneDdd`}
                                                rules={rules.phoneDdd}
                                                control={control}
                                                label="DDD"
                                                mask="number"
                                                messageError={messageError}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <FormInputText
                                                name={`phoneNumber`}
                                                control={control}
                                                rules={rules.phoneNumber}
                                                label="Número"
                                                mask="phone"
                                                messageError={messageError}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                    <br />
                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
            </Paper>
        </div>
    );
};

export default Form;

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AddCard from '@mui/icons-material/AddCard';
import IconCategory from '@mui/icons-material/Category';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { Switch } from 'react-router-dom';

// import Chart from './Chart';
// import Deposits from './Deposits';
// import HandymanIcon from '@mui/icons-material/Handyman'; Ícone para a ordem e colocar esse ícone na configuração
import { FormButton } from '../../componets/form/formButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import InventoryIcon from '@mui/icons-material/Inventory';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';
import OutputIcon from '@mui/icons-material/Output';
import {
    Badge,
    Collapse,
    Drawer,
    Grid,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Tooltip,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import AlertDialog from '../../componets/dialog/alertDialog';
import Footer from '../../componets/footer';
import { paths, rowsPerPageOptions } from '../../config';
import { useAuth } from '../../context/AuthContext';
import { useContextGlobal } from '../../context/ContextGlobal';
import { floatValue, formatNumber } from '../../util/infoFormat';
import './styles.scss';
import { useForm } from 'react-hook-form';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { FormInputNumber } from '../../componets/form/formInputNumber';
import {
    formatDateWithoutHours,
    momentZoneToDateAddDays,
    momentZoneToUnix,
} from '../../util/dateUtil';
import { optionsState } from '../../communs/enums/state';
import { IconComponent, icons } from '../../componets/icons';
import { objToQuery, queryToObj } from '../../util/query';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { ColumnDef } from '@tanstack/react-table';
import { TableGeneric } from '../../componets/table';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

interface Data {
    id: string;
    description: string;
    localization?: string;
    code?: string;
    category?: {
        id: string;
        description: string;
    };
    quantity?: number;
}

interface AwardCustomer {
    id: string;
    name: string;
    awardGiven: number;
    total: number;
    awardActual: number;
}

interface IInvoiceDetail {
    formPayment: string;
    typePayment: string;
    description: string;
    dueDate: Date;
    installmentValue: number;
    downloaded: boolean;
    workOrder?: {
        id: string;
        code: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
    };
    outputProduct?: {
        id: string;
        code: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
    };
}

interface DataBirthdayCustomer {
    id: string;
    name: string;
    birthDate: string;
    yearsOld: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

interface HeadCellAward {
    disablePadding: boolean;
    id: keyof AwardCustomer;
    label: string;
    numeric: boolean;
}

interface HeadCellBirthday {
    disablePadding: boolean;
    id: keyof DataBirthdayCustomer;
    label: string;
    numeric: boolean;
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    rows: Data[];
    width: number;
}

interface ModalAwardProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    rows: AwardCustomer[];
    setListAwardedCustomers: (list: AwardCustomer[]) => void;
    width: number;
}
interface ModalOverdueInstallmentProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    rows: IInvoiceDetail[];
    width: number;
}

interface ModalBirthdayCustomerProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    rows: DataBirthdayCustomer[];
    width: number;
}

interface ModalApplyAwardProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    data?: AwardCustomer;
    setListAwardedCustomers: (list: AwardCustomer[]) => void;
    width: number;
}

const headCellsAward: readonly HeadCellAward[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Cliente',
    },
    {
        id: 'total',
        numeric: false,
        disablePadding: false,
        label: 'Total acumulado',
    },
    {
        id: 'awardGiven',
        numeric: false,
        disablePadding: false,
        label: 'Acumulado de premiações',
    },
    {
        id: 'awardActual',
        numeric: false,
        disablePadding: false,
        label: 'Valor atingido',
    },
];

const headCells: readonly HeadCell[] = [
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'Código',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Descrição',
    },
    {
        id: 'localization',
        numeric: false,
        disablePadding: false,
        label: 'Localização',
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'Categoria',
    },
    {
        id: 'quantity',
        numeric: false,
        disablePadding: false,
        label: 'QTD estoque',
    },
];

const headCellsBirthday: readonly HeadCellBirthday[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Cliente',
    },
    {
        id: 'birthDate',
        numeric: false,
        disablePadding: false,
        label: 'Data de nascimento',
    },
    {
        id: 'yearsOld',
        numeric: false,
        disablePadding: false,
        label: 'Anos',
    },
];

type Props = { children: React.ReactNode };

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${open ? drawerWidth : 0}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${open ? drawerWidth : 0}px)`,
        marginLeft: `${open ? drawerWidth : 0}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

// const socket = io('http://localhost:6001');

const titleMessages = {
    inventory: 'Estoque em alerta',
    awardedCustomers: 'Clientes pendentes para receber premiações',
    birthDays: 'Aniversariante(s)',
    overdueInstallments: 'Parcelas vencidas ou perto do vencimento',
};

const MainContainer: React.FC<Props> = ({ children }) => {
    const history = useHistory();
    const theme = useTheme();
    const [listInventoryWarnning, setListInventoryWarning] = React.useState([]);
    const [openModalInventory, setOpenModalInventory] = React.useState(false);
    const [states, setStates] = React.useState<
        { id: string; description: string }[]
    >([]);

    const [listBirthdays, setListBirthdays] = React.useState([]);
    const [openModalBirthday, setOpenModalBirthday] = React.useState(false);
    const [listAwardedCustomers, setListAwardedCustomers] = React.useState<
        AwardCustomer[]
    >([]);
    const [openModalAwardedCustomers, setOpenModalAwardedCustomers] =
        React.useState(false);
    const [listOverdueInstallments, setListOverdueInstallments] =
        React.useState<IInvoiceDetail[]>([]);
    const [openModalOverdueInstallments, setOpenModalOverdueInstallments] =
        React.useState(false);

    const {
        openCollapseRecords,
        handleOpenCollapseRecords,
        openCollapseInvoicing,
        handleOpenCollapseInvoicing,
    } = useContextGlobal();

    const { notificationInventory, setNotificationInventory } =
        useContextGlobal();
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const { user } = useAuth();

    const handleFirstName = (name: string) => {
        if (name) {
            const firstName = name.split(' ');
            return firstName[0].toLowerCase();
        }
        return '';
    };

    React.useEffect(() => {
        const name = handleFirstName(user.name);

        if (name === 'alex') {
            history.push(`${paths.paid}`);
        }
    }, [user]);

    React.useEffect(() => {
        let items: any = localStorage.getItem('@GESSIS:inventory');
        if (items) {
            items = JSON.parse(items);
        }
        if (items?.length > 0) {
            setListInventoryWarning(items);
        }
    }, [localStorage.getItem('@GESSIS:inventory')]);

    React.useEffect(() => {
        let items: any = localStorage.getItem('@GESSIS:birthdays');
        if (items) {
            items = JSON.parse(items);
        }
        if (items?.length > 0) {
            setListBirthdays(items);
        }
    }, [localStorage.getItem('@GESSIS:birthdays')]);

    React.useEffect(() => {
        let items: any = localStorage.getItem('@GESSIS:awardedCustomers');
        if (items) {
            items = JSON.parse(items);
        }
        if (items?.length > 0) {
            setListAwardedCustomers(items);
        }
    }, [localStorage.getItem('@GESSIS:awardedCustomers')]);

    React.useEffect(() => {
        let items: any = localStorage.getItem('@GESSIS:birthday');
        if (items) {
            items = JSON.parse(items);
        }
        if (items?.length > 0) {
            setListBirthdays(items);
        }
    }, [localStorage.getItem('@GESSIS:birthday')]);

    React.useEffect(() => {
        let items: any = localStorage.getItem('@GESSIS:overdueInstallments');
        if (items) {
            items = JSON.parse(items);
        }
        if (items?.length > 0) {
            setListOverdueInstallments(items);
        }
    }, [localStorage.getItem('@GESSIS:overdueInstallments')]);

    function closeAll() {
        handleOpenCollapseRecords(false);
    }

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleConfirmViewInventory = async (confirm: boolean) => {
        if (confirm) {
            setNotificationInventory(false);
            setOpenModalInventory(true);
        } else {
            setNotificationInventory(false);
        }
    };

    // const location = useLocation();

    // const [locationKeys, setLocationKeys] = React.useState<any[]>([]);
    // const [locationPaths, setLocationPaths] = React.useState<any[]>([]);

    // React.useEffect(() => {
    //     return history.listen(location => {
    //         if (history.action === 'PUSH') {
    //             setLocationKeys([location.key]);
    //             setLocationPaths([location.pathname]);
    //         }

    //         if (history.action === 'POP') {
    //             if (locationKeys[1] === location.key) {
    //                 setLocationKeys(([_, ...keys]) => keys);
    //                 setLocationPaths(([_, ...keys]) => keys);

    //                 // Handle forward event
    //                 console.log('Handle forward event');
    //             } else {
    //                 setLocationKeys(keys => [location.key, ...keys]);

    //                 // Handle back event
    //                 console.log('Handle back event');

    //                 history.push(sessionStorage.getItem('prevPath') || '');
    //                 // history.push(`${paths.category}?`);
    //             }
    //         }
    //     });
    // }, [locationKeys]);

    // function handleStoragePathname() {
    //     if (window.location.pathname !== '/') {
    //         let path = window.location.pathname;
    //         let pathname = window.location.pathname + window.location.search;

    //         if (
    //             pathname &&
    //             pathname.includes('?') &&
    //             !sessionStorage.getItem('prevPath')?.includes(path)
    //         ) {
    //             sessionStorage.setItem('prevPath', pathname);
    //             localStorage.setItem('pathname', pathname.split('?')[0]);
    //         } else {
    //             localStorage.setItem('pathname', pathname);
    //         }
    //     }
    // }

    // React.useEffect(() => {
    //     handleStoragePathname();
    // }, [location]);

    var altura = window.screen.height;
    var largura = window.screen.width;

    function useWindowSize() {
        const [size, setSize] = React.useState([0, 0]);
        React.useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const [width, height] = useWindowSize();

    React.useEffect(() => {
        // 3 - Entregado, 5 - Não aprovado,  6 - Sem conserto e 11 - Entregado sem conserto
        const statesDetault = optionsState().filter(
            s => s.id !== '3' && s.id !== '5' && s.id !== '6' && s.id !== '11',
        );
        setStates(statesDetault);

        if (window.screen.width < 800 && open) {
            setOpen(false);
        }
    }, [width]);

    const styleListIcon = { minWidth: 0, pr: 1 };

    return (
        <div id="root">
            <ModalInventory
                open={openModalInventory}
                setOpen={setOpenModalInventory}
                rows={listInventoryWarnning}
                width={width}
            />
            <ModalAward
                open={openModalAwardedCustomers}
                setOpen={setOpenModalAwardedCustomers}
                rows={listAwardedCustomers}
                setListAwardedCustomers={setListAwardedCustomers}
                width={width}
            />
            <ModalBirthDayCustomer
                open={openModalBirthday}
                setOpen={setOpenModalBirthday}
                rows={listBirthdays}
                width={width}
            />
            <ModalOverdueInstallment
                open={openModalOverdueInstallments}
                setOpen={setOpenModalOverdueInstallments}
                rows={listOverdueInstallments}
                width={width}
            />
            <Box sx={{ display: 'flex', height: '100vh' }}>
                <CssBaseline />
                <AlertDialog
                    handleConfirmation={handleConfirmViewInventory}
                    open={notificationInventory}
                    messageDialogTitle={
                        'Deseja abrir a lista de produtos que estão abaixo do estoque permitido?'
                    }
                />
                <AppBar position="fixed" open={open}>
                    <Toolbar className="toolbar">
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight:
                                    window.screen.width < 800 ? '8px' : '16px',
                                ...(open && { display: 'none' }),
                            }}>
                            <MenuIcon />
                        </IconButton>

                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => history.push('/dashboard')}
                            sx={{ flexGrow: 1, cursor: 'pointer' }}>
                            GESSIS
                        </Typography>

                        <Tooltip title={titleMessages.overdueInstallments}>
                            <IconButton
                                color="inherit"
                                onClick={() =>
                                    listOverdueInstallments.length &&
                                    setOpenModalOverdueInstallments(true)
                                }>
                                <Badge
                                    badgeContent={
                                        listOverdueInstallments.length
                                    }
                                    color="secondary">
                                    <IconComponent icon={icons.paid} />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={titleMessages.birthDays}>
                            <IconButton
                                color="inherit"
                                onClick={() =>
                                    listBirthdays.length &&
                                    setOpenModalBirthday(true)
                                }>
                                <Badge
                                    badgeContent={listBirthdays.length}
                                    color="secondary">
                                    <SentimentVerySatisfiedOutlinedIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={titleMessages.awardedCustomers}>
                            <IconButton
                                color="inherit"
                                onClick={() =>
                                    listAwardedCustomers.length > 0 &&
                                    setOpenModalAwardedCustomers(true)
                                }>
                                <Badge
                                    badgeContent={listAwardedCustomers.length}
                                    color="secondary">
                                    <AddShoppingCartIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={titleMessages.inventory}>
                            <IconButton
                                color="inherit"
                                onClick={() =>
                                    listInventoryWarnning.length > 0 &&
                                    setOpenModalInventory(true)
                                }>
                                <Badge
                                    badgeContent={listInventoryWarnning.length}
                                    color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <LongMenu />
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: open ? drawerWidth : 0,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: open ? drawerWidth : 0,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? (
                                <ChevronLeftIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    </DrawerHeader>
                    {/* <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar> */}
                    <Divider />
                    <Switch>
                        <React.Fragment>
                            <List component="nav">
                                <ListItem
                                    button
                                    onClick={() =>
                                        handleOpenCollapseRecords(
                                            !openCollapseRecords,
                                        )
                                    }>
                                    <ListItemIcon sx={styleListIcon}>
                                        <AddCard />
                                    </ListItemIcon>
                                    <ListItemText primary="Cadastros" />
                                    {openCollapseRecords ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={openCollapseRecords}
                                    timeout="auto"
                                    unmountOnExit>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/records/user?limit=${rowsPerPageOptions[0]}&page=1`}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <AccountCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Usuário" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/records/category?limit=${rowsPerPageOptions[0]}&page=1`}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <IconCategory />
                                            </ListItemIcon>
                                            <ListItemText primary="Categoria" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/records/customer?limit=${rowsPerPageOptions[0]}&page=1`}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <AccountBoxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Cliente" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/records/product?limit=${rowsPerPageOptions[0]}&page=1`}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <InventoryIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Produto" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/records/provider?limit=${rowsPerPageOptions[0]}&page=1`}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <AssignmentIndIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Fornecedor" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/records/brand?limit=${rowsPerPageOptions[0]}&page=1`}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <BrandingWatermarkOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Marca" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/records/model-brand?limit=${rowsPerPageOptions[0]}&page=1`}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <NoteAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Modelo" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/records/technician-service?limit=${rowsPerPageOptions[0]}&page=1`}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <ManageAccountsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Tipo de serviço" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to="/records/district">
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <EditRoadIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Bairro" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/records/configuration?limit=${rowsPerPageOptions[0]}&page=1`}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <MemoryOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Configuração" />
                                        </ListItemButton>
                                    </ListItem>
                                </Collapse>
                                <ListItemButton
                                    component={Link}
                                    onClick={closeAll}
                                    to={`/records/entry-product`}>
                                    <ListItemIcon sx={styleListIcon}>
                                        <DashboardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Entrada de produtos" />
                                </ListItemButton>
                                <ListItemButton
                                    component={Link}
                                    onClick={closeAll}
                                    to={`/records/output-product`}>
                                    <ListItemIcon sx={styleListIcon}>
                                        <OutputIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Saída de produtos" />
                                </ListItemButton>
                                <ListItemButton
                                    component={Link}
                                    onClick={closeAll}
                                    to={`/records/work-order`}>
                                    <ListItemIcon sx={styleListIcon}>
                                        <MiscellaneousServicesIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Ordem de serviço" />
                                </ListItemButton>
                                <ListItem
                                    button
                                    onClick={() =>
                                        handleOpenCollapseInvoicing(
                                            !openCollapseInvoicing,
                                        )
                                    }>
                                    <ListItemIcon sx={styleListIcon}>
                                        <IconComponent
                                            icon={icons.currencyExchange}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Faturamento" />
                                    {openCollapseInvoicing ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </ListItem>
                                <Collapse
                                    in={openCollapseInvoicing}
                                    timeout="auto"
                                    unmountOnExit>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={paths.invoiceDetailList}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <IconComponent
                                                    icon={icons.priceCheck}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Gerar faturas" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={paths.invoiceDetail}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <IconComponent
                                                    icon={icons.paid}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Baixa de faturas" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem
                                        button
                                        component={Link}
                                        to={paths.generatedInvoices}>
                                        <ListItemButton>
                                            <ListItemIcon sx={styleListIcon}>
                                                <IconComponent
                                                    icon={icons.requestQuote}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Faturas geradas" />
                                        </ListItemButton>
                                    </ListItem>
                                </Collapse>
                                {/* <ListItemButton>
                                <ListItemIcon>
                                    <BarChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Reports" />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemIcon>
                                    <LayersIcon />
                                </ListItemIcon>
                                <ListItemText primary="Integrations" />
                            </ListItemButton>

                            <Divider sx={{ my: 1 }} />
                            {secondaryListItems} */}
                            </List>
                        </React.Fragment>
                    </Switch>
                </Drawer>

                <Main
                    open={open}
                    className="main-container"
                    sx={{
                        backgroundColor: theme =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        display: 'flex',
                        flexDirection: 'column',
                        p: 0,
                    }}>
                    <DrawerHeader />
                    <Box
                        className="main-box"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            overflow: 'auto',
                            width: '100%',
                        }}>
                        {children}
                    </Box>

                    <Footer />
                </Main>

                {/* <div className="container-test"></div> */}
            </Box>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalInventory({ open, setOpen, rows, width }: ModalProps) {
    const history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        history.push(`${paths.productRegister}/${id}`);
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <div className="header-modal">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            {titleMessages.inventory}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <TableContainer
                        sx={{
                            height: width <= 800 ? 400 : 600,
                            '&::-webkit-scrollbar': {
                                width: 8,
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#f1f1f1',
                                borderRadius: 2,
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#c1c1c1',
                                borderRadius: 2,
                            },
                        }}>
                        <Table
                            sx={{ height: 'max-content' }}
                            aria-labelledby="tableTitle"
                            size="small">
                            <TableHead>
                                <TableRow>
                                    {headCells.map(headCell => (
                                        <TableCell
                                            key={headCell.id}
                                            align={
                                                headCell.numeric
                                                    ? 'right'
                                                    : 'left'
                                            }
                                            padding={
                                                headCell.disablePadding
                                                    ? 'none'
                                                    : 'normal'
                                            }>
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell
                                                scope="row"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row?.id)
                                                }>
                                                {row?.code}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                scope="row"
                                                sx={{ cursor: 'pointer' }}
                                                id={`${row.description}`}
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row.description}
                                            </TableCell>
                                            <TableCell
                                                scope="row"
                                                align="left"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row.localization}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row.category?.description}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row?.quantity}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </div>
    );
}

function ModalAward({
    open,
    setOpen,
    rows,
    setListAwardedCustomers,
    width,
}: ModalAwardProps) {
    const [dataAwardCustomer, setDataAwardCustomer] = React.useState<
        AwardCustomer | undefined
    >(undefined);
    const [openModal, setOpenModal] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (
        event: React.MouseEvent<unknown>,
        data: AwardCustomer,
    ) => {
        setDataAwardCustomer(data);
        setOpenModal(true);
    };

    const styleModal = {
        ...style,
    };
    if (width <= 800) {
        styleModal.width = '90%';
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <div className="header-modal">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            {titleMessages.awardedCustomers}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <ModalApplyAward
                        open={openModal}
                        setOpen={setOpenModal}
                        data={dataAwardCustomer}
                        setListAwardedCustomers={setListAwardedCustomers}
                        width={width}
                    />
                    <TableContainer
                        sx={{
                            height: width <= 800 ? 400 : 600,
                            '&::-webkit-scrollbar': {
                                width: 8,
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#f1f1f1',
                                borderRadius: 2,
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#c1c1c1',
                                borderRadius: 2,
                            },
                        }}>
                        <Table
                            // sx={{ minWidth: 750 }}
                            sx={{ height: 'max-content' }}
                            aria-labelledby="tableTitle"
                            size="small">
                            <TableHead>
                                <TableRow>
                                    {headCellsAward.map(headCell => (
                                        <TableCell
                                            key={headCell.id}
                                            align={
                                                headCell.numeric
                                                    ? 'right'
                                                    : 'left'
                                            }
                                            padding={
                                                headCell.disablePadding
                                                    ? 'none'
                                                    : 'normal'
                                            }>
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell
                                                scope="row"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row)
                                                }>
                                                {row?.name}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                scope="row"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row)
                                                }>
                                                {formatNumber(row.total)}
                                            </TableCell>
                                            <TableCell
                                                scope="row"
                                                align="left"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row)
                                                }>
                                                {formatNumber(row.awardGiven)}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row)
                                                }>
                                                {formatNumber(
                                                    row.total - row.awardGiven,
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </div>
    );
}

function ModalApplyAward({
    open,
    setOpen,
    data,
    setListAwardedCustomers,
    width,
}: ModalApplyAwardProps) {
    const [focusAwardGiven, setFocusAwardGiven] = React.useState(true);

    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();

    const defaultValuesItems = {
        total: undefined,
        awardActual: undefined,
    };

    const rulesItems = {
        awardActual: {
            required: true,
        },
    };
    const styleModalApply = {
        ...style,
        width: '400px',
    };

    if (width <= 800) {
        styleModalApply.width = '88%';
    }

    const messageErrorItems = (errors: any, field: any) => {
        // awardGiven
        if (errors && errors.type === 'required' && field === 'awardActual') {
            return 'O campo valor atingido é obrigátorio.';
        }

        return '';
    };

    const useFormItems = useForm<AwardCustomer>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    React.useEffect(() => {
        setModel();
        setFocusAwardGiven(true);
    }, [open]);

    const setModel = () => {
        if (data) {
            setValueItems('total', data.total);
            setValueItems('awardActual', data.total - data?.awardGiven);
            setValueItems('id', data.id);
        }
    };

    const handleClose = () => {
        reset(defaultValuesItems);
        setOpen(false);
    };

    const submitItem = async (data: AwardCustomer) => {
        data.awardActual = floatValue(data.awardActual);
        data.total = floatValue(data.total);

        try {
            await api.patch(`customer/awardgiven/${data.id}`, data);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            handleClose();

            let items: any = localStorage.getItem('@GESSIS:awardedCustomers');
            if (items) {
                items = JSON.parse(items);
            }

            items = items.filter((i: AwardCustomer) => i.id !== data.id);
            localStorage.setItem(
                '@GESSIS:awardedCustomers',
                JSON.stringify(items),
            );
            setListAwardedCustomers(items);
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModalApply} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Salvar premiação
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`total`}
                                    control={controlItems}
                                    label="Acumulado total"
                                    decimalScale={2}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormInputNumber
                                    name={`awardActual`}
                                    control={controlItems}
                                    rules={rulesItems.awardActual}
                                    label="Valor atingido"
                                    decimalScale={2}
                                    messageError={messageErrorItems}
                                    autoFocus={focusAwardGiven}
                                />
                            </Grid>
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

function ModalBirthDayCustomer({
    open,
    setOpen,
    rows,
    width,
}: ModalBirthdayCustomerProps) {
    const history = useHistory();

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        history.push(`${paths.customerRegister}/${id}`);
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <div className="header-modal">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            {titleMessages.birthDays}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <TableContainer
                        sx={{
                            height: width <= 800 ? 400 : 600,
                            '&::-webkit-scrollbar': {
                                width: 8,
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#f1f1f1',
                                borderRadius: 2,
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#c1c1c1',
                                borderRadius: 2,
                            },
                        }}>
                        <Table
                            sx={{ height: 'max-content' }}
                            aria-labelledby="tableTitle"
                            size="small">
                            <TableHead>
                                <TableRow>
                                    {headCellsBirthday.map(headCell => (
                                        <TableCell
                                            key={headCell.id}
                                            align={
                                                headCell.numeric
                                                    ? 'right'
                                                    : 'left'
                                            }
                                            padding={
                                                headCell.disablePadding
                                                    ? 'none'
                                                    : 'normal'
                                            }>
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell
                                                scope="row"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row?.id)
                                                }>
                                                {row?.name}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                scope="row"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {formatDateWithoutHours(
                                                    new Date(row.birthDate),
                                                )}
                                            </TableCell>
                                            <TableCell
                                                scope="row"
                                                align="left"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row.yearsOld}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </div>
    );
}

function ModalOverdueInstallment({
    open,
    setOpen,
    rows,
    width,
}: ModalOverdueInstallmentProps) {
    const history = useHistory();

    const columns = React.useMemo<ColumnDef<IInvoiceDetail>[]>(
        () => [
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Código / Número
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell align="left" key={row.id}>
                            {row.original.outputProduct
                                ? row.original.outputProduct?.code
                                : row.original.workOrder?.code}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'description',
                cell: (cell: any) => (
                    <TableCell align="left">{cell?.getValue()}</TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Descrição
                    </TableCell>
                ),
            },
            {
                accessorKey: 'dueDate',
                cell: (cell: any) => (
                    <TableCell align="left">
                        {formatDateWithoutHours(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data vencimento
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Cliente
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell align="left" key={row.id}>
                            {row.original.outputProduct
                                ? row.original.outputProduct?.customer?.name
                                : row.original.workOrder?.customer?.name}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Identificação
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell key={row.id} align="left">
                            {row.original.outputProduct
                                ? cpfAndCnpjMask(
                                      row.original.outputProduct?.customer
                                          ?.identification,
                                      false,
                                  )
                                : cpfAndCnpjMask(
                                      row.original.workOrder?.customer
                                          ?.identification,
                                      false,
                                  )}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'installmentValue',
                cell: (cell: any) => (
                    <TableCell align="left">
                        {formatNumber(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Valor
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <Tooltip
                            title="Dar baixa"
                            onClick={() => {
                                const action = 'writeOff';

                                // Tirando action e invoiceId, é a query default da página
                                let limit = rowsPerPageOptions[0];
                                let currentPage = 1;
                                const queryVO = {
                                    limit,
                                    page: currentPage,
                                    downloaded: '0',
                                    dateInitial: momentZoneToUnix(
                                        momentZoneToDateAddDays({
                                            days: -90,
                                            startOfType: 'day',
                                        }),
                                    ),
                                    dateFinal: momentZoneToUnix(
                                        momentZoneToDateAddDays({
                                            days: 0,
                                            endOfType: 'day',
                                        }),
                                    ),
                                    action: action,
                                    invoiceId: row.original.id,
                                };

                                history.push(
                                    `/invoice-detail/payment?${objToQuery(
                                        queryVO,
                                    )}`,
                                );
                            }}>
                            <IconButton disabled={row.original.downloaded}>
                                <IconComponent icon={icons.downloadDone} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const handleClose = () => {
        setOpen(false);
    };

    const styleModal = {
        ...style,
    };
    if (width <= 800) {
        styleModal.width = '90%';
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <div className="header-modal">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            {titleMessages.overdueInstallments}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {/* <AlertDialog
                        handleConfirmation={handleConfirmAction}
                        open={openModalAction}
                        messageDialogTitle={messageDialogTitle}
                        handleCloseProps={handleCloseModalAction}
                        messageDetail={messageDialogContent}
                    /> */}
                    <TableGeneric
                        data={rows}
                        columns={columns}
                        minWidth={650}
                        sxTableContainer={{
                            height: width <= 800 ? 400 : 600,
                            '&::-webkit-scrollbar': {
                                width: 8,
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#f1f1f1',
                                borderRadius: 2,
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#c1c1c1',
                                borderRadius: 2,
                            },
                        }}
                    />
                </Box>
            </Modal>
        </div>
    );
}

const ITEM_HEIGHT = 48;

function LongMenu() {
    const history = useHistory();
    const { user, signOut } = useAuth();

    const handlePerfilUser = () => {
        handleClose();
        history.push(`${paths.userRegister}/${user.sub}`);
    };

    const options = [
        {
            title: 'Meu perfil',
            handleClick: handlePerfilUser,
        },
        {
            title: 'Sair',
            handleClick: signOut,
        },
    ];

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFirstName = (name: string) => {
        if (name) {
            const firstName = name.split(' ');
            return firstName[0];
        }
        return '';
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}>
                <Typography
                    sx={{ flex: '1 1 100%', fontSize: '16px', color: '#fff' }}
                    variant="h6"
                    component="div">
                    {handleFirstName(user.name)}
                </Typography>
                <MoreVertIcon sx={{ color: '#fff' }} />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}>
                {options.map(option => (
                    <MenuItem
                        key={option.title}
                        // selected={option === 'Pyxis'}
                        onClick={option.handleClick}>
                        {option.title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default MainContainer;

import { Delete, ModeEdit } from '@mui/icons-material';
import {
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
    optionsTypeNumberPhone,
    typeNumberPhoneFind,
} from '../../communs/enums/typeNumberPhone';
import { extraValidationCNPJ } from '../../communs/valitions/cnpj';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormInputText } from '../../componets/form/formInputText';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { phoneMask } from '../../componets/form/mask/phone';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';

interface IFormInput {
    description: string;
    identification?: string;
    email: string;
    phones: IPhone[];
}

interface IPhone {
    phoneType: string;
    phoneDdd: string;
    phoneNumber: string;
    branch: string;
    id?: string;
    phoneTypeOption?: {
        id: string;
        description: string;
    };
}

interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    getValues: any;
    append: any;
    update: any;
    setIndex: (index: number) => void;
    fields: any[];
}

const defaultValues = {
    description: '',
    email: '',
    identification: '',
};

const rules = {
    description: {
        required: true,
    },
    email: {
        require: true,
        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    },
    identification: {
        required: false,
        validate: {
            validateCNPJ: (text: string) =>
                text?.length > 0 ? extraValidationCNPJ(text) : true,
        },
    },
};

const messageError = (errors: any, field: any) => {
    // description
    if (errors && errors.type === 'required' && field === 'description') {
        return 'O campo descrição é obrigátorio.';
    }

    // email
    if (errors && errors.type === 'required' && field === 'email') {
        return 'O campo e-mail é obrigátorio.';
    }
    if (errors && errors.type === 'pattern' && field === 'email') {
        return 'E-mail inválido.';
    }

    // identification
    if (errors && errors.type === 'validateCPF' && field === 'identification') {
        return 'CPF inválido.';
    }
    if (
        errors &&
        errors.type === 'validateCNPJ' &&
        field === 'identification'
    ) {
        return 'CNPJ inválido.';
    }
    return '';
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalPhones, setOpenModalPhones] = useState(false);
    const [indexItem, setIndexItem] = useState<number>(-1);

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`provider/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    const { handleSubmit, control, reset, setValue, getValues } =
        useForm<IFormInput>({
            defaultValues,
        });

    const { fields, append, remove, update } = useFieldArray<
        IFormInput,
        'phones',
        'phonesId'
    >({
        control,
        name: 'phones',
        keyName: 'phonesId',
    });

    const setModel = (data: IFormInput) => {
        setValue('description', data.description);
        setValue('email', data.email);
        setValue('phones', data.phones);
        if (data.identification) {
            setValue(
                'identification',
                cpfAndCnpjMask(data.identification, false),
            );
        }
    };

    const submit = async (data: any) => {
        try {
            if (data.identification) {
                data.identification = data.identification.replace(/\D/g, '');
            } else {
                data.identification = null;
            }

            setOpenLoading(true);
            if (params && params.id) {
                await api.patch(`provider/${params.id}`, data);
            } else {
                await api.post('provider', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.provider}?`);
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`provider/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.provider}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const handleNewItemTable = () => {
        // setAddItem(true);
        setIndexItem(-1);
        setOpenModalPhones(true);
    };

    const handleEditItemTable = (index: any) => {
        // setAddItem(false);
        setIndexItem(index);
        setOpenModalPhones(true);
    };

    const handleDeleteItemTable = async (index: number) => {
        remove(index);
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="description"
                                control={control}
                                rules={rules.description}
                                label="Descrição"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="identification"
                                control={control}
                                rules={rules.identification}
                                label="Identificação"
                                mask="identification"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="email"
                                control={control}
                                rules={rules.email}
                                label="E-mail"
                                messageError={messageError}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                sx={{ fontSize: '16px', pb: 1 }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                <FormButton
                                    label={'Adicionar'}
                                    typeButton={'addRegister'}
                                    onClick={() => handleNewItemTable()}
                                />
                            </Typography>
                            <Typography
                                sx={{ fontSize: '16px' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Telefones
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tipo</TableCell>
                                            <TableCell align="center">
                                                DDD
                                            </TableCell>
                                            <TableCell align="center">
                                                Número
                                            </TableCell>
                                            <TableCell align="center">
                                                Ramal
                                            </TableCell>
                                            <TableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fields.map((item, index) => (
                                            <TableRow
                                                hover
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        { border: 0 },
                                                }}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {
                                                        typeNumberPhoneFind(
                                                            item.phoneType,
                                                        )?.description
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.phoneDdd}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {phoneMask(
                                                        item.phoneNumber,
                                                        false,
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={event =>
                                                        handleEditItemTable(
                                                            index,
                                                        )
                                                    }>
                                                    {item.branch || ''}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleEditItemTable(
                                                                index,
                                                            )
                                                        }>
                                                        <ModeEdit />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleDeleteItemTable(
                                                                index,
                                                            );
                                                        }}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <br />
                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
                <ModalPhones
                    open={openModalPhones}
                    setOpen={setOpenModalPhones}
                    index={indexItem}
                    setIndex={setIndexItem}
                    getValues={getValues}
                    append={append}
                    update={update}
                    fields={fields}
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

function ModalPhones({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    fields,
}: ModalProps) {
    const [focusTypeNumberPhoneOption, setFocusTypeNumberPhoneOption] =
        useState(true);

    const { addToast } = useToast();

    const defaultValuesItems = {
        phoneType: undefined,
        phoneDdd: undefined,
        phoneNumber: undefined,
        brandh: undefined,
    };

    const rulesItems = {
        phoneType: {
            required: true,
        },
        phoneTypeOption: {
            required: true,
        },
        phoneDdd: {
            required: true,
            minLength: 3,
            maxLength: 3,
        },
        phoneNumber: {
            required: true,
        },
        branch: {
            required: true,
        },
    };

    const messageErrorItems = (errors: any, field: any) => {
        // phoneTypeOption
        if (
            errors &&
            errors.type === 'required' &&
            field === 'phoneTypeOption'
        ) {
            return 'O campo tipo é obrigátorio.';
        }

        // phoneDdd
        if (errors && errors.type === 'required' && field === 'phoneDdd') {
            return 'O campo DDD é obrigátorio.';
        }
        if (errors && errors.type === 'minLength' && field === 'phoneDdd') {
            return 'O campo DDD tem que ter no mínimo 3 números.';
        }
        if (errors && errors.type === 'maxLength' && field === 'phoneDdd') {
            return 'O campo DDD não pode ter mais que 3 números.';
        }

        // phoneNumber
        if (errors && errors.type === 'required' && field === 'phoneNumber') {
            return 'O campo número é obrigátorio.';
        }

        return '';
    };

    const useFormItems = useForm<IPhone>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        } else {
            setValueItems('phoneDdd', '047');
        }
        setFocusTypeNumberPhoneOption(true);
    }, [index, open]);

    const setModel = (index: number) => {
        const phoneTypeId = getValues(`phones[${index}].phoneType`);
        const pType = typeNumberPhoneFind(phoneTypeId);
        if (pType?.id) {
            setValueItems('phoneType', phoneTypeId);
            setValueItems('phoneTypeOption', {
                id: pType?.id,
                description: pType?.description,
            });
        }
        setValueItems('phoneType', getValues(`phones[${index}].phoneType`));
        setValueItems('phoneDdd', getValues(`phones[${index}].phoneDdd`));
        setValueItems(
            'phoneNumber',
            phoneMask(getValues(`phones[${index}].phoneNumber`), false),
        );
        setValueItems('branch', getValues(`phones[${index}].branch`));
    };

    const handleClose = () => {
        setIndex(-1);
        reset(defaultValuesItems);
        setOpen(false);
    };

    const onChangeInputWarrantyTime = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            setFocusTypeNumberPhoneOption(false);
        }
        setValueItems(`phoneType`, option?.id);
    };

    const onBlurInputTypeNumberPhone = (event: any) => {
        setFocusTypeNumberPhoneOption(false);
    };

    const submitItem = (data: any) => {
        const repeatItem = fields
            ? fields.filter(
                  (f, i) => index != i && f.phoneNumber == data.phoneNumber,
              )
            : [];

        if (repeatItem.length > 0) {
            addToast({
                type: 'warn',
                title: 'Item repedito',
                description: 'O item já foi informado.',
            });
            return;
        }

        data.phoneNumber = data.phoneNumber.replace(/\D/g, '');

        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style} component={'div'}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2">
                        Inserir telefone
                    </Typography>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            <Grid item md={5} xs={12}>
                                <FormAutocompleteInitialized
                                    name={`phoneTypeOption`}
                                    rules={rulesItems.phoneTypeOption}
                                    control={controlItems}
                                    label="Tipo"
                                    options={optionsTypeNumberPhone()}
                                    loading={false}
                                    setValue={setValueItems}
                                    handleChange={onChangeInputWarrantyTime}
                                    messageError={messageErrorItems}
                                    handleOnBlur={onBlurInputTypeNumberPhone}
                                    autoFocus={focusTypeNumberPhoneOption}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <FormInputText
                                    name={`phoneDdd`}
                                    rules={rulesItems.phoneDdd}
                                    control={controlItems}
                                    label="DDD"
                                    mask="number"
                                    messageError={messageErrorItems}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <FormInputText
                                    name={`phoneNumber`}
                                    control={controlItems}
                                    rules={rulesItems.phoneNumber}
                                    label="Número"
                                    mask="phone"
                                    messageError={messageErrorItems}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <FormInputText
                                    name={`branch`}
                                    control={controlItems}
                                    label="Ramal"
                                    messageError={messageErrorItems}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}></Grid>
                        </Grid>
                        <span />
                        <Stack spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default Form;

import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import FilterSearch from '../../componets/filter/filterSearch';
import { IFormInputProps } from '../../componets/form/formInputText';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { TableGeneric } from '../../componets/table';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import {
    handleExceptionMessage,
    handleExceptionMultipleMessages,
} from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { objToQuery, queryToObj } from '../../util/query';
import {
    formatDateWithoutHours,
    momentZoneToDateAddDays,
    momentZoneToUnix,
    unixToDate,
} from '../../util/dateUtil';
import './styles.scss';
import { IconComponent, icons } from '../../componets/icons';
import { formatNumber } from '../../util/infoFormat';
import {
    GenericEnum,
    typeQueryOptions,
} from '../../communs/enums/generic-enum';
import { FormInputDateWithoutHour } from '../../componets/form/formInputDateWithoutHour';

interface IPhone {
    phoneType: string;
    phoneDdd: string;
    phoneNumber: string;
    branch: string;
    id?: string;
}

interface IInvoiceDetail {
    formPayment: string;
    typePayment: string;
    description: string;
    dueDate: Date;
    installmentValue: number;
    downloaded: boolean;
    workOrder?: {
        id: string;
        code: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
    };
    outputProduct?: {
        id: string;
        code: string;
        customer: {
            id: string;
            name: string;
            identification: string;
        };
    };
}

interface Data {
    id: string;
    description: string;
    email: string;
    identification?: string;
    phones: IPhone[];
}

interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date' | 'autocomplete';
    loadingAutocomplete?: boolean;
    options?: any[];
    setValue?: any;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const defaultValues = {
    code: '',
    customer: {
        name: '',
    },
    typeQuery: {
        id: '',
        description: '',
    },
    dateInitial: momentZoneToDateAddDays({ days: -90, startOfType: 'day' }),
    dateFinal: momentZoneToDateAddDays({ days: 0, endOfType: 'day' }),
};

const typeQueryInstance = new GenericEnum(typeQueryOptions);
const typeQueryEnum = typeQueryInstance.optionsList();

const ListEntity: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<IInvoiceDetail[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [deleteRegister, setDeleteRegister] = useState<boolean>(false);
    const params = useParams<{ id?: string }>();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();

    const {
        control,
        setValue,
        getValues,
        setError,
        clearErrors,
        getFieldState,
    } = useForm({
        defaultValues,
    });

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);
        try {
            let path = `output-product/with-invoices?${queryString}`;
            const queryObj = queryToObj(queryString);
            if (queryObj?.typeQuery === 'workOrder') {
                path = `work-order/with-invoices?${queryString}`;
            }
            const response = await api.get(path);
            setRows(response.data.data);
            setTotal(response.data.count);
            setOpenLoading(false);
            if (deleteRegister) {
                setDeleteRegister(false);
            }
        } catch (error) {
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: '',
            });
            setOpenLoading(false);
        }
    };

    useEffect(() => {
        let queryT = '';
        if (search && search.length > 0 && (!params || !params.id)) {
            const { limit, pageParam } = getParams();
            setRowsPerPage(Number(limit));
            setPage(Number(pageParam - 1));
            queryT = search.replace('?', '');
            setQuery(queryT);
        } else if (rowsPerPage === 0 && (!params || !params.id)) {
            let limit = rowsPerPage === 0 ? rowsPerPageOptions[0] : rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
                typeQuery: 'outputProduct',
                dateInitial: momentZoneToUnix(getValues('dateInitial')),
                dateFinal: momentZoneToUnix(getValues('dateFinal')),
            };

            const queryTemp = objToQuery(queryVO);
            history.push(`?${queryTemp}`);
        }

        if (queryT.length > 0) {
            loadRows(queryT);
            setParams(queryT);
        }
    }, [search]);

    useEffect(() => {
        const { limit, pageParam, dateInitial, dateFinal, code, typeQuery } =
            getParams();
        const customerName = getParams()['customer.name'];

        if (
            rowsPerPage > 0 &&
            (limit != rowsPerPage || page + 1 != pageParam)
        ) {
            let limit = rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
                'customer.name': customerName,
                dateInitial,
                dateFinal,
                code,
                typeQuery,
            };
            const queryTemp = objToQuery(queryVO);

            history.push(`?${queryTemp}`);
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (deleteRegister) {
            loadRows(query);
        }
    }, [deleteRegister]);

    function getParams() {
        let paramsQueryString = {
            limit: 0,
            pageParam: 0,
            page: 0,
            description: null,
            dateInitial: defaultValues.dateInitial,
            dateFinal: defaultValues.dateFinal,
            typeQuery: null,
            'customer.name': null,
            code: null,
        };
        if (search && search.length > 0) {
            paramsQueryString = queryToObj(search.replace('?', ''));
        }
        if (paramsQueryString.limit) {
            paramsQueryString.limit = Number(paramsQueryString.limit) || 0;
        }
        if (paramsQueryString.page) {
            paramsQueryString.pageParam = Number(paramsQueryString.page) || 0;
        }
        if (paramsQueryString.dateInitial) {
            paramsQueryString.dateInitial = paramsQueryString.dateInitial || '';
        }
        if (paramsQueryString.dateFinal) {
            paramsQueryString.dateFinal = paramsQueryString.dateFinal || '';
        }
        if (paramsQueryString['customer.name']) {
            paramsQueryString['customer.name'] =
                paramsQueryString['customer.name'] || null;
        }
        if (paramsQueryString.typeQuery) {
            paramsQueryString.typeQuery = paramsQueryString.typeQuery || null;
        }
        if (paramsQueryString.code) {
            paramsQueryString.code = paramsQueryString.code || null;
        }
        return paramsQueryString;
    }

    // Parâmetros de consulta padrão, para atualizar campos é dado um refresh na página
    function setParams(query: any) {
        const objQuery = queryToObj(query);
        if (objQuery?.typeQuery) {
            const optionOperation = typeQueryInstance.getObject(
                objQuery.typeQuery,
            );
            setValue('typeQuery', optionOperation);
        }
        if (objQuery?.dateInitial) {
            setValue('dateInitial', unixToDate(objQuery?.dateInitial));
        }
        if (objQuery?.dateFinal) {
            setValue('dateFinal', unixToDate(objQuery?.dateFinal));
        }
    }

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = () => {
        if (getFieldState('dateInitial').invalid) {
            return;
        }
        if (getFieldState('dateFinal').invalid) {
            return;
        }

        const queryObj = queryToObj(query);
        const newQuery = {
            ...queryObj,
            code: getValues('code'),
            'customer.name': getValues('customer.name'),
            dateInitial: momentZoneToUnix(getValues('dateInitial')),
            dateFinal: momentZoneToUnix(getValues('dateFinal')),
            typeQuery: getValues('typeQuery')?.id || '',
            page: 1,
        };
        setQuery(objToQuery(newQuery));
        history.push(`?${objToQuery(newQuery)}`);

        if (window.screen.width < 900) {
            setToggleSearch(false);
        }
    };

    const columnsDetPag = React.useMemo<ColumnDef<IInvoiceDetail>[]>(
        () => [
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Código / Número
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() =>
                                handleClick(
                                    row.original.id,
                                    row.original.typeQuery,
                                )
                            }>
                            {row.original?.code}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'date',
                cell: (cell: any) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() =>
                                handleClick(
                                    cell.row.original.id,
                                    cell.row.original.typeQuery,
                                )
                            }>
                            {formatDateWithoutHours(cell?.getValue())}
                        </TableCell>
                    );
                },
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: (info: any) => {
                    return (
                        <TableCell align={'left'} padding={'normal'}>
                            Cliente
                        </TableCell>
                    );
                },
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            align="left"
                            key={row.id}
                            onClick={() =>
                                handleClick(
                                    row.original.id,
                                    row.original.typeQuery,
                                )
                            }>
                            {row.original?.customer?.name}
                        </TableCell>
                    );
                },
            },
            {
                id: 'select',
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Identificação
                    </TableCell>
                ),
                cell: ({ row }: any) => {
                    return (
                        <TableCell
                            key={row.id}
                            align="left"
                            onClick={() =>
                                handleClick(
                                    row.original.id,
                                    row.original.typeQuery,
                                )
                            }>
                            {cpfAndCnpjMask(
                                row.original?.customer?.identification,
                                false,
                            )}
                        </TableCell>
                    );
                },
            },
            {
                accessorKey: 'total',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() =>
                            handleClick(
                                cell.row.original.id,
                                cell.row.original.typeQuery,
                            )
                        }>
                        {formatNumber(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Valor
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <Tooltip
                            title="Gerar parcelas"
                            onClick={() =>
                                handleClick(
                                    row.original.id,
                                    row.original.typeQuery,
                                )
                            }>
                            <IconButton>
                                <IconComponent icon={icons.eventNote} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const handleRunLow = async ({
        id,
        action,
    }: {
        id: string;
        action: 'unsubscribe' | 'writeOff';
    }) => {
        try {
            setOpenLoading(true);
            await api.patch(`invoice-detail/${id}/action/${action}`, {});
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            setDeleteRegister(true);
        } catch (error) {
            setOpenLoading(false);
            const messagesResponse = handleExceptionMultipleMessages(error);
            for (const messageResponse of messagesResponse) {
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
            }
            console.log(error);
        }
    };

    const handleClick = (id: string, typeQuery: string) => {
        handleEdit(id, typeQuery);
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            handleByOnKeyPressEnterNewQuery();
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOnChange = () => {
        handleByOnKeyPressEnterNewQuery();
    };

    const inputsFilter: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'code',
            variant: 'standard',
            control: control,
            label: 'Código / Número',
            handleOnKeyPress,
        },
        {
            typeInput: 'text',
            name: 'customer.name',
            variant: 'standard',
            control: control,
            label: 'Cliente',
            handleOnKeyPress,
        },
        {
            typeInput: 'autocomplete',
            name: 'typeQuery',
            variant: 'standard',
            control: control,
            loadingAutocomplete: false,
            setValue: setValue,
            label: 'Operação',
            options: typeQueryEnum,
            handleOnKeyPress,
        },
    ];

    const clearInputsFilter = () => {
        setValue('code', '');
        setValue('customer', { name: '' });
        setValue('typeQuery', typeQueryInstance.getObject('outputProduct'));
    };

    const clearFilters = () => {
        const queryObj = queryToObj(query);
        const newQuery = {
            ...queryObj,
            code: null,
            'customer.name': null,
            typeQuery: typeQueryInstance.getObject('outputProduct').id,
        };
        setQuery(objToQuery(newQuery));
        history.push(`?${objToQuery(newQuery)}`);
    };

    const handleClearFilters = (): void => {
        clearInputsFilter();
        clearFilters();
    };

    const handleEdit = (id: string, typeQuery: string) => {
        history.push(`${paths.invoiceDetailRegister}/${id}/${typeQuery}`);
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`provider/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setDeleteRegister(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalDelete(false);
            setIdDelete('');
        }
    };

    const emptyRows = () => {
        if (rowsPerPage > rows.length) {
            return rowsPerPage - rows.length;
        }
        return 0;
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }} component="div">
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Faturas geradas
                            </Typography>
                        </div>

                        <div className="header-list-search">
                            <Stack spacing={2} direction="row" width={'100%'}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name="dateInitial"
                                            control={control}
                                            rules={null}
                                            label="Data de início"
                                            setValue={setValue}
                                            fullWidth={true}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            handleChange={(_: any) =>
                                                handleOnChange()
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name="dateFinal"
                                            control={control}
                                            rules={null}
                                            label="Data de fim"
                                            setValue={setValue}
                                            fullWidth={true}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            handleChange={(_: any) =>
                                                handleOnChange()
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                    <TableGeneric
                        data={rows}
                        columns={columnsDetPag}
                        minWidth={650}
                    />
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>

            {toggleSearch && (
                <FilterSearch
                    inputs={inputsFilter}
                    handleClearFilters={handleClearFilters}
                    setToggleSearch={setToggleSearch}
                />
            )}
        </div>
    );
};

export default ListEntity;

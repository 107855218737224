import {
    DownloadDone,
    NotInterested,
    CurrencyExchange,
    Paid,
    PriceCheck,
    EventNote,
    Close,
    RequestQuote,
    Print,
    InstallDesktop,
} from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface IButtonProps extends SvgIconProps {
    icon: any;
    width?: number;
    height?: number;
}

export const icons = {
    downloadDone: DownloadDone,
    notInterested: NotInterested,
    currencyExchange: CurrencyExchange,
    paid: Paid,
    priceCheck: PriceCheck,
    eventNote: EventNote,
    close: Close,
    requestQuote: RequestQuote,
    print: Print,
    installDesktop: InstallDesktop,
};

export const IconComponent = (properties: IButtonProps) => {
    return (
        <SvgIcon
            component={properties.icon}
            disabled
            // sx={{ width: 24, height: 24 }}
        />
    );
};

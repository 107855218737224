import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    right: calc(50% - 180px);
    top: 0;
    padding: 10px;
    margin: 0;
    overflow: hidden;
`;

import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';

const labelProps = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface IFormInputProps {
    name: string;
    control: any;
}

export const FormCheckbox = ({ name, control }: IFormInputProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
            }) => {
                return (
                    <Checkbox
                        checked={value}
                        onChange={event => {
                            onChange(event);
                        }}
                    />
                );
            }}
        />
    );
};

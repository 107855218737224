import { Delete, ModeEdit } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Typography,
    Tabs,
    Tab,
    Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import moment from 'moment';
import {
    ColumnDef,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';

import { useTable } from 'react-table';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { TableCellBaseProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { optionsCstIpint } from '../../communs/enums/cstIpint';
import { optionsCstIpiTrib } from '../../communs/enums/cstIpiTrib';
import { optionsCstPisnt } from '../../communs/enums/cstPisnt';
import { optionsList } from '../../communs/enums/originMerchandise';
//
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormInputDate } from '../../componets/form/formInputDate';
import { FormInputNumber } from '../../componets/form/formInputNumber';
import { FormInputText } from '../../componets/form/formInputText';
import { FormInputProps } from '../../componets/form/formInterfaces';
import { paths } from '../../config';
import { useAuth } from '../../context/AuthContext';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { downloadReport } from '../../services/download-report';
import { formatDate, momentZoneToDate } from '../../util/dateUtil';
import { resolveValueObject } from '../../util/findValueObjectByKey';
import {
    handleExceptionMessage,
    handleExceptionMessageApiNfe,
} from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import {
    floatValue,
    formatNumber,
    IObjectKeys,
    roundNumber,
} from '../../util/infoFormat';
import { useWindowSize } from '../../util/responsiveness';
import {
    crtOptions,
    environmentOptions,
    finNFeOptions,
    GenericEnum,
    idDestOptions,
    indFinalOptions,
    indIEDestOptions,
    indPagOptions,
    indPresOptions,
    indTotOptions,
    modFreteOptions,
    procEmiOptions,
    tPagOptions,
    tpEmisOptions,
    tpImpOptions,
    tpNfOptions,
    yesOrNoOptions,
} from '../../communs/enums/generic-enum';
import { cepMask } from '../../componets/form/mask/cep';
import { phoneMask } from '../../componets/form/mask/phone';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { Label } from 'recharts';
import { FormInputDateWithoutHour } from '../../componets/form/formInputDateWithoutHour';
import makeData from './makeData';
import { TableGeneric } from '../../componets/table';

type Person = {
    firstName: string;
    lastName: string;
    age: number;
    visits: number;
    status: string;
    progress: number;
};

const defaultData: Person[] = [
    {
        firstName: 'tanner',
        lastName: 'linsley',
        age: 24,
        visits: 100,
        status: 'In Relationship',
        progress: 50,
    },
    {
        firstName: 'tandy',
        lastName: 'miller',
        age: 40,
        visits: 40,
        status: 'Single',
        progress: 80,
    },
    {
        firstName: 'joe',
        lastName: 'dirte',
        age: 45,
        visits: 20,
        status: 'Complicated',
        progress: 10,
    },
];

interface Expanded {
    infoProductNfe: boolean;
    imposto: boolean;
    icmssn102: boolean;
    ipi: boolean;
    pis: boolean;
    cofins: boolean;
}

interface GeradorParcelas {
    indPag: Description;
    tpag: Description;
    numberInstallments: number;
    withEntrance: Description;
    valueEntry?: number | null;
    dateEntry?: Date | null;
    dueDate: number; // Dia vencimento
    vnf: number;
}

interface Prod extends IObjectKeys {
    xprod: string;
    cprod: string;
    qcom: string;
    vunCom: string;
    vprod: string;
    vunTrib: string;
    qtrib: string;
}

interface IItemProduct extends IObjectKeys {
    prod: Prod;
    product?: {
        id: string;
        code: string;
        description: string;
        quantity: number;
        localization: string;
    };
    valueOutput: number | undefined;
    quantity?: number;
}
interface Description {
    id: string;
    description: string;
}
interface Name {
    id: string;
    name: string;
}

interface Ender {
    xlgr: string;
    nro: string;
    xbairro: string;
    cmun: string;
    xmun: string;
    cep: string;
    cpais: string;
    xpais: string;
    uf: string;
    foneDdd: string;
    foneNumero: string;
}

interface Total {
    icmstot: {
        vbc: string;
        vicms: string;
        vicmsdeson: string;
        vfcp: string;
        vfcpst: string;
        vfcpstret: string;
        vbcst: string;
        vst: string;
        vprod: string;
        vfrete: string;
        vseg: string;
        vdesc: string;
        vii: string;
        vipi: string;
        vipidevol: string;
        vpis: string;
        vcofins: string;
        voutro: string;
        vnf: string;
    };
}

interface Transp {
    modFrete: Description;
}

interface DetPagamento extends IObjectKeys {
    // Campos para NFe
    indPag: Description;
    tpag: Description;
    vpag: number | string;
    // Campos que não são utlizados na NFe
    description: string;
    dueDate: Date;
}
interface IFormInput {
    referenceId: string;
    code?: string;
    numberNfe?: string;
    det: IItemProduct[];
    // Início tab Dados NFe
    estado: string;
    ambiente: Description;
    idLote: string;
    indSinc: string;
    ide: {
        serie: string;
        dhEmi: Date;
        tpNF: Description;
        cMunFG: string;
        tpImp: Description;
        tpEmis: Description;
        finNFe: Description;
        indFinal: Description;
        indPres: Description;
        procEmi: Description;
        idDest: Description;
    };
    // Início tab Emitente
    emit: {
        ie: string;
        crt: Description;
        xnome: string;
        enderEmit: {
            xlgr: string;
            nro: string;
            xbairro: string;
            cmun: string;
            xmun: string;
            cep: string;
            cpais: string;
            xpais: string;
            uf: string;
            foneDdd: string;
            foneNumero: string;
        };
    };
    // Início tab Destinatário
    dest: {
        indIEDest: Description; // Indicador da IE do destinatário
        identificacao: string; // Pode ser o cnpj ou cpf
        xnome: string;
        ie?: string; // Inscrição Estadual do Destinatário
        isuf?: string; // Inscrição na SUFRAMA
        im?: string; // Inscrição Municipal do Tomador do Serviço
        email?: string;
        enderDest: Ender;
    };
    total: Total;
    transp: Transp;
    pag: {
        detPagamento: DetPagamento[];
    };
    indPag: Description | null;
    withEntrance: string;
    valueEntry: number;
    dateEntry: Date;
    numberInstallments: number | null; // Quant. parcelas
    dueDate: Date; // Dia vencimento
}
interface ModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    index: number;
    setIndex?: (index: number) => void;
    getValues: any;
    append: any;
    update: any;
    fields: any[];
    width: number;
}

interface ModalPropsPlotGenerator {
    open: boolean;
    setOpen: (open: boolean) => void;
    getValues: any;
    setValue: any;
    width: number;
    setTotal: (total: number) => void;
}

const rules = {};

interface HeadCell {
    disablePadding: boolean;
    // id: keyof Data;
    id: string;
    label: string;
    numeric: boolean;
}

interface BodyCell {
    component?: React.ElementType<TableCellBaseProps>;
    scope: string;
    // keyName: keyof IItemProduct;
    keyName: string;
    onClick?: (index: any) => void;
    replace?: (value: any, keyName: string) => any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const indOptions = new GenericEnum(indTotOptions).optionsList();
const environmentEnum = new GenericEnum(environmentOptions).optionsList();
const tpNfEnum = new GenericEnum(tpNfOptions).optionsList();
const tpImpEnum = new GenericEnum(tpImpOptions).optionsList();
const tpEmisEnum = new GenericEnum(tpEmisOptions).optionsList();
const finNFeEnum = new GenericEnum(finNFeOptions).optionsList();
const indFinalEnum = new GenericEnum(indFinalOptions).optionsList();
const indPresEnum = new GenericEnum(indPresOptions).optionsList();
const procEmiEnum = new GenericEnum(procEmiOptions).optionsList();
const idDestEnum = new GenericEnum(idDestOptions).optionsList();
const crtEnum = new GenericEnum(crtOptions).optionsList();
const indIEDestEnum = new GenericEnum(indIEDestOptions).optionsList();
const modFreteEnum = new GenericEnum(modFreteOptions).optionsList();
const indPagInstance = new GenericEnum(indPagOptions);
const indPagEnum = indPagInstance.optionsList();
const tPagInstance = new GenericEnum(tPagOptions);
const tPagEnum = tPagInstance.optionsList();
const yesOrNoInstance = new GenericEnum(yesOrNoOptions);
const yesOrNoEnum = yesOrNoInstance.optionsList();

const headCells: readonly HeadCell[] = [
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'Código',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Produto',
    },
    {
        id: 'value',
        numeric: false,
        disablePadding: false,
        label: 'Valor pago (R$)',
    },
    {
        id: 'quantity',
        numeric: false,
        disablePadding: false,
        label: 'Valor pago (R$)',
    },
    {
        id: 'subTotal',
        numeric: false,
        disablePadding: false,
        label: 'Sub total (vProd)',
    },
];

const headCellsDetPag: readonly HeadCell[] = [
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Descrição',
    },
    {
        id: 'tpag',
        numeric: false,
        disablePadding: false,
        label: 'Forma de pagamento',
    },
    {
        id: 'vpag',
        numeric: false,
        disablePadding: false,
        label: 'Valor',
    },
];

const messageError = (errors: any, field: any) => {
    return '';
};

// const data = {
//     estado: 'SC',
//     ambiente: 'HOMOLOGACAO',
//     idLote: '1',
//     indSinc: '1',
//     ide: {
//         serie: '889',
//         dhEmi: '2023-01-21T22:25:39.492Z',
//         tpNF: '1',
//         cMunFG: '4201901',
//         tpImp: '1',
//         tpEmis: '1',
//         finNFe: '1',
//         indFinal: '1',
//         indPres: '0',
//         procEmi: '0',
//         idDest: '1',
//     },
//     emit: {
//         ie: '260685577',
//         crt: '1',
//         enderEmit: {
//             xlgr: '25 DE JULHO',
//             nro: '250',
//             xbairro: 'CENTRO',
//             cmun: '4201901',
//             xmun: 'AURORA',
//             cep: '72900000',
//             cpais: '1058',
//             xpais: 'Brasil',
//             fone: '6233215175',
//         },
//     },
//     dest: {
//         indIEDest: '9',
//         email: 'test@test.com',
//         cnpj: '87305277000100',
//         xnome: 'NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL',
//         enderDest: {
//             xlgr: 'Rua Teste',
//             nro: '0',
//             xbairro: 'TESTE',
//             cmun: '4109708',
//             xmun: 'IBAITI',
//             uf: 'PR',
//             cep: '84900000',
//             cpais: '1058',
//             xpais: 'Brasil',
//             fone: '4845454545',
//         },
//     },
//     det: [
//         {
//             prod: {
//                 cprod: 'COD001',
//                 cean: null,
//                 xprod: 'NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL',
//                 ncm: '84678100',
//                 cfop: '5102',
//                 ucom: 'UN',
//                 qcom: '1',
//                 vunCom: '1305.0000',
//                 ceantrib: null,
//                 utrib: 'UN',
//                 qtrib: '1.0000',
//                 vunTrib: '1305.0000',
//                 indTot: '1',
//                 vprod: '1305.0000',
//             },
//             imposto: {
//                 icmssn102: {
//                     orig: {
//                         id: '0',
//                         description: 'Nacional',
//                     },
//                     csosn: '102',
//                 },
//                 ipi: {
//                     cenq: '999',
//                     ipint: {
//                         cst: { id: '53', description: 'Saída não-tributada' },
//                     },
//                 },
//                 pis: {
//                     pisnt: {
//                         cst: {
//                             id: '08',
//                             description:
//                                 'Operação Sem Incidência da contribuição',
//                         },
//                     },
//                 },
//                 cofinsnt: {
//                     cst: {
//                         id: '08',
//                         description: 'Operação Sem Incidência da contribuição',
//                     },
//                 },
//             },
//         },
//         {
//             prod: {
//                 cprod: '00002',
//                 cean: null,
//                 xprod: 'NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL',
//                 ncm: '84678100',
//                 cfop: '5102',
//                 ucom: 'UN',
//                 qcom: '1',
//                 vunCom: '1450.0000',
//                 ceantrib: null,
//                 utrib: 'UN',
//                 qtrib: '1.0000',
//                 vunTrib: '1450.0000',
//                 indTot: '1',
//                 vprod: '1450.0000',
//             },
//             imposto: {
//                 icmssn102: {
//                     orig: {
//                         id: '0',
//                         description: 'Nacional',
//                     },
//                     csosn: '102',
//                 },
//                 ipi: {
//                     cenq: '999',
//                     ipint: {
//                         cst: { id: '53', description: 'Saída não-tributada' },
//                     },
//                     ipiTrib: {
//                         cst: {
//                             id: '99',
//                             description: '99-Outras saídas',
//                         },
//                     },
//                 },
//                 pis: {
//                     pisnt: {
//                         cst: {
//                             id: '08',
//                             description:
//                                 'Operação Sem Incidência da contribuição',
//                         },
//                     },
//                 },
//                 cofins: {
//                     cofinsnt: {
//                         cst: {
//                             id: '08',
//                             description:
//                                 'Operação Sem Incidência da contribuição',
//                         },
//                     },
//                 },
//             },
//         },
//     ],
//     transp: {
//         modFrete: '9',
//     },
//     pag: {
//         detPagamento: [
//             {
//                 indPag: '1',
//                 tpag: '01',
//                 vpag: '320.00',
//             },
//             {
//                 indPag: '1',
//                 tpag: '15',
//                 vpag: '2435.00',
//             },
//         ],
//     },
//     total: {
//         icmstot: {
//             vbc: '0.00',
//             vicms: '0.00',
//             vicmsdeson: '0.00',
//             vfcp: '0.00',
//             vfcpst: '0.00',
//             vfcpstret: '0.00',
//             vbcst: '0.00',
//             vst: '0.00',
//             vprod: '2755.00',
//             vfrete: '0.00',
//             vseg: '0.00',
//             vdesc: '0.00',
//             vii: '0.00',
//             vipi: '0.00',
//             vipidevol: '0.00',
//             vpis: '0.00',
//             vcofins: '0.00',
//             voutro: '0.00',
//             vnf: '2755.00',
//         },
//     },
// };

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Form: React.FC = () => {
    const columnsDetPag = React.useMemo<ColumnDef<DetPagamento>[]>(
        () => [
            {
                accessorKey: 'description',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() => handleEditItemTableDetPag(cell.row.id)}>
                        {cell?.getValue()}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Descrição
                    </TableCell>
                ),
            },
            {
                accessorFn: (row: any) => row.tpag.description,
                id: 'tpag',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() => handleEditItemTableDetPag(cell.row.id)}>
                        {cell?.getValue()}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Forma de pagamento
                    </TableCell>
                ),
            },
            {
                accessorFn: (row: any) => row.dueDate,
                id: 'dueDate',
                cell: (cell: any) => (
                    <TableCell
                        align="left"
                        onClick={() => handleEditItemTableDetPag(cell.row.id)}>
                        {formatDate(cell?.getValue())}
                    </TableCell>
                ),
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Data
                    </TableCell>
                ),
            },
            {
                accessorFn: (row: any) => row.vpag,
                id: 'vpag',
                cell: (cell: any) => {
                    return (
                        <TableCell
                            align="left"
                            onClick={() =>
                                handleEditItemTableDetPag(cell.row.id)
                            }>
                            {formatNumber(cell?.getValue())}
                        </TableCell>
                    );
                },
                header: () => (
                    <TableCell align={'left'} padding={'normal'}>
                        Valor
                    </TableCell>
                ),
            },
            {
                id: 'select',
                header: ({ table }: any) => <TableCell align="center" />,
                cell: ({ row }: any) => (
                    <TableCell align="right">
                        <IconButton
                            onClick={() => handleEditItemTableDetPag(row.id)}>
                            <ModeEdit />
                        </IconButton>
                    </TableCell>
                ),
            },
        ],
        [],
    );

    const [data, setData] = React.useState<DetPagamento[]>(() => []);

    const defaultValues = {};

    const history = useHistory();
    const params = useParams<'id' | any>();
    const [width] = useWindowSize();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [showValueEntry, setShowValueEntry] = useState(false);

    const [options, setOptions] = useState<{ name: string; id: string }[]>([]);
    const [optionsSellers, setOptionsSellers] = useState<
        { name: string; id: string }[]
    >([]);
    const [total, setTotal] = useState<number>(0);
    const [totalDetPag, setTotalDetPag] = useState<number>(0);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const [openModalItemProduct, setOpenModalItemProduct] = useState(false);
    const [indexItem, setIndexItem] = useState<number>(-1);
    const [indexItemDetPag, setIndexItemDetPag] = useState<number>(-1);
    const [openModalDetPag, setOpenModalDetPag] = useState(false);
    const [messageDialogTitle, setMessageDialogTitle] = useState<
        string | undefined
    >(undefined);
    const [openModalDownloadReport, setOpenModalDownloadReport] = useState<{
        open: boolean;
        numberNfe?: string;
        type?: string;
    }>({ open: false, numberNfe: undefined, type: undefined });
    const [openModalDownloadReportByType, setOpenModalDownloadReportByType] =
        useState<{
            open: boolean;
            numberNfe?: string;
            type?: string;
        }>({ open: false, numberNfe: undefined, type: undefined });
    const [valueTab, setValueTab] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const { user } = useAuth();

    const { handleSubmit, control, reset, setValue, getValues } =
        useForm<IFormInput>({
            defaultValues,
        });

    const { fields, append, remove, update } = useFieldArray<
        IFormInput,
        'det',
        'detId'
    >({
        control,
        name: 'det',
        keyName: 'detId',
    });

    const {
        fields: fieldsDetPag,
        append: appendDetPag,
        update: updateDetPag,
    } = useFieldArray<IFormInput, 'pag.detPagamento', 'detPagamentoId'>({
        control,
        name: 'pag.detPagamento',
        keyName: 'detPagamentoId',
    });

    useEffect(() => {
        if (getValues('pag.detPagamento')?.length > 0) {
            setData(getValues('pag.detPagamento'));
        }
    }, [getValues('pag.detPagamento')]);

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`invoice-output-product/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    useEffect(() => {
        const totalSale = fields.reduce((accumulator, item) => {
            const quantity = Number(item.prod.qcom);
            const value = Number(item.prod.vunCom);
            return accumulator + quantity * value;
        }, 0);

        setTotal(totalSale);
    }, [fields]);

    useEffect(() => {
        const totDetPag = fieldsDetPag.reduce((accumulator, item) => {
            const value = Number(item.vpag);
            return accumulator + value;
        }, 0);

        setTotalDetPag(totDetPag);
    }, [fieldsDetPag]);

    const setModel = (data: IFormInput) => {
        // Referência da saída
        setValue('referenceId', params.id);

        setValue('code', data.code);
        setValue('numberNfe', data.numberNfe);

        // Dados NFe
        setValue('estado', data.estado);
        setValue('ambiente', data.ambiente);
        setValue('idLote', data.idLote);
        setValue('indSinc', data.indSinc);
        setValue('ide.serie', data.ide.serie);
        setValue('ide.dhEmi', data.ide.dhEmi);
        setValue('ide.tpNF', data.ide.tpNF);
        setValue('ide.cMunFG', data.ide.cMunFG);
        setValue('ide.tpImp', data.ide.tpImp);
        setValue('ide.tpEmis', data.ide.tpEmis);
        setValue('ide.finNFe', data.ide.finNFe);
        setValue('ide.indFinal', data.ide.indFinal);
        setValue('ide.indPres', data.ide.indPres);
        setValue('ide.procEmi', data.ide.procEmi);
        setValue('ide.idDest', data.ide.idDest);

        // Emitente
        setValue('emit.ie', data.emit.ie);
        setValue('emit.crt', data.emit.crt);
        setValue('emit.xnome', data.emit.xnome);
        setValue('emit.enderEmit.xlgr', data.emit.enderEmit.xlgr);
        setValue('emit.enderEmit.nro', data.emit.enderEmit.nro);
        setValue('emit.enderEmit.xbairro', data.emit.enderEmit.xbairro);
        setValue('emit.enderEmit.cmun', data.emit.enderEmit.cmun);
        setValue('emit.enderEmit.xmun', data.emit.enderEmit.xmun);
        setValue('emit.enderEmit.uf', data.emit.enderEmit.uf);
        setValue('emit.enderEmit.cep', cepMask(data.emit.enderEmit.cep, false));
        setValue('emit.enderEmit.cpais', data.emit.enderEmit.cpais);
        setValue('emit.enderEmit.xpais', data.emit.enderEmit.xpais);
        setValue('emit.enderEmit.foneDdd', data.emit.enderEmit.foneDdd);
        setValue(
            'emit.enderEmit.foneNumero',
            phoneMask(data.emit.enderEmit.foneNumero, false),
        );

        // Destinatário
        setValue('dest', data.dest);
        setValue(
            'dest.enderDest.foneNumero',
            phoneMask(data.dest.enderDest.foneNumero, false),
        );

        // Total
        setValue('total.icmstot.vbc', data.total.icmstot.vbc);
        setValue('total.icmstot.vicms', data.total.icmstot.vicms);
        setValue('total.icmstot.vicmsdeson', data.total.icmstot.vicmsdeson);
        setValue('total.icmstot.vfcp', data.total.icmstot.vfcp);
        setValue('total.icmstot.vfcpst', data.total.icmstot.vfcpst);
        setValue('total.icmstot.vfcpstret', data.total.icmstot.vfcpstret);
        setValue('total.icmstot.vbcst', data.total.icmstot.vbcst);
        setValue('total.icmstot.vst', data.total.icmstot.vst);
        setValue('total.icmstot.vprod', data.total.icmstot.vprod);
        setValue('total.icmstot.vfrete', data.total.icmstot.vfrete);
        setValue('total.icmstot.vseg', data.total.icmstot.vseg);
        setValue('total.icmstot.vdesc', data.total.icmstot.vdesc);
        setValue('total.icmstot.vii', data.total.icmstot.vii);
        setValue('total.icmstot.vipi', data.total.icmstot.vipi);
        setValue('total.icmstot.vipidevol', data.total.icmstot.vipidevol);
        setValue('total.icmstot.vpis', data.total.icmstot.vpis);
        setValue('total.icmstot.vcofins', data.total.icmstot.vcofins);
        setValue('total.icmstot.voutro', data.total.icmstot.voutro);
        setValue('total.icmstot.vnf', data.total.icmstot.vnf);

        //Transportador
        setValue('transp.modFrete', data.transp.modFrete);

        setValue('det', data.det);

        // if (!) {
        //     const indPag = indPagInstance.getObject('0'); // À vista
        //     const tPag = tPagInstance.getObject('15'); // Boleto
        //     setValue('indPag', indPag);
        //     setValue('numberInstallments', 1);
        //     const detPagamento: DetPagamento[] = [
        //         {
        //             indPag,
        //             tpag: tPag,
        //             vpag: data.total.icmstot.vnf,
        //         },
        //     ];
        //     setValue('pag.detPagamento', detPagamento);
        if (data?.pag?.detPagamento?.length > 0) {
            setValue('indPag', data.indPag);
            setValue('numberInstallments', data.pag.detPagamento.length);
            setValue('pag.detPagamento', data.pag.detPagamento);
        }
    };

    const submit = async (data: any, event: any) => {
        event.preventDefault();
        try {
            if (!fields || fields.length === 0) {
                addToast({
                    type: 'warn',
                    title: message.warn.save,
                    description: 'A saída tem que ter pelo menos um item.',
                });
                return;
            }
            // if (getValues('numberNfe')) {
            //     addToast({
            //         type: 'warn',
            //         title: message.warn.save,
            //         description:
            //             'Essa nota já foi emitida. Consulte seu contador',
            //     });
            //     return;
            // }

            setOpenLoading(true);
            data.emit.enderEmit.cep = data.emit.enderEmit.cep.replace(
                /\D/g,
                '',
            );
            data.emit.enderEmit.foneNumero =
                data.emit.enderEmit.foneNumero.replace(/\D/g, '');
            data.emit.enderEmit.fone = `${data.emit.enderEmit.foneDdd}${data.emit.enderEmit.foneNumero}`;

            data.dest.enderDest.foneNumero =
                data.dest.enderDest.foneNumero.replace(/\D/g, '');
            data.dest.enderDest.fone = `${data.dest.enderDest.foneDdd}${data.dest.enderDest.foneNumero}`;

            data.dest.identificacao = data.dest.identificacao.replace(
                /\D/g,
                '',
            );

            delete data.indPag;

            const response = await api.post('invoice-output-product', data);
            const numberNfe = response?.data?.data?.nfe[0]?.infNFe?.id;

            setMessageDialogTitle('Deseja imprimir a NFe?');

            setOpenModalDownloadReport(prevState => ({
                ...prevState,
                numberNfe: numberNfe,
                type: 'pdf',
                open: true,
            }));

            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
        } catch (error) {
            setOpenLoading(false);
            const messagesResponse = handleExceptionMessageApiNfe(error);
            for (const messageResponse of messagesResponse) {
                addToast({
                    type: 'error',
                    title: message.error.save,
                    description: messageResponse,
                });
            }
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`output-product/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.outputProduct}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const handleConfirmeDownloadReport = async (confirm: boolean) => {
        if (confirm) {
            setOpenLoading(true);
            try {
                await downloadReport({
                    nameReport: 'nfe_api',
                    params: {
                        numberNfe: openModalDownloadReport.numberNfe,
                        type: openModalDownloadReport.type,
                    },
                });
                setOpenLoading(false);
            } catch (error: any) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.delete,
                    description: messageResponse || error.message,
                });
            }
        }
        setMessageDialogTitle(undefined);
        setOpenModalDownloadReport({
            open: false,
            numberNfe: undefined,
            type: undefined,
        });
        history.push(`${paths.outputProduct}?`);
    };

    const handleConfirmeDownloadReportByType = async (confirm: boolean) => {
        if (confirm) {
            setOpenLoading(true);
            try {
                await downloadReport({
                    nameReport: 'nfe_api',
                    params: {
                        numberNfe: openModalDownloadReportByType.numberNfe,
                        type: openModalDownloadReportByType.type,
                    },
                });
                setOpenLoading(false);
            } catch (error: any) {
                setOpenLoading(false);
                const messageResponse = handleExceptionMessage(error);
                addToast({
                    type: 'error',
                    title: message.error.delete,
                    description: messageResponse || error.message,
                });
            }
        }
        setMessageDialogTitle(undefined);
        setOpenModalDownloadReportByType({
            open: false,
            numberNfe: undefined,
            type: undefined,
        });
    };

    const handleEditItemTable = (index: number): void => {
        setIndexItem(index);
        setOpenModalItemProduct(true);
    };

    const handleDownloadNfe = (type: 'pdf' | 'xml') => {
        setMessageDialogTitle('Download NFe');

        setOpenModalDownloadReportByType(prevState => ({
            ...prevState,
            numberNfe: getValues('numberNfe'),
            type: type,
            open: true,
        }));
    };

    const bodyCells: readonly BodyCell[] = [
        {
            component: 'th',
            scope: 'row',
            keyName: 'prod.cprod',
            onClick: handleEditItemTable,
            replace: (obj: any, keyName: string) => {
                return resolveValueObject(keyName, obj);
            },
        },
        {
            component: 'th',
            scope: 'row',
            keyName: 'prod.xprod',
            onClick: handleEditItemTable,
            replace: (obj: any, keyName: string) => {
                return resolveValueObject(keyName, obj);
            },
        },
        {
            component: 'th',
            scope: 'row',
            keyName: 'prod.qcom',
            onClick: handleEditItemTable,
            replace: (obj: any, keyName: string) => {
                return resolveValueObject(keyName, obj);
            },
        },
        {
            component: 'th',
            scope: 'row',
            keyName: 'prod.vunCom',
            onClick: handleEditItemTable,
            replace: (obj: any, keyName: string) => {
                return formatNumber(resolveValueObject(keyName, obj));
            },
        },
        {
            component: 'th',
            scope: 'row',
            keyName: 'prod.vprod',
            onClick: handleEditItemTable,
            replace: (obj: any, keyName: string) => {
                return formatNumber(resolveValueObject(keyName, obj));
            },
        },
    ];

    const handleEditItemTableDetPag = (index: number): void => {
        setIndexItemDetPag(index);
        setOpenModalDetPag(true);
    };

    const dataNFe: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'ambiente',
            control: control,
            label: 'Ambiente',
            loadingAutocomplete: false,
            setValue: setValue,
            options: environmentEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'estado',
            control: control,
            label: 'Estado',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'idLote',
            control: control,
            label: 'Tipo identificação de lote',
            md: 3,
            xs: 12,
            readOnly: true,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'indSinc',
            control: control,
            label: 'Tipo pedido de concessão de autorização da Nota fiscal',
            md: 3,
            xs: 12,
            readOnly: true,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'ide.serie',
            control: control,
            label: 'Série',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'date',
            name: 'ide.dhEmi',
            control: control,
            label: 'Data emissão',
            setValue: setValue,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'ide.tpNF',
            control: control,
            label: 'Tipo da nota',
            loadingAutocomplete: false,
            setValue: setValue,
            options: tpNfEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'ide.cMunFG',
            control: control,
            label: 'Código da cidade',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'ide.tpImp',
            control: control,
            label: 'Tipo de impressão',
            loadingAutocomplete: false,
            setValue: setValue,
            options: tpImpEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'ide.tpEmis',
            control: control,
            label: 'Tipo de emissão',
            loadingAutocomplete: false,
            setValue: setValue,
            options: tpEmisEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'ide.finNFe',
            control: control,
            label: 'Finalidade da emissão da NF-e',
            loadingAutocomplete: false,
            setValue: setValue,
            options: finNFeEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'ide.finNFe',
            control: control,
            label: 'Indica operação com consumidor final',
            loadingAutocomplete: false,
            setValue: setValue,
            options: indFinalEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'ide.indPres',
            control: control,
            label: 'Comprador presente no estabelecimento no momento da operação',
            loadingAutocomplete: false,
            setValue: setValue,
            options: indPresEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'ide.procEmi',
            control: control,
            label: 'Processo de emissão utilizado com:',
            loadingAutocomplete: false,
            setValue: setValue,
            options: procEmiEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'ide.idDest',
            control: control,
            label: 'Identificador de local de destino da operação',
            loadingAutocomplete: false,
            setValue: setValue,
            options: idDestEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
    ];

    const dataEmit: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'emit.ie',
            control: control,
            label: 'Inscrição estadual',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'emit.crt',
            control: control,
            label: 'Código do regime tributário',
            setValue,
            loadingAutocomplete: false,
            options: crtEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.xnome',
            control: control,
            label: 'Nome',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.xlgr',
            control: control,
            label: 'Logradouro',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.nro',
            control: control,
            label: 'Número',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.xbairro',
            control: control,
            label: 'Bairro',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.cmun',
            control: control,
            label: 'Código município',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.xmun',
            control: control,
            label: 'Nome município',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.uf',
            control: control,
            label: 'UF',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.cep',
            control: control,
            label: 'CEP',
            md: 3,
            xs: 12,
            mask: 'cep',
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.cpais',
            control: control,
            label: 'Código país',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.xpais',
            control: control,
            label: 'Nome país',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.foneDdd',
            control: control,
            label: 'DDD',
            md: 1,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'emit.enderEmit.foneNumero',
            control: control,
            label: 'Telefone',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
    ];

    const dataDest: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'dest.indIEDest',
            control: control,
            label: 'Indicador da IE do destinatário',
            setValue,
            loadingAutocomplete: false,
            options: indIEDestEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.identificacao',
            control: control,
            label: 'Identificação (CPF/CNPJ)',
            mask: 'identification',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.xnome',
            control: control,
            label: 'Cliente',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.ie',
            control: control,
            label: 'Inscrição estadual',
            md: 3,
            xs: 12,
            rules: {},
        },
        {
            typeInput: 'text',
            name: 'dest.isuf',
            control: control,
            label: 'Inscrição na SUFRAMA',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.im',
            control: control,
            label: 'Inscrição municipal do tomador do serviço',
            md: 3,
            xs: 12,
            rules: {},
        },
        {
            typeInput: 'text',
            name: 'dest.email',
            control: control,
            label: 'Inscrição municipal do tomador do serviço',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.xlgr',
            control: control,
            label: 'Logradouro',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.nro',
            control: control,
            label: 'Número',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.xbairro',
            control: control,
            label: 'Bairro',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.cmun',
            control: control,
            label: 'Código município',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.xmun',
            control: control,
            label: 'Nome município',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.uf',
            control: control,
            label: 'UF',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.cep',
            control: control,
            label: 'CEP',
            md: 3,
            xs: 12,
            mask: 'cep',
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.cpais',
            control: control,
            label: 'Código país',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.xpais',
            control: control,
            label: 'Nome país',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.foneDdd',
            control: control,
            label: 'DDD',
            md: 1,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'dest.enderDest.foneNumero',
            control: control,
            label: 'Telefone',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
    ];

    const dataTotal: FormInputProps[] = [
        {
            typeInput: 'number',
            name: 'total.icmstot.vbc',
            control: control,
            label: 'Valor base de cálculo (BC)',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vicms',
            control: control,
            label: 'Valor do ICMS',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vicmsdeson',
            control: control,
            label: 'Valor do ICMS desonerado',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vfcp',
            control: control,
            label: 'Valor do ICMS relativo ao fundo de combate à pobreza (FCP)',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vfcpst',
            control: control,
            label: 'Valor do FCP retido por substituição tributária',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vst',
            control: control,
            label: 'Valor total do ICMS ST',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vfrete',
            control: control,
            label: 'Valor total do frete',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vseg',
            control: control,
            label: 'Valor total do seguro',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vdesc',
            control: control,
            label: 'Valor Total do Desconto',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vii',
            control: control,
            label: 'Valor Total do II',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vipi',
            control: control,
            label: 'Valor Total do IPI',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vipidevol',
            control: control,
            label: 'Valor total do IPI devolvido',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vpis',
            control: control,
            label: 'Valor do PIS',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vcofins',
            control: control,
            label: 'Valor da COFINS',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.voutro',
            control: control,
            label: 'Outras despesas acessórias',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vprod',
            control: control,
            label: 'Valor total bruto dos produtos ou serviços',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
        {
            typeInput: 'number',
            name: 'total.icmstot.vnf',
            control: control,
            label: 'Valor total da NF-e',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
            decimalScale: 2,
        },
    ];

    const dataTransp: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'transp.modFrete',
            control: control,
            label: 'Modalidade do frete',
            loadingAutocomplete: false,
            setValue,
            options: modFreteEnum,
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
    ];

    const onChangeWithEntrance = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option?.id === '0') {
            setShowValueEntry(false);
            setValueDataPag('valueEntry', null);
            setValueDataPag('dateEntry', null);
        }
        if (option?.id === '1') {
            setShowValueEntry(true);
            setValueDataPag('dateEntry', momentZoneToDate(undefined));
        }
    };

    const {
        control: controlDataPag,
        setValue: setValueDataPag,
        getValues: getValuesDataPag,
        setError: setErrorDataPag,
        clearErrors: clearErrorsDataPag,
    } = useForm<GeradorParcelas>({
        defaultValues: {},
    });

    const handlePlotGenerator = () => {
        const plots: DetPagamento[] = [];

        const data: GeradorParcelas = {
            indPag: getValuesDataPag('indPag'),
            tpag: getValuesDataPag('tpag'),
            numberInstallments: Number(getValuesDataPag('numberInstallments')),
            withEntrance: getValuesDataPag('withEntrance'),
            valueEntry: getValuesDataPag('valueEntry'),
            dateEntry: getValuesDataPag('dateEntry'),
            dueDate: Number(getValuesDataPag('dueDate')),
            vnf: floatValue(getValues('total.icmstot.vnf')),
        };
        let amountInstallments = data?.vnf;

        let error = false;
        if (!data.indPag?.id) {
            setErrorDataPag('indPag', {
                type: `custom_indPag`,
                message: 'O campo é obrigatório',
            });
            error = true;
        }

        if (!data.tpag?.id) {
            setErrorDataPag('tpag', {
                type: `custom_tpag`,
                message: 'O campo é obrigatório',
            });
            error = true;
        }

        if (data?.indPag?.id === '1') {
            if (!data?.numberInstallments) {
                setErrorDataPag('numberInstallments', {
                    type: `custom_numberInstallments`,
                    message: 'O campo é obrigatório',
                });
                error = true;
            }
            if (!data?.dueDate) {
                setErrorDataPag('dueDate', {
                    type: `custom_dueDate`,
                    message: 'O campo é obrigatório',
                });
                error = true;
            }
            if (!data?.withEntrance) {
                setErrorDataPag('withEntrance', {
                    type: `custom_withEntrance`,
                    message: 'O campo é obrigatório',
                });
                error = true;
            }
        }

        if (data?.indPag?.id === '1' && data?.withEntrance?.id === '1') {
            if (!data?.valueEntry) {
                setErrorDataPag('valueEntry', {
                    type: `custom_valueEntry`,
                    message: 'O campo é obrigatório',
                });
                error = true;
            }
        }

        if (error) {
            return;
        }

        if (
            data.indPag.id === '1' &&
            data.withEntrance?.id === '1' &&
            data?.dateEntry
        ) {
            data.valueEntry = floatValue(data.valueEntry);
            plots.push({
                indPag: data.indPag,
                tpag: data.tpag,
                vpag: data.valueEntry || 0,
                description: 'Entrada',
                dueDate: data.dateEntry,
            });
            amountInstallments = amountInstallments - Number(data.valueEntry);

            const valuePlot =
                amountInstallments / Number(data.numberInstallments);
            for (
                let index = 0;
                index < Number(data.numberInstallments);
                index++
            ) {
                const datePlot = moment()
                    .add(index + 1, 'months')
                    .set({ date: data.dueDate })
                    .toDate();
                plots.push({
                    indPag: data.indPag,
                    tpag: data.tpag,
                    vpag: roundNumber(valuePlot),
                    description: `Parcela ${index + 1}`,
                    dueDate: datePlot,
                });
            }
        }

        if (data.indPag.id === '0') {
            data.numberInstallments = 1;
            data.withEntrance = yesOrNoInstance.getObject('1');
            data.valueEntry = 0;
            data.dateEntry = null;
            data.dueDate = Number(moment().format('DD'));
            const datePlot = momentZoneToDate(undefined);
            plots.push({
                indPag: data.indPag,
                tpag: data.tpag,
                vpag: amountInstallments,
                description: `Parcela 1`,
                dueDate: datePlot,
            });
        }

        let totalSale = plots.reduce((accumulator, item) => {
            return accumulator + Number(item.vpag);
        }, 0);

        if (totalSale > data.vnf || totalSale < data.vnf) {
            const difference = totalSale - data.vnf;

            const lastValue = roundNumber(
                Number(plots[plots.length - 1].vpag) - difference,
            );
            plots[plots.length - 1].vpag = lastValue;

            totalSale = plots.reduce((accumulator, item) => {
                return accumulator + Number(item.vpag);
            }, 0);
        }

        clearErrorsDataPag('indPag');
        clearErrorsDataPag('tpag');
        clearErrorsDataPag('dueDate');
        clearErrorsDataPag('valueEntry');
        clearErrorsDataPag('numberInstallments');
        clearErrorsDataPag('withEntrance');

        setValue('pag.detPagamento', plots);
        plots.splice(0, plots.length);
    };

    const dataPag: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'indPag',
            control: controlDataPag,
            label: 'Forma de pagamento',
            loadingAutocomplete: false,
            setValue: setValueDataPag,
            options: indPagEnum,
            md: 4,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'tpag',
            control: controlDataPag,
            label: 'Tipo de pagamento',
            loadingAutocomplete: false,
            setValue: setValueDataPag,
            options: tPagEnum,
            md: 4,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'withEntrance',
            control: controlDataPag,
            label: 'Com entrada',
            loadingAutocomplete: false,
            setValue: setValueDataPag,
            handleChange: onChangeWithEntrance,
            options: yesOrNoEnum,
            md: 4,
            xs: 12,
        },
        {
            typeInput: 'number',
            name: 'valueEntry',
            control: controlDataPag,
            label: 'Valor entrada',
            md: 4,
            xs: 12,
            decimalScale: 2,
            show: showValueEntry,
        },
        {
            typeInput: 'date',
            name: 'dateEntry',
            control: controlDataPag,
            label: 'Data entrada',
            setValue: setValueDataPag,
            md: 4,
            xs: 12,
            show: showValueEntry,
        },
        {
            typeInput: 'number',
            name: 'numberInstallments',
            control: controlDataPag,
            label: 'Quantidade de parcelas',
            md: 4,
            xs: 12,
            rules: {
                required: true,
            },
            show: true,
        },
        {
            typeInput: 'number',
            name: 'dueDate',
            control: controlDataPag,
            label: 'Dia vencimento',
            md: 4,
            xs: 12,
            decimalScale: 0,
            show: true,
        },
    ];

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDownloadReport}
                open={openModalDownloadReport.open}
                messageDialogTitle={messageDialogTitle}
            />
            <AlertDialog
                handleConfirmation={handleConfirmeDownloadReportByType}
                open={openModalDownloadReportByType.open}
                messageDialogTitle={messageDialogTitle}
            />
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Grid sx={{ pt: 0, pb: 0, pl: 1 }} container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                            }}>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                {`Código: ${getValues('code')}`}
                            </Typography>
                            {getValues('numberNfe') && (
                                <Typography
                                    sx={{
                                        fontSize: '1rem',
                                        color: '#2e7d32',
                                    }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div">
                                    {`Status: Emitida`}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Tabs
                        value={valueTab}
                        onChange={handleChange}
                        aria-label="basic tabs example">
                        <Tab label="Dados NF-e" {...a11yProps(0)} />
                        <Tab label="Emitente" {...a11yProps(1)} />
                        <Tab label="Destinatário" {...a11yProps(2)} />
                        <Tab label="Produtos" {...a11yProps(3)} />
                        <Tab label="Totais" {...a11yProps(4)} />
                        <Tab label="Transporte" {...a11yProps(5)} />
                        <Tab label="Cobrança" {...a11yProps(6)} />
                    </Tabs>
                </Box>
                <form
                    onSubmit={handleSubmit((data, event) =>
                        submit(data, event),
                    )}>
                    <TabPanel value={valueTab} index={0}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {dataNFe.map((field, index) => {
                                if (
                                    field.typeInput === 'number' &&
                                    field.decimalScale
                                ) {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputNumber
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageError}
                                                decimalScale={
                                                    field.decimalScale
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                key={index}
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                control={field.control}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'date') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputDate
                                                name={field.name}
                                                control={field.control}
                                                rules={field.rules}
                                                label={field.label}
                                                setValue={field.setValue}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    );
                                }
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormInputText
                                            readOnly={field?.readOnly}
                                            name={field.name}
                                            rules={field.rules}
                                            control={field.control}
                                            label={field.label}
                                            messageError={messageError}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {dataEmit.map((field, index) => {
                                if (
                                    field.typeInput === 'number' &&
                                    field.decimalScale
                                ) {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputNumber
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageError}
                                                decimalScale={
                                                    field.decimalScale
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                key={index}
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                control={field.control}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'date') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputDate
                                                name={field.name}
                                                control={field.control}
                                                rules={field.rules}
                                                label={field.label}
                                                setValue={field.setValue}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    );
                                }
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormInputText
                                            readOnly={field?.readOnly}
                                            name={field.name}
                                            rules={field.rules}
                                            control={field.control}
                                            label={field.label}
                                            messageError={messageError}
                                            mask={field?.mask}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </TabPanel>
                    <TabPanel value={valueTab} index={2}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {dataDest.map((field, index) => {
                                if (
                                    field.typeInput === 'number' &&
                                    field.decimalScale
                                ) {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputNumber
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageError}
                                                decimalScale={
                                                    field.decimalScale
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                key={index}
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                control={field.control}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'date') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputDate
                                                name={field.name}
                                                control={field.control}
                                                rules={field.rules}
                                                label={field.label}
                                                setValue={field.setValue}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    );
                                }
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormInputText
                                            readOnly={field?.readOnly}
                                            name={field.name}
                                            rules={field.rules}
                                            control={field.control}
                                            label={field.label}
                                            messageError={messageError}
                                            mask={field?.mask}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </TabPanel>
                    <TabPanel value={valueTab} index={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TableContainer component={'div'}>
                                    <Table
                                        sx={{ minWidth: 650 }}
                                        size="small"
                                        aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                {headCells.map(headCell => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        align={
                                                            headCell.numeric
                                                                ? 'right'
                                                                : 'left'
                                                        }
                                                        padding={
                                                            headCell.disablePadding
                                                                ? 'none'
                                                                : 'normal'
                                                        }>
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                                <TableCell align="center" />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {fields.map((item, index) => (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            { border: 0 },
                                                    }}>
                                                    {bodyCells.map(
                                                        (
                                                            col,
                                                            indexBodyCell,
                                                        ) => (
                                                            <TableCell
                                                                key={
                                                                    indexBodyCell
                                                                }
                                                                component={
                                                                    col.component
                                                                }
                                                                scope={
                                                                    col.scope
                                                                }
                                                                onClick={event =>
                                                                    handleEditItemTable(
                                                                        index,
                                                                    )
                                                                }>
                                                                {col.replace
                                                                    ? col.replace(
                                                                          item,
                                                                          col.keyName,
                                                                      )
                                                                    : item[
                                                                          col
                                                                              .keyName
                                                                      ]}
                                                            </TableCell>
                                                        ),
                                                    )}
                                                    <TableCell align="right">
                                                        <IconButton
                                                            onClick={() =>
                                                                handleEditItemTable(
                                                                    index,
                                                                )
                                                            }>
                                                            <ModeEdit />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={valueTab} index={4}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {dataTotal.map((field, index) => {
                                if (
                                    field.typeInput === 'number' &&
                                    field.decimalScale
                                ) {
                                    return (
                                        <Tooltip title={field.label} arrow>
                                            <Grid
                                                key={index}
                                                item
                                                md={field.md}
                                                xs={field.xs}>
                                                <FormInputNumber
                                                    name={field.name}
                                                    readOnly={field?.readOnly}
                                                    rules={field.rules}
                                                    control={field.control}
                                                    label={field.label}
                                                    messageError={messageError}
                                                    decimalScale={
                                                        field.decimalScale
                                                    }
                                                />
                                            </Grid>
                                        </Tooltip>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                key={index}
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                control={field.control}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'date') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputDate
                                                name={field.name}
                                                control={field.control}
                                                rules={field.rules}
                                                label={field.label}
                                                setValue={field.setValue}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    );
                                }
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormInputText
                                            readOnly={field?.readOnly}
                                            name={field.name}
                                            rules={field.rules}
                                            control={field.control}
                                            label={field.label}
                                            messageError={messageError}
                                            mask={field?.mask}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </TabPanel>
                    <TabPanel value={valueTab} index={5}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {dataTransp.map((field, index) => {
                                if (
                                    field.typeInput === 'number' &&
                                    field.decimalScale
                                ) {
                                    return (
                                        <Tooltip title={field.label} arrow>
                                            <Grid
                                                key={index}
                                                item
                                                md={field.md}
                                                xs={field.xs}>
                                                <FormInputNumber
                                                    name={field.name}
                                                    readOnly={field?.readOnly}
                                                    rules={field.rules}
                                                    control={field.control}
                                                    label={field.label}
                                                    messageError={messageError}
                                                    decimalScale={
                                                        field.decimalScale
                                                    }
                                                />
                                            </Grid>
                                        </Tooltip>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                key={index}
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                control={field.control}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'date') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputDate
                                                name={field.name}
                                                control={field.control}
                                                rules={field.rules}
                                                label={field.label}
                                                setValue={field.setValue}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    );
                                }
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormInputText
                                            readOnly={field?.readOnly}
                                            name={field.name}
                                            rules={field.rules}
                                            control={field.control}
                                            label={field.label}
                                            messageError={messageError}
                                            mask={field?.mask}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </TabPanel>
                    <TabPanel value={valueTab} index={6}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {dataPag.map((field, index) => {
                                if (field.typeInput === 'number') {
                                    return (
                                        <Tooltip title={field.label} arrow>
                                            <Grid
                                                key={index}
                                                item
                                                display={
                                                    field?.show === false
                                                        ? 'none'
                                                        : undefined
                                                }
                                                md={field.md}
                                                xs={field.xs}>
                                                <FormInputNumber
                                                    name={field.name}
                                                    readOnly={field?.readOnly}
                                                    rules={field.rules}
                                                    control={field.control}
                                                    label={field.label}
                                                    messageError={messageError}
                                                    decimalScale={
                                                        field?.decimalScale || 0
                                                    }
                                                />
                                            </Grid>
                                        </Tooltip>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                name={field.name}
                                                readOnly={field?.readOnly}
                                                control={field.control}
                                                rules={field.rules}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'date') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            display={
                                                field?.show === false
                                                    ? 'none'
                                                    : undefined
                                            }
                                            xs={field.xs}>
                                            <FormInputDateWithoutHour
                                                name={field.name}
                                                control={field.control}
                                                rules={field.rules}
                                                label={field.label}
                                                setValue={field.setValue}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'text') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputText
                                                readOnly={field?.readOnly}
                                                name={field.name}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageError}
                                                mask={field?.mask}
                                            />
                                        </Grid>
                                    );
                                }
                                return <></>;
                            })}
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        flex: '1 1 100%',
                                        fontSize: '16px',
                                        pb: 1,
                                    }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div">
                                    <FormButton
                                        label={'Gerar parcelas'}
                                        typeButton={'toLoad'}
                                        onClick={() => handlePlotGenerator()}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableGeneric
                                    data={data}
                                    columns={columnsDetPag}
                                    minWidth={650}
                                />
                            </Grid>
                        </Grid>
                        <Typography
                            id="total"
                            sx={{ textAlign: 'right', mt: 1, mb: 0, pb: 0 }}
                            component="div">
                            {`Total (R$): ${formatNumber(total)}`}
                            <br />
                            {`Total de parcelas geradas (R$): ${formatNumber(
                                totalDetPag,
                            )}`}
                        </Typography>
                    </TabPanel>
                    <br />
                    <div className="info-footer">
                        <div className="footer-buttons">
                            <FormButton
                                label={'Emitir'}
                                typeButton={'submit'}
                            />
                            {params.id && (
                                <FormButton
                                    label={'Excluir'}
                                    typeButton={'delete'}
                                    onClick={() => setOpenModalDelete(true)}
                                />
                            )}
                            <FormButton
                                label={'Voltar'}
                                typeButton={'cancel'}
                                onClick={() => handleCancel()}
                            />
                        </div>
                        {getValues('numberNfe') && (
                            <div className="footer-buttons">
                                <FormButton
                                    label={'Baixar XML'}
                                    typeButton={'custom'}
                                    icon={<CloudDownloadIcon />}
                                    variant={'outlined'}
                                    onClick={() => handleDownloadNfe('xml')}
                                />
                                <FormButton
                                    label={'Baixar PDF'}
                                    typeButton={'custom'}
                                    icon={<CloudDownloadIcon />}
                                    variant={'outlined'}
                                    onClick={() => handleDownloadNfe('pdf')}
                                />
                            </div>
                        )}
                    </div>
                </form>
                <ModalItem
                    open={openModalItemProduct}
                    setOpen={setOpenModalItemProduct}
                    index={indexItem}
                    setIndex={setIndexItem}
                    getValues={getValues}
                    append={append}
                    update={update}
                    fields={fields}
                    width={width}
                />
                <ModalItemDetPag
                    open={openModalDetPag}
                    setOpen={setOpenModalDetPag}
                    index={indexItemDetPag}
                    setIndex={setIndexItemDetPag}
                    getValues={getValues}
                    append={appendDetPag}
                    update={updateDetPag}
                    fields={fieldsDetPag}
                    width={width}
                />
            </Paper>
        </div>
    );
};

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
    overflowY: 'scroll',
    display: 'block',
    height: '90%',
};

function ModalItem({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    fields,
    width,
}: ModalProps) {
    const [expanded, setExpaded] = useState<Expanded>({
        infoProductNfe: true,
        imposto: true,
        icmssn102: true,
        ipi: true,
        pis: true,
        cofins: true,
    });

    const { addToast } = useToast();

    const defaultValuesItems = {
        valueOutput: undefined,
        quantity: undefined,
        warrantyTime: '',
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const extraValidationOutputNumber = (valueInput: string | number) => {
        if (typeof valueInput === 'number') {
            if (valueInput <= 0.0) {
                return false;
            }
        }
        if (typeof valueInput === 'string') {
            valueInput = valueInput.replace(',', '.');
            valueInput = Number(valueInput);
            if (valueInput <= 0.0) {
                return false;
            }
        }
        return true;
    };

    const rulesItems = {
        valueOutput: {
            required: true,
            validate: extraValidationOutputNumber,
        },
        quantity: {
            required: true,
            min: 1,
        },
        warrantyTime: {
            required: true,
        },
        product: {
            required: true,
        },
    };

    const messageErrorItems = (errors: any, field: any) => {
        // valueOutput
        if (errors && errors.type === 'required' && field === 'valueOutput') {
            return 'O campo valor de saída é obrigátorio.';
        }
        if (errors && errors.type === 'validate' && field === 'valueOutput') {
            return 'O campo valor de saída tem que ser maior que 0,00.';
        }

        // quantity
        if (errors && errors.type === 'required' && field === 'quantity') {
            return 'O campo quantidade é obrigátorio.';
        }
        if (errors && errors.type === 'min' && field === 'quantity') {
            return 'O campo quantidade tem que ser maior que 0.';
        }

        // warrantyTime
        if (
            errors &&
            errors.type === 'required' &&
            field === 'warrantyTimeOption'
        ) {
            return 'O campo garantia é obrigátorio.';
        }

        // product
        if (errors && errors.type === 'required' && field === 'product') {
            return 'O campo produto é obrigátorio.';
        }
        return '';
    };

    const useFormItems = useForm<IItemProduct>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    const inputs: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'prod.cprod',
            control: controlItems,
            label: 'Código',
            md: 3,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'text',
            name: 'prod.xprod',
            control: controlItems,
            label: 'Produto',
            md: 9,
            xs: 12,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'number',
            name: 'prod.vunCom',
            control: controlItems,
            label: 'Valor unitário',
            md: 6,
            xs: 12,
            decimalScale: 2,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'number',
            name: 'prod.qcom',
            control: controlItems,
            label: 'Quantidade',
            md: 6,
            xs: 12,
            decimalScale: 0,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'number',
            name: 'prod.vunTrib',
            control: controlItems,
            label: 'Valor unitário de tributação',
            md: 4,
            xs: 12,
            decimalScale: 2,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'number',
            name: 'prod.qtrib',
            control: controlItems,
            label: 'Quantidade tributável',
            md: 4,
            xs: 12,
            decimalScale: 0,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'number',
            name: 'prod.vprod',
            control: controlItems,
            label: 'Sub total (vProd)',
            md: 4,
            xs: 12,
            decimalScale: 2,
            rules: {
                required: true,
            },
        },
        {
            typeInput: 'autocomplete',
            name: 'prod.indTot',
            control: controlItems,
            label: 'Configuração valor total',
            loadingAutocomplete: false,
            setValue: setValueItems,
            options: indOptions,
            md: 6,
            xs: 12,
        },
    ];

    const infoProductForNfe: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'prod.cean',
            control: controlItems,
            label: 'CEAN / GTIN',
            md: 4,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'prod.cfop',
            control: controlItems,
            label: 'CFOP',
            md: 4,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'prod.ncm',
            control: controlItems,
            label: 'NCM',
            md: 4,
            xs: 12,
        },
    ];

    const icmssn102: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'imposto.icmssn102.orig',
            control: controlItems,
            label: 'Origem da mercadoria',
            loadingAutocomplete: false,
            setValue: setValueItems,
            options: optionsList(),
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'imposto.icmssn102.csosn',
            control: controlItems,
            label: 'CSOSN',
            md: 6,
            xs: 12,
        },
    ];

    const ipi: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'imposto.ipi.cenq',
            control: controlItems,
            label: 'Código de Enquadramento Legal do IPI',
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'imposto.ipi.ipint.cst', // Confirmar caminho
            control: controlItems,
            label: 'CST IPINT',
            loadingAutocomplete: false,
            setValue: setValueItems,
            options: optionsCstIpint(),
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'imposto.ipi.ipiTrib.cst', // Confirmar caminho
            control: controlItems,
            label: 'Código da Situação Tributária do IPI',
            loadingAutocomplete: false,
            setValue: setValueItems,
            options: optionsCstIpiTrib(),
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'imposto.ipi.ipiTrib.vbc', // Confirmar caminho
            control: controlItems,
            label: 'Valor cálculo de base',
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'impotos.ipi.ipiTrib.pipi',
            control: controlItems,
            label: 'Alíquota do IPI',
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'text',
            name: 'imposto.ipi.ipiTrib.vipi',
            control: controlItems,
            label: 'Valor do IPI',
            md: 6,
            xs: 12,
        },
    ];

    const pis: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'imposto.pis.pisnt.cst',
            control: controlItems,
            label: 'CST PISNT',
            loadingAutocomplete: false,
            setValue: setValueItems,
            options: optionsCstPisnt(),
            md: 6,
            xs: 12,
        },
    ];

    const cofins: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'imposto.cofins.cofinsnt.cst',
            control: controlItems,
            label: 'CST COFINSNT',
            loadingAutocomplete: false,
            setValue: setValueItems,
            options: optionsCstPisnt(),
            md: 6,
            xs: 12,
        },
    ];

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
    }, [index, open]);

    const setModel = (index: number) => {
        const prod = getValues(`det[${index}].prod`);
        setValueItems('prod', prod);
        setValueItems('imposto', getValues(`det[${index}].imposto`));
    };

    const handleClose = () => {
        setIndex && setIndex(-1);
        setOpen(false);
        reset(defaultValuesItems);
    };

    const handleChangeAccordionInfoProductNf =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                infoProductNfe: isExpanded,
            }));
        };

    const handleChangeAccordionImposto =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                imposto: isExpanded,
            }));
        };

    const handleChangeAccordionIcmssn102 =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                icmssn102: isExpanded,
            }));
        };

    const handleChangeAccordionIpi =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                ipi: isExpanded,
            }));
        };

    const handleChangeAccordionPis =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                pis: isExpanded,
            }));
        };

    const handleChangeAccordionCofins =
        () => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpaded(prevState => ({
                ...prevState,
                cofins: isExpanded,
            }));
        };

    const submitItem = (data: IItemProduct) => {
        data.valueOutput = floatValue(data.valueOutput);

        delete data.warrantyTimeOption;

        // const repeatItem = fields
        //     ? fields.filter(
        //           (f, i) => index != i && f.product.id == data.product.id,
        //       )
        //     : [];

        // if (repeatItem.length > 0) {
        //     addToast({
        //         type: 'warn',
        //         title: 'Item repedito',
        //         description: 'O item já foi informado.',
        //     });
        //     return;
        // }

        // if (data.product.quantity < Number(data.quantity)) {
        //     addToast({
        //         type: 'warn',
        //         title: 'Sem estoque',
        //         description: `Tem apenas ${data.product.quantity} unidade(s)`,
        //     });
        //     return;
        // }

        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <div className="header-modal">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            Alterar item
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {inputs.map((field, index) => {
                                if (
                                    field.typeInput === 'number' &&
                                    field.decimalScale
                                ) {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputNumber
                                                name={field.name}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageErrorItems}
                                                decimalScale={
                                                    field.decimalScale
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                key={index}
                                                name={field.name}
                                                control={field.control}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormInputText
                                            name={field.name}
                                            rules={field.rules}
                                            control={field.control}
                                            label={field.label}
                                            messageError={messageErrorItems}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion
                                expanded={expanded.infoProductNfe}
                                onChange={handleChangeAccordionInfoProductNf()}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>
                                        Informações do produto para a NF
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {infoProductForNfe.map(
                                            (input, index) => {
                                                if (
                                                    input.typeInput === 'text'
                                                ) {
                                                    return (
                                                        <Grid
                                                            key={index}
                                                            item
                                                            md={input.md}
                                                            xs={input.xs}>
                                                            <FormInputText
                                                                key={index}
                                                                name={
                                                                    input.name
                                                                }
                                                                control={
                                                                    input.control
                                                                }
                                                                label={
                                                                    input.label
                                                                }
                                                                handleOnKeyPress={
                                                                    input.handleOnKeyPress
                                                                }
                                                                variant={
                                                                    input?.variant
                                                                }
                                                                mask={
                                                                    input.mask
                                                                }
                                                            />
                                                        </Grid>
                                                    );
                                                }
                                                if (
                                                    input.typeInput ===
                                                    'autocomplete'
                                                ) {
                                                    return (
                                                        <FormAutocompleteInitialized
                                                            key={index}
                                                            name={input.name}
                                                            control={
                                                                input.control
                                                            }
                                                            label={input.label}
                                                            loading={
                                                                input.loadingAutocomplete ||
                                                                false
                                                            }
                                                            options={
                                                                input.options ||
                                                                []
                                                            }
                                                            setValue={
                                                                input.setValue
                                                            }
                                                            handleChange={
                                                                input.handleChange
                                                            }
                                                            variant={
                                                                input.variant
                                                            }
                                                            handleOnKeyPress={
                                                                input.handleOnKeyPress
                                                            }
                                                        />
                                                    );
                                                }
                                                return <></>;
                                            },
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid sx={{ pt: 2 }} item xs={12}>
                            <Accordion
                                expanded={expanded.imposto}
                                onChange={handleChangeAccordionImposto()}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Impostos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item xs={12}>
                                        <Accordion
                                            expanded={expanded.icmssn102}
                                            onChange={handleChangeAccordionIcmssn102()}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>
                                                    ICMSSN102
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {icmssn102.map(
                                                        (input, index) => {
                                                            if (
                                                                input.typeInput ===
                                                                'text'
                                                            ) {
                                                                return (
                                                                    <Grid
                                                                        key={
                                                                            index
                                                                        }
                                                                        item
                                                                        md={
                                                                            input.md
                                                                        }
                                                                        xs={
                                                                            input.xs
                                                                        }>
                                                                        <FormInputText
                                                                            key={
                                                                                index
                                                                            }
                                                                            name={
                                                                                input.name
                                                                            }
                                                                            control={
                                                                                input.control
                                                                            }
                                                                            label={
                                                                                input.label
                                                                            }
                                                                            handleOnKeyPress={
                                                                                input.handleOnKeyPress
                                                                            }
                                                                            variant={
                                                                                input?.variant
                                                                            }
                                                                            mask={
                                                                                input.mask
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                );
                                                            }
                                                            if (
                                                                input.typeInput ===
                                                                'autocomplete'
                                                            ) {
                                                                return (
                                                                    <Grid
                                                                        key={
                                                                            index
                                                                        }
                                                                        item
                                                                        md={
                                                                            input.md
                                                                        }
                                                                        xs={
                                                                            input.xs
                                                                        }>
                                                                        <FormAutocompleteInitialized
                                                                            key={
                                                                                index
                                                                            }
                                                                            name={
                                                                                input.name
                                                                            }
                                                                            control={
                                                                                input.control
                                                                            }
                                                                            label={
                                                                                input.label
                                                                            }
                                                                            loading={
                                                                                input.loadingAutocomplete ||
                                                                                false
                                                                            }
                                                                            options={
                                                                                input.options ||
                                                                                []
                                                                            }
                                                                            setValue={
                                                                                input.setValue
                                                                            }
                                                                            handleChange={
                                                                                input.handleChange
                                                                            }
                                                                            variant={
                                                                                input.variant
                                                                            }
                                                                            handleOnKeyPress={
                                                                                input.handleOnKeyPress
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                );
                                                            }
                                                            return <></>;
                                                        },
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid sx={{ pt: 2 }} item xs={12}>
                                        <Accordion
                                            expanded={expanded.ipi}
                                            onChange={handleChangeAccordionIpi()}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>IPI</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {ipi.map((input, index) => {
                                                        if (
                                                            input.typeInput ==
                                                            'text'
                                                        ) {
                                                            return (
                                                                <Grid
                                                                    key={index}
                                                                    item
                                                                    md={
                                                                        input.md
                                                                    }
                                                                    xs={
                                                                        input.xs
                                                                    }>
                                                                    <FormInputText
                                                                        key={
                                                                            index
                                                                        }
                                                                        name={
                                                                            input.name
                                                                        }
                                                                        control={
                                                                            input.control
                                                                        }
                                                                        label={
                                                                            input.label
                                                                        }
                                                                        handleOnKeyPress={
                                                                            input.handleOnKeyPress
                                                                        }
                                                                        variant={
                                                                            input?.variant
                                                                        }
                                                                        mask={
                                                                            input.mask
                                                                        }
                                                                    />
                                                                </Grid>
                                                            );
                                                        }
                                                        if (
                                                            input.typeInput ===
                                                            'autocomplete'
                                                        ) {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    key={index}
                                                                    md={
                                                                        input.md
                                                                    }
                                                                    xs={
                                                                        input.xs
                                                                    }>
                                                                    <FormAutocompleteInitialized
                                                                        key={
                                                                            index
                                                                        }
                                                                        name={
                                                                            input.name
                                                                        }
                                                                        control={
                                                                            input.control
                                                                        }
                                                                        label={
                                                                            input.label
                                                                        }
                                                                        loading={
                                                                            input.loadingAutocomplete ||
                                                                            false
                                                                        }
                                                                        options={
                                                                            input.options ||
                                                                            []
                                                                        }
                                                                        setValue={
                                                                            input.setValue
                                                                        }
                                                                        handleChange={
                                                                            input.handleChange
                                                                        }
                                                                        variant={
                                                                            input.variant
                                                                        }
                                                                        handleOnKeyPress={
                                                                            input.handleOnKeyPress
                                                                        }
                                                                    />
                                                                </Grid>
                                                            );
                                                        }
                                                        return <></>;
                                                    })}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid sx={{ pt: 2 }} item xs={12}>
                                        <Accordion
                                            expanded={expanded.pis}
                                            onChange={handleChangeAccordionPis()}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>PIS</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {pis.map((input, index) => {
                                                        if (
                                                            input.typeInput ===
                                                            'autocomplete'
                                                        ) {
                                                            return (
                                                                <Grid
                                                                    key={index}
                                                                    item
                                                                    md={
                                                                        input.md
                                                                    }
                                                                    xs={
                                                                        input.xs
                                                                    }>
                                                                    <FormAutocompleteInitialized
                                                                        name={
                                                                            input.name
                                                                        }
                                                                        control={
                                                                            input.control
                                                                        }
                                                                        label={
                                                                            input.label
                                                                        }
                                                                        loading={
                                                                            input.loadingAutocomplete ||
                                                                            false
                                                                        }
                                                                        options={
                                                                            input.options ||
                                                                            []
                                                                        }
                                                                        setValue={
                                                                            input.setValue
                                                                        }
                                                                        handleChange={
                                                                            input.handleChange
                                                                        }
                                                                        variant={
                                                                            input.variant
                                                                        }
                                                                        handleOnKeyPress={
                                                                            input.handleOnKeyPress
                                                                        }
                                                                    />
                                                                </Grid>
                                                            );
                                                        }
                                                        return <></>;
                                                    })}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid sx={{ pt: 2 }} item xs={12}>
                                        <Accordion
                                            expanded={expanded.cofins}
                                            onChange={handleChangeAccordionCofins()}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>COFINS</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {cofins.map(
                                                        (input, index) => {
                                                            if (
                                                                input.typeInput ===
                                                                'autocomplete'
                                                            ) {
                                                                return (
                                                                    <Grid
                                                                        key={
                                                                            index
                                                                        }
                                                                        item
                                                                        md={
                                                                            input.md
                                                                        }
                                                                        xs={
                                                                            input.xs
                                                                        }>
                                                                        <FormAutocompleteInitialized
                                                                            name={
                                                                                input.name
                                                                            }
                                                                            control={
                                                                                input.control
                                                                            }
                                                                            label={
                                                                                input.label
                                                                            }
                                                                            loading={
                                                                                input.loadingAutocomplete ||
                                                                                false
                                                                            }
                                                                            options={
                                                                                input.options ||
                                                                                []
                                                                            }
                                                                            setValue={
                                                                                input.setValue
                                                                            }
                                                                            handleChange={
                                                                                input.handleChange
                                                                            }
                                                                            variant={
                                                                                input.variant
                                                                            }
                                                                            handleOnKeyPress={
                                                                                input.handleOnKeyPress
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                );
                                                            }
                                                            return <></>;
                                                        },
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <span />
                        <Stack sx={{ pt: 2 }} spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

function ModalItemDetPag({
    open,
    setOpen,
    index,
    setIndex,
    getValues,
    update,
    append,
    fields,
    width,
}: ModalProps) {
    const { addToast } = useToast();

    const defaultValuesItems = {};

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const extraValidationOutputNumber = (valueInput: string | number) => {
        if (typeof valueInput === 'number') {
            if (valueInput <= 0.0) {
                return false;
            }
        }
        if (typeof valueInput === 'string') {
            valueInput = valueInput.replace(',', '.');
            valueInput = Number(valueInput);
            if (valueInput <= 0.0) {
                return false;
            }
        }
        return true;
    };

    const rulesItems = {
        valueOutput: {
            required: true,
            validate: extraValidationOutputNumber,
        },
        quantity: {
            required: true,
            min: 1,
        },
        warrantyTime: {
            required: true,
        },
        product: {
            required: true,
        },
    };

    const messageErrorItems = (errors: any, field: any) => {
        // valueOutput
        if (errors && errors.type === 'required' && field === 'valueOutput') {
            return 'O campo valor de saída é obrigátorio.';
        }
        if (errors && errors.type === 'validate' && field === 'valueOutput') {
            return 'O campo valor de saída tem que ser maior que 0,00.';
        }

        // quantity
        if (errors && errors.type === 'required' && field === 'quantity') {
            return 'O campo quantidade é obrigátorio.';
        }
        if (errors && errors.type === 'min' && field === 'quantity') {
            return 'O campo quantidade tem que ser maior que 0.';
        }

        // warrantyTime
        if (
            errors &&
            errors.type === 'required' &&
            field === 'warrantyTimeOption'
        ) {
            return 'O campo garantia é obrigátorio.';
        }

        // product
        if (errors && errors.type === 'required' && field === 'product') {
            return 'O campo produto é obrigátorio.';
        }
        return '';
    };

    const useFormItems = useForm<DetPagamento>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    const inputs: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'description',
            control: controlItems,
            label: 'Descrição',
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'tpag',
            control: controlItems,
            label: 'Tipo de pagamento',
            loadingAutocomplete: false,
            setValue: setValueItems,
            options: tPagEnum,
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'date',
            name: 'dueDate',
            control: controlItems,
            label: 'Data de vencimento',
            setValue: setValueItems,
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'number',
            name: 'vpag',
            control: controlItems,
            label: 'Valor',
            md: 6,
            xs: 12,
            decimalScale: 2,
            rules: {
                required: true,
            },
        },
    ];

    useEffect(() => {
        if (index >= 0) {
            setModel(index);
        }
    }, [index, open]);

    const setModel = (index: number) => {
        const detPag = getValues(`pag.detPagamento[${index}]`);
        setValueItems('description', detPag.description);
        setValueItems('dueDate', detPag.dueDate);
        setValueItems('tpag', detPag.tpag);
        setValueItems('vpag', detPag.vpag);
    };

    const handleClose = () => {
        setIndex && setIndex(-1);
        setOpen(false);
        reset(defaultValuesItems);
    };

    const submitItem = (data: DetPagamento) => {
        data.vpag = floatValue(data.vpag);

        if (index >= 0) {
            update(index, {
                ...data,
            });
        } else {
            append(data);
        }
        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <div className="header-modal">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            Alterar item
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {inputs.map((field, index) => {
                                if (
                                    field.typeInput === 'number' &&
                                    field.decimalScale
                                ) {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputNumber
                                                name={field.name}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageErrorItems}
                                                decimalScale={
                                                    field.decimalScale
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                key={index}
                                                name={field.name}
                                                control={field.control}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'date') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputDateWithoutHour
                                                name={field.name}
                                                control={controlItems}
                                                rules={field.rules}
                                                label={field.label}
                                                setValue={setValueItems}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    );
                                }
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        md={field.md}
                                        xs={field.xs}>
                                        <FormInputText
                                            name={field.name}
                                            rules={field.rules}
                                            control={field.control}
                                            label={field.label}
                                            messageError={messageErrorItems}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <span />
                        <Stack sx={{ pt: 2 }} spacing={1} direction="row">
                            <FormButton
                                label={'Salvar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

function ModalPlotGenerator({
    open,
    setOpen,
    getValues,
    setValue,
    width,
    setTotal,
}: ModalPropsPlotGenerator) {
    const [showValueEntry, setShowValueEntry] = useState(false);
    const { addToast } = useToast();
    const plots: DetPagamento[] = [];

    useEffect(() => {}, []);

    const defaultValuesItems = {
        numberInstallments: getValues('numberInstallments'),
        tpag: undefined,
        vnf: getValues('total.icmstot.vnf'),
        withEntrance: yesOrNoInstance.getObject('0'),
    };

    const styleModal = {
        ...style,
    };

    if (width <= 800) {
        styleModal.width = '90%';
    }

    const messageErrorItems = (errors: any, field: any) => {
        // valueOutput
        if (errors && errors.type === 'required' && field === 'tpag') {
            return 'O campo forma de pagamento é obrigatório.';
        }
        if (
            errors &&
            errors.type === 'validate' &&
            field === 'numberInstallments'
        ) {
            return 'O campo quantidade de parcelas é obrigatório.';
        }
        return '';
    };

    const useFormItems = useForm<GeradorParcelas>({
        defaultValues: defaultValuesItems,
    });
    const controlItems = useFormItems.control;
    const setValueItems = useFormItems.setValue;
    const handleSubmit = useFormItems.handleSubmit;
    const reset = useFormItems.reset;

    const onChangeWithEntrance = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option?.id === '0') {
            setShowValueEntry(false);
            setValueItems('valueEntry', null);
            setValueItems('dateEntry', null);
        }
        if (option?.id === '1') {
            setShowValueEntry(true);
            setValueItems('dateEntry', momentZoneToDate(undefined));
        }
    };

    const inputs: FormInputProps[] = [
        {
            typeInput: 'autocomplete',
            name: 'tpag',
            control: controlItems,
            label: 'Tipo de pagamento',
            loadingAutocomplete: false,
            setValue: setValueItems,
            options: tPagEnum,
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'autocomplete',
            name: 'withEntrance',
            control: controlItems,
            label: 'Com entrada',
            loadingAutocomplete: false,
            setValue: setValueItems,
            handleChange: onChangeWithEntrance,
            options: yesOrNoEnum,
            md: 6,
            xs: 12,
        },
        {
            typeInput: 'number',
            name: 'valueEntry',
            control: controlItems,
            label: 'Valor entrada',
            md: 6,
            xs: 12,
            decimalScale: 2,
            show: showValueEntry,
        },
        {
            typeInput: 'date',
            name: 'dateEntry',
            control: controlItems,
            label: 'Data entrada',
            setValue: setValueItems,
            md: 6,
            xs: 12,
            show: showValueEntry,
        },
        {
            typeInput: 'number',
            name: 'numberInstallments',
            control: controlItems,
            label: 'Quantidade de parcelas',
            md: 6,
            xs: 12,
            rules: {
                required: true,
            },
            show: true,
        },
        {
            typeInput: 'number',
            name: 'dueDate',
            control: controlItems,
            label: 'Dia vencimento',
            md: 6,
            xs: 12,
            decimalScale: 0,
            show: true,
        },
    ];

    const handleClose = () => {
        setOpen(false);
        reset(defaultValuesItems);
    };

    const submitItem = (data: GeradorParcelas) => {
        data.vnf = floatValue(data.vnf);

        const totalNumberInstallments =
            data.withEntrance.id === '1'
                ? data.numberInstallments + 1
                : data.numberInstallments;

        const indPag = indPagInstance.getObject('1');
        let amountInstallments = data?.vnf;

        if (data.withEntrance.id === '1' && data.dateEntry) {
            data.valueEntry = floatValue(data.valueEntry);
            plots.push({
                indPag: indPag,
                tpag: data.tpag,
                vpag: data.valueEntry || 0,
                description: 'Entrada',
                dueDate: data.dateEntry,
            });
            amountInstallments = amountInstallments - Number(data.valueEntry);
        }

        const valuePlot = amountInstallments / Number(data.numberInstallments);
        for (let index = 0; index < Number(data.numberInstallments); index++) {
            const datePlot = moment()
                .add(1, 'months')
                .set({ day: data.dueDate })
                .toDate();
            plots.push({
                indPag: indPag,
                tpag: data.tpag,
                vpag: roundNumber(valuePlot),
                description: `Parcela ${index + 1}`,
                dueDate: datePlot,
            });
        }

        let totalSale = plots.reduce((accumulator, item) => {
            return accumulator + Number(item.vpag);
        }, 0);

        if (totalSale > data.vnf) {
            const difference = totalSale - data.vnf;

            const lastValue = roundNumber(
                Number(plots[plots.length - 1].vpag) - difference,
            );
            plots[plots.length - 1].vpag = lastValue;

            totalSale = plots.reduce((accumulator, item) => {
                return accumulator + Number(item.vpag);
            }, 0);
        }

        setTotal(totalSale);
        setValue('pag.detPagamento', plots);
        plots.splice(0, plots.length);

        handleClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleModal} component={'div'}>
                    <div className="header-modal">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2">
                            Gerar
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <form onSubmit={handleSubmit(data => submitItem(data))}>
                        <Grid sx={{ pt: 2, pb: 2 }} container spacing={2}>
                            {inputs.map((field, index) => {
                                if (
                                    field.typeInput === 'number' &&
                                    field.show
                                ) {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputNumber
                                                name={field.name}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageErrorItems}
                                                decimalScale={
                                                    field?.decimalScale || 0
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'autocomplete') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormAutocompleteInitialized
                                                name={field.name}
                                                control={field.control}
                                                label={field.label}
                                                loading={
                                                    field.loadingAutocomplete ||
                                                    false
                                                }
                                                options={field.options || []}
                                                setValue={field.setValue}
                                                handleChange={
                                                    field.handleChange
                                                }
                                                variant={field.variant}
                                                handleOnKeyPress={
                                                    field.handleOnKeyPress
                                                }
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'date' && field.show) {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputDateWithoutHour
                                                name={field.name}
                                                control={field.control}
                                                rules={field.rules}
                                                label={field.label}
                                                setValue={field.setValue}
                                                fullWidth={true}
                                            />
                                        </Grid>
                                    );
                                }
                                if (field.typeInput === 'text') {
                                    return (
                                        <Grid
                                            key={index}
                                            item
                                            md={field.md}
                                            xs={field.xs}>
                                            <FormInputText
                                                name={field.name}
                                                rules={field.rules}
                                                control={field.control}
                                                label={field.label}
                                                messageError={messageErrorItems}
                                            />
                                        </Grid>
                                    );
                                }
                                return <div key={index}></div>;
                            })}
                        </Grid>
                        <span />
                        <Stack sx={{ pt: 2 }} spacing={1} direction="row">
                            <FormButton
                                label={'Aplicar'}
                                typeButton={'submit'}
                            />
                            <Button variant="outlined" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default Form;

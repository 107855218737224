import { Delete, ModeEdit } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
    optionsTypeNumberPhone,
    typeNumberPhoneFind,
} from '../../communs/enums/typeNumberPhone';
import { extraValidationCNPJ } from '../../communs/valitions/cnpj';
import { extraValidationCPF } from '../../communs/valitions/cpf';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormInputDate } from '../../componets/form/formInputDate';
import {
    FormInputText,
    IFormInputProps,
} from '../../componets/form/formInputText';
import { cpfAndCnpjMask } from '../../componets/form/mask/cpfAndCnpj';
import { phoneMask } from '../../componets/form/mask/phone';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import axios from 'axios';
import { findVal } from '../../util/findValueObjectByKey';
import { cepMask } from '../../componets/form/mask/cep';

interface IFormInput {
    description: string;
    city?:
        | {
              id: string;
              description: string;
          }
        | '';
    state?: {
        id: string;
        description: string;
    };
    cityIbgeId?: string;
}

interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date' | 'autocomplete';
    name: 'description' | 'city';
    setError?: any;
    clearErrors?: any;
    required: boolean;
    hidden?: boolean;
    options?: {
        name?: string;
        description?: string;
        id?: string;
        code?: string;
    }[];
    setValue?: any;
    setFocusFirstField?: (value: boolean) => void;
}

interface IInputFormSaved {
    description: string;
    city: {
        id: string;
        description: string;
    };
}

const defaultValues = {};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [optionsCity, setOptionsCity] = useState([]);
    const [optionsState, setOptionsState] = useState<
        { id: string; description: string; initials: string }[]
    >([]);

    const extraValidationCity = (value: any) => {
        if (!value) {
            return `O campo cidade é obrigatório.`;
        }
    };

    const extraValidationState = (value: any) => {
        if (!value) {
            return `O campo estado é obrigatório.`;
        }
    };

    const rules = {
        description: {
            required: true,
        },
        city: {
            validate: extraValidationCity,
        },
        state: {
            validate: extraValidationState,
        },
    };

    const { handleSubmit, control, reset, setValue, getValues } =
        useForm<IFormInput>({
            defaultValues,
        });

    const messageError = (errors: any, field: any) => {
        if (errors && errors.type === 'required' && field === 'description') {
            return 'O campo nome é obrigátorio.';
        }
        return errors?.message || '';
    };

    useEffect(() => {
        updateState();
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`district/${params.id}`)
                .then(response => {
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    setOpenLoading(false);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        } else {
            updateCity('42');
        }
    }, []);

    const setModelAddress = async (district: any) => {
        let city: any = await findCity(String(district?.cityIbgeId));

        if (city && city.UF) {
            const cities: any = await updateCity(city.UF.id);
            setOptionsCity(cities);

            setValue('state', {
                id: city.UF.id,
                description: city.UF.nome,
            });

            setValue('city', city);
        }
    };

    const setModel = (data: IFormInput) => {
        setValue('description', data.description);
        setModelAddress({ cityIbgeId: data.cityIbgeId });
    };

    const submit = async (data: any) => {
        try {
            setOpenLoading(true);
            const city = {
                ...data.city,
            };
            delete data.city;

            data = {
                ...data,
                cityIbgeId: city.id,
                cityName: `${city.description}-${city.UF.sigla}`,
            };

            if (params && params.id) {
                await api.patch(`district/${params.id}`, data);
            } else {
                await api.post('district', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.district}?`);
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`district/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.district}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const updateState = async () => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: d.nome,
                      initials: d.sigla,
                  };
              })
            : [];

        setOptionsState(data);
    };

    const updateCity = async (idState: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${idState}/municipios`,
        );

        const data = response.data
            ? response.data.map((d: any) => {
                  return {
                      id: d.id,
                      description: d.nome,
                      UF: findVal(d, 'UF'),
                  };
              })
            : [];
        setOptionsCity(data);
        return data;
    };

    const findCity = async (id: string) => {
        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${id}`,
        );

        return {
            id: response.data.id,
            description: response.data.nome,
            UF: findVal(response.data, 'UF'),
        };
    };

    const onChangeState = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            const response = await axios.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${option.id}/municipios`,
            );
            const data = response.data
                ? response.data.map((d: any) => {
                      return {
                          id: d.id,
                          description: d.nome,
                          UF: findVal(d, 'UF'),
                      };
                  })
                : [];
            const city = getValues('city');
            const filterCity = data.filter(
                (c: any) => !city || c.id === city.id,
            );
            if (filterCity.length === 0) {
                setValue('city', '');
                setValue('description', '');
            }
            setOptionsCity(data);
        }
    };

    const onChangeCity = async (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (option && option.id) {
            const city: any = await findCity(option.id);
            if (city && city.UF) {
                const state = optionsState.find(o => o.id === city.UF.id);
                setValue('state', state);
                await updateCity(String(state?.id));
                setValue('city', city);
            }
        }
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <FormInputText
                                name="description"
                                control={control}
                                rules={rules.description}
                                label="Nome"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormAutocompleteInitialized
                                name={`city`}
                                rules={rules.city}
                                control={control}
                                label="Cidade"
                                options={optionsCity}
                                loading={false}
                                setValue={setValue}
                                messageError={messageError}
                                handleChange={onChangeCity}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormAutocompleteInitialized
                                name={`state`}
                                rules={rules.state}
                                control={control}
                                label="Estado"
                                options={optionsState}
                                loading={false}
                                setValue={setValue}
                                messageError={messageError}
                                handleChange={onChangeState}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
            </Paper>
        </div>
    );
};

export default Form;

import { useState } from 'react';
import MainContainer from '../main-container';
import Form from './categoryForm';
import List from './categoryList';

const Category: React.FC = () => {
   
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const CategoryList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Category, CategoryList };

import moment from 'moment';
import api from './api';

export interface IDataReport {
    nameReport: string;
    params: any;
}

export const downloadReport = async ({ nameReport, params }: IDataReport) => {
    api.get(`reports?nameReport=${nameReport}`, {
        responseType: 'arraybuffer',
        params,
    })
        .then(response => {
            let typeArchive = 'pdf';
            if (params.type) {
                typeArchive = params.type;
            }
            const blob = new Blob([response.data], {
                type: `application/${typeArchive}`,
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${nameReport}_${moment().format(
                'YYYYMMDDHmmss',
            )}.${typeArchive}`;
            link.click();
        })
        .catch(error => {
            throw new Error('Erro ao baixar arquivo');
        });
};

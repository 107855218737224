import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import MainContainer from '../main-container';
import Form from './districtForm';
import List from './districtList';

const District: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const DistrictList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { District, DistrictList };

import { Container, Grid, Paper, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from '../../componets/dialog/alertDialog';
import { FormAutocompleteInitialized } from '../../componets/form/formAutocompleteInitialized';
import { FormButton } from '../../componets/form/formButton';
import { FormInputText } from '../../componets/form/formInputText';
import { paths } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';

interface IFormInput {
    description: string;
    brand: {
        description: string;
        id: string;
    };
}

const defaultValues = {
    description: '',
};

const rules = {
    description: {
        required: true,
    },
    brand: {
        required: true,
    },
};

const messageError = (errors: any, field: any) => {
    // description
    if (errors && errors.type === 'required' && field === 'description') {
        return 'O campo descrição é obrigátorio.';
    }
    // brand
    if (errors && errors.type === 'required' && field === 'brand') {
        return 'O campo marca é obrigátorio.';
    }
    return '';
};

const Form: React.FC = () => {
    const history = useHistory();
    const params = useParams<'id' | any>();
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [options, setOptions] = useState<
        { id: string; description: string }[]
    >([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    useEffect(() => {
        if (params && params.id) {
            setOpenLoading(true);
            api.get(`model-brand/${params.id}`)
                .then(response => {
                    updateAutocomplete(response.data.brand.description);
                    setModel(response.data);
                    setOpenLoading(false);
                })
                .catch(e => {
                    console.error(e);
                    addToast({
                        type: 'error',
                        title: message.error.selectOne,
                    });
                });
        }
    }, []);

    const { handleSubmit, control, reset, setValue } = useForm<IFormInput>({
        defaultValues,
    });

    const setModel = (data: IFormInput) => {
        setValue('description', data.description);
        setValue('brand', {
            id: data.brand.id,
            description: data.brand.description,
        });
    };

    const submit = async (data: any) => {
        try {
            setOpenLoading(true);
            if (params && params.id) {
                await api.patch(`model-brand/${params.id}`, data);
            } else {
                await api.post('model-brand', data);
            }
            reset(defaultValues);
            addToast({
                type: 'success',
                title: message.success.save,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.modelBrand}?`);
        } catch (error) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.save,
                description: messageResponse,
            });
            console.log(error);
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`model-brand/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setOpenLoading(false);
            history.push(`${paths.modelBrand}?`);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(params.id);
        } else {
            setOpenModalDelete(false);
        }
    };

    const updateAutocomplete = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(`brand?description=${description}`);
            setOptions(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const onChangeBrand = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocomplete(event.target.value);
        }
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Paper component={'div'} sx={{ pt: 2, pl: 2, pb: 2, pr: 2 }}>
                <form onSubmit={handleSubmit(data => submit(data))}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormInputText
                                name="description"
                                control={control}
                                rules={rules.description}
                                label="Descrição"
                                messageError={messageError}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormAutocompleteInitialized
                                name="brand"
                                control={control}
                                rules={rules.brand}
                                label="Marca"
                                loading={loadingAutocomplete}
                                messageError={messageError}
                                options={options}
                                setValue={setValue}
                                handleChange={onChangeBrand}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Stack spacing={1} direction="row">
                        <FormButton label={'Salvar'} typeButton={'submit'} />
                        {params.id && (
                            <FormButton
                                label={'Excluir'}
                                typeButton={'delete'}
                                onClick={() => setOpenModalDelete(true)}
                            />
                        )}
                        <FormButton
                            label={'Voltar'}
                            typeButton={'cancel'}
                            onClick={() => handleCancel()}
                        />
                    </Stack>
                </form>
            </Paper>
        </div>
    );
};

export default Form;

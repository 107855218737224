import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import MainContainer from '../main-container';
import Form from './configurationForm';
import List from './configurationList';

const Configuration: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};
const ConfigurationList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Configuration, ConfigurationList };

export const paths = {
    category: '/records/category',
    categoryRegister: '/records/category/register',
    product: '/records/product',
    productRegister: '/records/product/register',
    entryProduct: '/records/entry-product',
    entryProductRegister: '/records/entry-product/register',
    outputProduct: '/records/output-product',
    outputProductRegister: '/records/output-product/register',
    workOrder: '/records/work-order',
    workOrderRegister: '/records/work-order/register',
    provider: '/records/provider',
    providerRegister: '/records/provider/register',
    customer: '/records/customer',
    customerRegister: '/records/customer/register',
    user: '/records/user',
    userRegister: '/records/user/register',
    district: '/records/district',
    districtRegister: '/records/district/register',
    brand: '/records/brand',
    brandRegister: '/records/brand/register',
    modelBrand: '/records/model-brand',
    modelBrandRegister: '/records/model-brand/register',
    technicianService: '/records/technician-service',
    technicianServiceRegister: '/records/technician-service/register',
    configuration: '/records/configuration',
    configurationRegister: '/records/configuration/register',
    paid: '/paid',
    invoiceOutputProductRegister: '/invoice/output-product/register',
    invoiceOutputProduct: '/invoice/output-product',
    invoiceDetail: '/invoice-detail/payment',
    invoiceDetailList: '/invoice-detail/list',
    invoiceDetailRegister: '/invoice-detail/register',
    generatedInvoices: '/invoice-detail/generated-invoices',
};

export const rowsPerPageOptions = [15, 50, 100];

export const urls = {
    api: {
        local: 'http://localhost:6001',
        prod: 'https://api.gessis.com.br',
    },
    apiNfe: {
        local: 'http://localhost:8080',
        prod: 'http://localhost:8080',
    },
};

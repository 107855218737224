import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export interface IFormInputProps {
    variant?: 'outlined' | 'standard' | 'filled' | undefined;
    name: string;
    control: any;
    rules?: any;
    label: string;
    messageError?: (errors: any, field: any) => string;
    handleChange?: any;
    handleOnKeyPress?: (data: any) => void;
    handleOnBlur?: (data: any) => void;
    options: {
        description: string;
        id: string;
    }[];
    setValue: any;
    getValues: (name: string) => any | any[] | undefined;
    readOnly?: boolean;
}

export const FormAutocompleteMultiple = ({
    variant,
    name,
    control,
    rules,
    label,
    messageError,
    options,
    setValue,
    getValues,
    handleChange,
    readOnly,
}: IFormInputProps) => {
    if (!variant) {
        variant = 'outlined';
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
            }) => {
                return (
                    <Autocomplete
                        multiple
                        options={options}
                        readOnly={readOnly}
                        getOptionDisabled={(option: any) =>
                            value.find((o: any) => o.id == option.id)?.id
                                ? true
                                : false
                        }
                        onChange={(event, option) => {
                            const lastOption = option[option.length - 1];
                            const findOptions = option.find(
                                (o, index) =>
                                    index != option.length - 1 &&
                                    o.description === lastOption.description,
                            );
                            if (findOptions) {
                                option.splice(option.length - 1, 1);
                            }
                            onChange(event);
                            setValue(name, [...option]);
                            handleChange && handleChange(event, option);
                        }}
                        value={value || []}
                        getOptionLabel={option => option.description}
                        size="small"
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant={variant}
                                size="small"
                                label={label}
                                helperText={
                                    (messageError &&
                                        messageError(error, name)) ||
                                    error?.message
                                }
                                inputProps={{
                                    ...params.inputProps,
                                }}
                                error={!!error}
                            />
                        )}
                    />
                );
            }}
        />
    );
};

import MainContainer from '../main-container';
import Form from './entryProductForm';
import List from './entryProductList';

const EntryProduct: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const EntryProductList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { EntryProduct, EntryProductList };

import MainContainer from '../main-container';
import Form from './outputProductForm';
import List from './outputProductList';

const OutputProduct: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const OutputProductList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { OutputProduct, OutputProductList };

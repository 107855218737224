import MainContainer from '../main-container';
import Form from './providerForm';
import List from './providerList';

const Provider: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const ProviderList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Provider, ProviderList };

import MainContainer from '../main-container';
import List from './invoiceDetailPayment';
import InvoiceDetailListComponent from './invoiceDetailList';
import InvoiceDetailGenerateComponent from './invoiceDetailRegister';
import GeneratedInvoiceComponent from './generatedInvoice';

const InvoiceDetailPayment: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};
const InvoiceDetailList: React.FC = () => {
    return (
        <MainContainer>
            <InvoiceDetailListComponent />
        </MainContainer>
    );
};

const InvoiceDetailRegister: React.FC = () => {
    return (
        <MainContainer>
            <InvoiceDetailGenerateComponent />
        </MainContainer>
    );
};
const GeneratedInvoice: React.FC = () => {
    return (
        <MainContainer>
            <GeneratedInvoiceComponent />
        </MainContainer>
    );
};

export {
    InvoiceDetailPayment,
    InvoiceDetailList,
    InvoiceDetailRegister,
    GeneratedInvoice,
};

import { keyBy } from 'lodash';

export function queryToObj(query: string) {
    return query
        ? JSON.parse(
              '{"' + query.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
              function (key, value) {
                  return key === '' ? value : decodeURIComponent(value);
              },
          )
        : {};
}

export function objToQuery(obj: any, arrNotInclude?: string[]) {
    return Object.keys(obj)
        .reduce(function (a: string[], k) {
            if (arrNotInclude && arrNotInclude.includes(k)) {
                return a;
            }
            if (obj[k]) {
                const query: string = `${k}=${encodeURIComponent(obj[k])}`;
                a.push(query);
            }
            return a;
        }, [])
        .join('&');
}

export const LINES_PER_PAGE = 5;

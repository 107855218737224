import React, { MouseEvent, useEffect, useState } from 'react';
import { Delete, ModeEdit } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { defaultLabelDisplayedRows } from '../../communs/tablePaginationAssistant';
import AlertDialog from '../../componets/dialog/alertDialog';
import FilterSearch from '../../componets/filter/filterSearch';
import { FormButton } from '../../componets/form/formButton';
import { FormInputDateWithoutHour } from '../../componets/form/formInputDateWithoutHour';
import { IFormInputProps } from '../../componets/form/formInputText';
import { paths, rowsPerPageOptions } from '../../config';
import { useContextGlobal } from '../../context/ContextGlobal';
import { useToast } from '../../context/ToastContext';
import api from '../../services/api';
import {
    formatDate,
    momentZoneToDateAddDays,
    momentZoneToUnix,
} from '../../util/dateUtil';
import { handleExceptionMessage } from '../../util/handleExceptionAxios';
import { message } from '../../util/handleMessages';
import { objToQuery, queryToObj } from '../../util/query';
import './styles.scss';

interface Data {
    id: string;
    dateEntry: Date;
    noteCode?: string;
    noteCodeDefault: string;
    provider?: {
        id: string;
        description: string;
    };
}

interface FormInputProps extends IFormInputProps {
    typeInput: 'text' | 'date' | 'autocomplete';
    loadingAutocomplete?: boolean;
    options?: any[];
    setValue?: any;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'noteCode',
        numeric: false,
        disablePadding: false,
        label: 'Código da nota',
    },
    {
        id: 'dateEntry',
        numeric: false,
        disablePadding: false,
        label: 'Data',
    },
    {
        id: 'provider',
        numeric: false,
        disablePadding: false,
        label: 'Fornecedor',
    },
];

const defaultValues = {
    provider: {
        description: '',
    },
    product: {
        id: '',
        description: '',
    },
    entryProduct: {
        noteCode: '',
    },
    dateInitial: momentZoneToDateAddDays({ days: -90, startOfType: 'day' }),
    dateFinal: momentZoneToDateAddDays({ days: 0, endOfType: 'day' }),
    noteCodeDefault: '',
};

const ProductList: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [rows, setRows] = useState<Data[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [toggleSearch, setToggleSearch] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [deleteRegister, setDeleteRegister] = useState<boolean>(false);
    const params = useParams<{ id?: string }>();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [idDelete, setIdDelete] = useState<string>('');
    const [options, setOptions] = useState<
        { id: string; description: string }[]
    >([]);
    const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);

    const history = useHistory();
    const { search } = useLocation();
    const { setOpenLoading } = useContextGlobal();
    const { addToast } = useToast();

    const {
        control,
        setValue,
        getValues,
        setError,
        clearErrors,
        getFieldState,
    } = useForm({
        defaultValues,
    });

    const loadRows = async (queryString: string): Promise<void> => {
        setOpenLoading(true);
        try {
            const response = await api.get(`entry-product?${queryString}`);
            setRows(response.data.data);
            setTotal(response.data.count);
            setOpenLoading(false);
            if (deleteRegister) {
                setDeleteRegister(false);
            }
        } catch (error) {
            addToast({
                type: 'error',
                title: message.error.selectAll,
                description: '',
            });
            setOpenLoading(false);
        }
    };

    useEffect(() => {
        let queryT = '';
        if (search && search.length > 0 && (!params || !params.id)) {
            const { limit, pageParam } = getParams();
            setRowsPerPage(Number(limit));
            setPage(Number(pageParam - 1));
            queryT = search.replace('?', '');
            setQuery(queryT);
        } else if (rowsPerPage === 0 && (!params || !params.id)) {
            let limit = rowsPerPage === 0 ? rowsPerPageOptions[0] : rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
            };
            const queryTemp = objToQuery(queryVO);
            history.push(`?${queryTemp}`);
        }

        if (queryT.length > 0) {
            loadRows(queryT);
        }
    }, [search]);

    useEffect(() => {
        const { limit, pageParam, noteCode } = getParams();
        if (
            rowsPerPage > 0 &&
            (limit != rowsPerPage || page + 1 != pageParam)
        ) {
            let limit = rowsPerPage;
            let currentPage = page + 1;
            const queryVO = {
                limit,
                page: currentPage,
                noteCode,
            };
            const queryTemp = objToQuery(queryVO);
            history.push(`?${queryTemp}`);
        }
    }, [page, rowsPerPage]);

    useEffect(() => {
        if (deleteRegister) {
            loadRows(query);
        }
    }, [deleteRegister]);

    const handleClick = (event: MouseEvent<unknown>, id: string) => {
        handleEdit(id);
    };

    function getParams() {
        let paramsQueryString = {
            limit: 0,
            pageParam: 0,
            page: 0,
            noteCode: null,
        };
        if (search && search.length > 0) {
            paramsQueryString = queryToObj(search.replace('?', ''));
        }
        if (paramsQueryString.limit) {
            paramsQueryString.limit = Number(paramsQueryString.limit) || 0;
        }
        if (paramsQueryString.page) {
            paramsQueryString.pageParam = Number(paramsQueryString.page) || 0;
        }
        if (paramsQueryString.noteCode) {
            paramsQueryString.noteCode = paramsQueryString.noteCode || null;
        }
        return paramsQueryString;
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // HANDLER LOAD NEW QUERY FILTER
    const handleByOnKeyPressEnterNewQuery = (
        defaultFilter: boolean | undefined,
    ) => {
        if (getFieldState('dateInitial').invalid) {
            return;
        }
        if (getFieldState('dateFinal').invalid) {
            return;
        }
        const queryObj = queryToObj(query);
        const newQuery = {
            ...queryObj,
            'entryProduct.noteCode': defaultFilter
                ? getValues('noteCodeDefault')
                : getValues('entryProduct.noteCode'),
            'provider.description': getValues('provider.description'),
            'product.description': getValues('product.description'),
            dateInitial: momentZoneToUnix(getValues('dateInitial')),
            dateFinal: momentZoneToUnix(getValues('dateFinal')),
            page: 1,
        };
        setQuery(objToQuery(newQuery));
        history.push(`?${objToQuery(newQuery)}`);

        if (window.screen.width < 900) {
            setToggleSearch(false);
        }
    };

    const handleOnKeyPress = (data: any) => {
        if (data.charCode === 13) {
            handleByOnKeyPressEnterNewQuery(false);
        }
    };

    const handleChangeInput = (data: any, input: string) => {
        handleByOnKeyPressEnterNewQuery(false);
    };

    const updateAutocomplete = async (description: any) => {
        if (description && description.length > 0) {
            setLoadingAutocomplete(true);
            const response = await api.get(
                `product/detail?description=${description}`,
            );
            setOptions(response.data.data);
            setLoadingAutocomplete(false);
        }
    };

    const onChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: any,
    ) => {
        if (!option) {
            updateAutocomplete(event.target.value);
        }
    };

    const inputsFilter: FormInputProps[] = [
        {
            typeInput: 'text',
            name: 'entryProduct.noteCode',
            variant: 'standard',
            control: control,
            label: 'Código',
            handleOnKeyPress,
        },
        {
            typeInput: 'text',
            name: 'provider.description',
            variant: 'standard',
            control: control,
            label: 'Fornecedor',
            handleOnKeyPress,
        },
        {
            typeInput: 'autocomplete',
            name: 'product',
            variant: 'standard',
            control: control,
            loadingAutocomplete: loadingAutocomplete,
            setValue: setValue,
            label: 'Produto',
            options,
            handleChange: onChange,
            handleOnKeyPress,
        },
    ];

    const clearInputsFilter = () => {
        setValue('entryProduct.noteCode', '');
        setValue('provider.description', '');
        setValue('product', { id: '', description: '' });
    };

    const clearFilters = () => {
        const queryObj = queryToObj(query);
        const newQuery = {
            ...queryObj,
            'entryProduct.noteCode': null,
            'provider.description': null,
            'product.description': null,
        };
        setQuery(objToQuery(newQuery));
        history.push(`?${objToQuery(newQuery)}`);
    };

    const handleClearFilters = (): void => {
        clearInputsFilter();
        clearFilters();
    };

    const handleEdit = (id: string) => {
        history.push(`${paths.entryProductRegister}/${id}`);
    };
    const handleDelete = async (id: string) => {
        setOpenLoading(true);

        try {
            await api.delete(`entry-product/${id}`);
            addToast({
                type: 'success',
                title: message.success.delete,
                description: '',
            });
            setPage(0);
            setRowsPerPage(rowsPerPageOptions[0]);
            setDeleteRegister(true);
        } catch (error: any) {
            const messageResponse = handleExceptionMessage(error);
            setOpenLoading(false);
            addToast({
                type: 'error',
                title: message.error.delete,
                description: messageResponse,
            });
        }
    };

    const handleConfirmeDelete = async (confirm: boolean) => {
        if (confirm) {
            setOpenModalDelete(false);
            handleDelete(idDelete);
            setIdDelete('');
        } else {
            setOpenModalDelete(false);
            setIdDelete('');
        }
    };

    const emptyRows = () => {
        if (rowsPerPage > rows.length) {
            return rowsPerPage - rows.length;
        }
        return 0;
    };

    return (
        <div className="principal-container">
            <AlertDialog
                handleConfirmation={handleConfirmeDelete}
                open={openModalDelete}
            />
            <Box className={toggleSearch ? 'container-box' : ''}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <div className="header-list">
                        <div className="header-list-title">
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div">
                                Entrada de produtos
                            </Typography>
                        </div>
                        <div className="header-list-search">
                            <Stack spacing={2} direction="row" width={'100%'}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name="dateInitial"
                                            control={control}
                                            rules={null}
                                            label="Data de início"
                                            setValue={setValue}
                                            fullWidth={true}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            handleChange={(data: any) =>
                                                handleChangeInput(
                                                    data,
                                                    'dateInitial',
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormInputDateWithoutHour
                                            name="dateFinal"
                                            control={control}
                                            rules={null}
                                            label="Data de fim"
                                            setValue={setValue}
                                            fullWidth={true}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                            handleChange={(data: any) =>
                                                handleChangeInput(
                                                    data,
                                                    'dateFinal',
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Tooltip
                                    title="Opções de filtros"
                                    onClick={() => setToggleSearch(true)}>
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </div>
                    </div>
                    <Typography
                        sx={{
                            pl: { xs: 1, sm: 2 },
                            pr: { xs: 1, sm: 1 },
                        }}>
                        <FormButton
                            label={'Novo'}
                            typeButton="addRegister"
                            onClick={() =>
                                history.push(paths.entryProductRegister)
                            }
                        />
                    </Typography>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size="small">
                            <TableHead>
                                <TableRow>
                                    {headCells.map(headCell => (
                                        <TableCell
                                            key={headCell.id}
                                            align={
                                                headCell.numeric
                                                    ? 'right'
                                                    : 'left'
                                            }
                                            padding={
                                                headCell.disablePadding
                                                    ? 'none'
                                                    : 'normal'
                                            }>
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}>
                                            <TableCell
                                                scope="row"
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row.noteCode}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {formatDate(row?.dateEntry)}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                onClick={event =>
                                                    handleClick(event, row.id)
                                                }>
                                                {row.provider?.description}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    onClick={() =>
                                                        handleEdit(row.id)
                                                    }>
                                                    <ModeEdit />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        setIdDelete(row.id);
                                                        setOpenModalDelete(
                                                            true,
                                                        );
                                                    }}>
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows() > 0 && (
                                    <>
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows(),
                                            }}>
                                            <TableCell colSpan={6}>
                                                {/* 1em é o mesmo tamanho do Icon svg */}
                                                <IconButton>
                                                    <div
                                                        style={{
                                                            width: '1em',
                                                            height: '1em',
                                                        }}></div>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={total}
                        labelRowsPerPage={'Linhas por página'}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        rowsPerPage={rowsPerPage}
                        page={total > 0 ? page : 0}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>

            {toggleSearch && (
                <FilterSearch
                    inputs={inputsFilter}
                    handleClearFilters={handleClearFilters}
                    setToggleSearch={setToggleSearch}
                />
            )}
        </div>
    );
};

export default ProductList;

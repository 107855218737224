import MainContainer from '../main-container';
import Form from './invoiceOutputProductForm';
import List from './outputProductList';

const InvoiceOutputProduct: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const InvoiceOutputProductList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { InvoiceOutputProduct, InvoiceOutputProductList };

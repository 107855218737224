import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import {
    getCoreRowModel,
    useReactTable,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table';
import { Cell } from 'recharts';

interface ReactTableProps<T extends object> {
    data: T[];
    columns: ColumnDef<T>[];
    minWidth: string | number;
    sxTableContainer?: any;
}

export const TableGeneric = <T extends object>({
    data,
    columns,
    minWidth,
    sxTableContainer,
}: ReactTableProps<T>) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <TableContainer component={'div'} sx={sxTableContainer}>
            <Table
                sx={{ minWidth: minWidth }}
                aria-labelledby="tableTitle"
                size="small">
                <TableHead>
                    <TableRow component={'tr'}>
                        {table.getHeaderGroups().map(headerGroup => {
                            return headerGroup.headers.map(
                                (header: any, i: number) => {
                                    const props: any = header.getContext();
                                    props.key = i;
                                    return header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              props,
                                          );
                                },
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map((row, index) => (
                        <TableRow
                            hover
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}>
                            {row.getVisibleCells().map((cell, index) => {
                                const key = index;
                                const props: any = cell.getContext();
                                props.key = key;
                                return flexRender(
                                    cell.column.columnDef.cell,
                                    props,
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

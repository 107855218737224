import React from 'react';
import { Switch } from 'react-router-dom';
import { paths } from './config';
// import Route from './Route'
// IMPORTS ADMIN
// IMPORTS CLIENT
import { Category, CategoryList } from './pages/category/category';
import { Customer, CustomerList } from './pages/customer/customer';
import Dashboard from './pages/dashboard';
import {
    EntryProduct,
    EntryProductList,
} from './pages/entry-product/entryPoduct';
import {
    OutputProduct,
    OutputProductList,
} from './pages/output-product/outputPoduct';
import { Product, ProductList } from './pages/product/product';
import { Provider, ProviderList } from './pages/provider/provider';
import { Brand, BrandList } from './pages/brand/brand';
import { District, DistrictList } from './pages/district/district';
import {
    Configuration,
    ConfigurationList,
} from './pages/configuration/configuration';
import {
    TechnicianService,
    TechnicianServiceList,
} from './pages/technician-service/technicianService';
import { ModelBrand, ModelBrandList } from './pages/model-brand/modelBrand';
import SignIn from './pages/sign-in/sign-in';
import { User, UserList } from './pages/user/user';
import { WorkOrder, WorkOrderList } from './pages/work-order/workOrder';
import Paid from './pages/paid';
import Route from './Route';
import {
    InvoiceOutputProduct,
    InvoiceOutputProductList,
} from './pages/invoice-output-product/invoiceOutputPoduct';
import {
    InvoiceDetailPayment,
    InvoiceDetailList,
    InvoiceDetailRegister,
    GeneratedInvoice,
} from './pages/invoice-details/invoiceDetail';

const Routes: React.FC = () => (
    <Switch>
        <Route path={`/`} exact component={SignIn} />
        <Route path={`/dashboard`} isPrivate={true} component={Dashboard} />

        {/*INICIO - cadastros gerais */}
        <Route
            path={`${paths.userRegister}/:id?`}
            isPrivate={true}
            component={User}
        />
        <Route path={`${paths.user}`} isPrivate={true} component={UserList} />
        <Route
            path={`${paths.categoryRegister}/:id?`}
            isPrivate={true}
            component={Category}
        />
        <Route
            path={`${paths.category}`}
            isPrivate={true}
            component={CategoryList}
        />
        <Route
            path={`${paths.productRegister}/:id?`}
            isPrivate={true}
            component={Product}
        />
        <Route
            path={`${paths.product}`}
            isPrivate={true}
            component={ProductList}
        />
        <Route
            path={`${paths.customerRegister}/:id?`}
            isPrivate={true}
            component={Customer}
        />
        <Route
            path={`${paths.customer}/:id?`}
            isPrivate={true}
            component={CustomerList}
        />
        <Route
            path={`${paths.providerRegister}/:id?`}
            isPrivate={true}
            component={Provider}
        />
        <Route
            path={`${paths.provider}`}
            isPrivate={true}
            component={ProviderList}
        />
        <Route
            path={`${paths.brandRegister}/:id?`}
            isPrivate={true}
            component={Brand}
        />
        <Route path={`${paths.brand}`} isPrivate={true} component={BrandList} />
        <Route
            path={`${paths.modelBrandRegister}/:id?`}
            isPrivate={true}
            component={ModelBrand}
        />
        <Route
            path={`${paths.modelBrand}`}
            isPrivate={true}
            component={ModelBrandList}
        />
        <Route
            path={`${paths.technicianServiceRegister}/:id?`}
            isPrivate={true}
            component={TechnicianService}
        />
        <Route
            path={`${paths.technicianService}`}
            isPrivate={true}
            component={TechnicianServiceList}
        />
        <Route
            path={`${paths.districtRegister}/:id?`}
            isPrivate={true}
            component={District}
        />
        <Route
            path={`${paths.district}`}
            isPrivate={true}
            component={DistrictList}
        />
        <Route
            path={`${paths.configurationRegister}/:id?`}
            isPrivate={true}
            component={Configuration}
        />
        <Route
            path={`${paths.configuration}/:id?`}
            isPrivate={true}
            component={ConfigurationList}
        />
        {/*FIM - cadastros gerais */}

        <Route
            path={`${paths.invoiceOutputProductRegister}/:id?`}
            isPrivate={true}
            component={InvoiceOutputProduct}
        />

        <Route
            isPrivate={true}
            path={`${paths.entryProductRegister}/:id?`}
            component={EntryProduct}
        />
        <Route
            isPrivate={true}
            path={`${paths.entryProduct}`}
            component={EntryProductList}
        />
        <Route
            isPrivate={true}
            path={`${paths.outputProductRegister}/:id?`}
            component={OutputProduct}
        />
        <Route
            isPrivate={true}
            path={`${paths.outputProduct}`}
            component={OutputProductList}
        />
        <Route
            isPrivate={true}
            path={`${paths.workOrderRegister}/:id?`}
            component={WorkOrder}
        />
        <Route
            isPrivate={true}
            path={`${paths.workOrder}`}
            component={WorkOrderList}
        />
        <Route isPrivate={true} path={`${paths.paid}`} component={Paid} />
        <Route
            isPrivate={true}
            path={`${paths.invoiceDetail}`}
            component={InvoiceDetailPayment}
        />
        <Route
            isPrivate={true}
            path={`${paths.invoiceDetailList}`}
            component={InvoiceDetailList}
        />
        <Route
            isPrivate={true}
            path={`${paths.invoiceDetailRegister}/:id/:typeQuery`}
            component={InvoiceDetailRegister}
        />
        <Route
            isPrivate={true}
            path={`${paths.generatedInvoices}`}
            component={GeneratedInvoice}
        />
        {/* <Route path="/records/category" component={ListEntity} /> */}
    </Switch>
);

export default Routes;

import MainContainer from '../main-container';
import Form from './brandForm';
import List from './brandList';

const Brand: React.FC = () => {
    return (
        <MainContainer>
            <Form />
        </MainContainer>
    );
};

const BrandList: React.FC = () => {
    return (
        <MainContainer>
            <List />
        </MainContainer>
    );
};

export { Brand, BrandList };

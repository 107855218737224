import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import logoImg from '../../assets/images/icone_alterado.png';
import ChartComponent from '../../componets/chart/chart';
import api from '../../services/api';
import MainContainer from '../main-container';
import './styles.scss';
import { useAuth } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { paths } from '../../config';
import { useToast } from '../../context/ToastContext';
import { message } from '../../util/handleMessages';
import { useContextGlobal } from '../../context/ContextGlobal';

interface Data {
    total: number;
    data: {
        value: number;
        date: string;
    }[];
}

const Component: React.FC = () => {
    const month = format(new Date(), 'MMMM', { locale: ptBR });
    const { addToast } = useToast();
    const { setOpenLoading } = useContextGlobal();

    const [pdf, setPdf] = useState('');

    const handleGenerateBoleto = async () => {
        try {
            setOpenLoading(true);
            const response = await api.post(`payment`);
            const link = response.data.links.find(
                (l: any) => l.media == 'application/pdf',
            );
            setOpenLoading(false);
            window.open(link.href, '_blank', 'noopener,noreferrer');
            // let newTab = window.open();
            // if (newTab) {
            //     newTab.location.href = link.href;
            // }

            // setPdf(link.href);
            // window.open(link.href, 'name', 'height=500,width=550');
        } catch (error) {
            console.error(error);
            addToast({
                type: 'error',
                title: message.error.selectOne,
            });
        }
    };

    return (
        <div className="principal-container-dashboard">
            <div className="container-title">
                <h2>GESSIS - Gestão com sistema</h2>
                <h6>Gerenciamento de orçamentos, estoques e vendas</h6>
                <img src={logoImg} alt="Gessis" width={100} height={79} />
            </div>
            <div className="container-image">
                <h6>
                    Baixar fatura do mês de{' '}
                    {month[0].toUpperCase() + month.substring(1)}
                </h6>

                <Stack component={'div'} spacing={1} direction="column">
                    <Button
                        href="https://boleto.sandbox.pagseguro.com.br/81aff866-a12e-41c9-ad08-34979aa998ba.pdf"
                        target="_black"
                        variant="contained"
                        onClick={handleGenerateBoleto}>
                        Clique aqui para baixar
                        {/* <a
                            href="https://boleto.sandbox.pagseguro.com.br/81aff866-a12e-41c9-ad08-34979aa998ba.pdf"
                            target="_black">
                            Test
                        </a> */}
                    </Button>
                </Stack>
            </div>
        </div>
    );
};

const Dashboard: React.FC = () => {
    return (
        <MainContainer>
            <Component />
        </MainContainer>
    );
};

export default Dashboard;

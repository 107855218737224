import { InputProps, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { randomId } from '../../util/randomIdInput';
import { cepMask } from './mask/cep';
import { cpfAndCnpjMask } from './mask/cpfAndCnpj';
import { numberMask } from './mask/number';
import { phoneMask } from './mask/phone';
import { toUpperCaseMask } from './mask/toUpperCase';

export interface IFormInputProps extends InputProps {
    variant?: 'outlined' | 'standard' | 'filled' | undefined;
    name: string;
    control: any;
    rules?: any;
    label: string;
    messageError?: (errors: any, field: any) => string;
    handleChange?: any;
    handleOnKeyPress?: (data: any) => void;
    handleOnBlur?: (data: any) => void;
    ref?: any;
    autoFocus?: boolean;
    readOnly?: boolean;
    size?: 'small' | 'medium' | undefined;
    sx?: any;
    type?: any;
    multiline?: boolean;
    mask?:
        | 'phone'
        | 'number'
        | 'identification'
        | 'cep'
        | 'toUpperCase'
        | undefined;
    hidden?: boolean;
    inactiveRandomId?: boolean;
}

export const execMask = (mask: string, value: string, onBlur: boolean) => {
    switch (mask) {
        case 'phone':
            return phoneMask(value, onBlur);
        case 'number':
            return numberMask(value);
        case 'identification':
            return cpfAndCnpjMask(value, onBlur);
        case 'cep':
            return cepMask(value, onBlur);
        case 'toUpperCase':
            return toUpperCaseMask(value, onBlur);

        default:
            return '';
    }
};

export const FormInputText = ({
    variant,
    name,
    control,
    rules,
    label,
    messageError,
    handleChange,
    handleOnKeyPress,
    autoFocus,
    readOnly,
    size,
    sx,
    type,
    multiline,
    mask,
    handleOnBlur,
    inactiveRandomId,
}: IFormInputProps) => {
    if (!variant) {
        variant = 'outlined';
    }

    let id = name;
    if (!inactiveRandomId) {
        id = randomId(name);
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { onChange, value },

                fieldState: { error },
                formState,
            }) => {
                if (multiline) {
                    return (
                        <TextField
                            id={id}
                            multiline={multiline}
                            rows={2}
                            maxRows={4}
                            type={type}
                            sx={sx}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                            inputRef={input => {
                                input && autoFocus && input.focus();
                            }}
                            helperText={
                                messageError && messageError(error, name)
                            }
                            error={!!error}
                            size={size || 'small'}
                            onChange={event => {
                                onChange(event);
                                handleChange && handleChange(event);
                            }}
                            onKeyPress={(data: any) => {
                                handleOnKeyPress && handleOnKeyPress(data);
                            }}
                            onBlur={(data: any) => {
                                handleOnBlur && handleOnBlur(data);
                            }}
                            value={value || ''}
                            fullWidth
                            label={label}
                            variant={variant}
                        />
                    );
                }
                return (
                    <TextField
                        multiline={multiline}
                        id={id}
                        type={type}
                        autoComplete="off"
                        sx={sx}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        inputRef={input => {
                            input && autoFocus && input.focus();
                        }}
                        helperText={
                            (!!error &&
                                messageError &&
                                messageError(error, name)) ||
                            error?.message
                        }
                        error={!!error}
                        size={size || 'small'}
                        onChange={event => {
                            if (mask && event.target.value) {
                                event.target.value = execMask(
                                    mask,
                                    event.target.value,
                                    false,
                                );
                            }
                            onChange(event);
                            handleChange && handleChange(event);
                        }}
                        onKeyPress={(data: any) => {
                            handleOnKeyPress && handleOnKeyPress(data);
                        }}
                        onBlur={(event: any) => {
                            if (mask && event.target.value) {
                                event.target.value = execMask(
                                    mask,
                                    event.target.value,
                                    true,
                                );
                                onChange(event);
                            }
                            handleOnBlur && handleOnBlur(event);
                        }}
                        value={value || ''}
                        fullWidth
                        label={label}
                        variant={variant}
                    />
                );
            }}
        />
    );
};

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { createTheme, styled } from '@mui/material/styles';
import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import io from 'socket.io-client';
import AppProvider from './context';
import Routes from './routes';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        // display: 'flex',
        // flexDirection: 'column',
        right: 0,
        position: 'relative',
        // marginRight: 0,
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const Drawer2 = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        display: 'flex',
        flexDirection: 'column',
        right: 0,
        outline: 0,
        top: 0,
        zIndex: 1200,
        height: '100%',
        flex: '1 0 auto',
        position: 'relative',
        overflowY: 'auto',
        // marginRight: 0,
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const mdTheme = createTheme();

// const Dashboard: React.FC = () => {
//     const [openCollapse, setOpenCollapse] = React.useState(false);
//     const [open, setOpen] = React.useState(true);
//     const toggleDrawer = () => {
//         setOpen(!open);
//     };

//     const { user } = useAuth();

//     function handleOpenSettings() {
//         setOpenCollapse(!openCollapse);
//     }

//     return (
//         <div id="root">
//             <ThemeProvider theme={mdTheme}>
//                 <Box sx={{ display: 'flex' }}>
//                     <CssBaseline />
//                     <AppBar open={open}>
//                         <Toolbar
//                             sx={{
//                                 pr: '24px', // keep right padding when drawer closed
//                             }}>
//                             <IconButton
//                                 edge="start"
//                                 color="inherit"
//                                 aria-label="open drawer"
//                                 onClick={toggleDrawer}
//                                 sx={{
//                                     marginRight: '36px',
//                                     ...(open && { display: 'none' }),
//                                 }}>
//                                 <MenuIcon />
//                             </IconButton>
//                             <Typography
//                                 component="h1"
//                                 variant="h6"
//                                 color="inherit"
//                                 noWrap
//                                 sx={{ flexGrow: 1 }}>
//                                 Dashboard
//                             </Typography>
//                             <IconButton color="inherit">
//                                 <Badge badgeContent={4} color="secondary">
//                                     <NotificationsIcon />
//                                 </Badge>
//                             </IconButton>
//                         </Toolbar>
//                     </AppBar>
//                     <Drawer variant="permanent" open={open}>
//                         <Toolbar
//                             sx={{
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 justifyContent: 'flex-end',
//                                 px: [1],
//                             }}>
//                             <IconButton onClick={toggleDrawer}>
//                                 <ChevronLeftIcon />
//                             </IconButton>
//                         </Toolbar>
//                         <Divider />
//                         <List component="nav">
//                             <ListItem button onClick={handleOpenSettings}>
//                                 <ListItemIcon>
//                                     <AddCard />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Cadastros" />
//                                 {openCollapse ? <ExpandLess /> : <ExpandMore />}
//                             </ListItem>
//                             <Collapse
//                                 in={openCollapse}
//                                 timeout="auto"
//                                 unmountOnExit>
//                                 <ListItem
//                                     button
//                                     component="a"
//                                     href="/records/category">
//                                     <ListItemButton>
//                                         <ListItemIcon>
//                                             <IconCategory />
//                                         </ListItemIcon>
//                                         <ListItemText primary="Categoria" />
//                                     </ListItemButton>
//                                 </ListItem>
//                                 <ListItem
//                                     button
//                                     component="a"
//                                     href="/records/product">
//                                     <ListItemButton>
//                                         <ListItemIcon>
//                                             <InventoryIcon />
//                                         </ListItemIcon>
//                                         <ListItemText primary="Produto" />
//                                     </ListItemButton>
//                                 </ListItem>
//                                 <ListItem>
//                                     <ListItemButton>
//                                         <ListItemIcon>
//                                             <InventoryIcon />
//                                         </ListItemIcon>
//                                         <ListItemText primary="Teste" />
//                                     </ListItemButton>
//                                 </ListItem>
//                             </Collapse>
//                             <ListItemButton
//                                 component="a"
//                                 href="/records/entry-product">
//                                 <ListItemIcon>
//                                     <DashboardIcon />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Entrada de produtos" />
//                             </ListItemButton>
//                             <ListItemButton
//                                 component="a"
//                                 href="/records/output-product">
//                                 <ListItemIcon>
//                                     <OutputIcon />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Saída de produtos" />
//                             </ListItemButton>
//                             <ListItemButton>
//                                 <ListItemIcon>
//                                     <PeopleIcon />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Customers" />
//                             </ListItemButton>
//                             <ListItemButton>
//                                 <ListItemIcon>
//                                     <BarChartIcon />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Reports" />
//                             </ListItemButton>
//                             <ListItemButton>
//                                 <ListItemIcon>
//                                     <LayersIcon />
//                                 </ListItemIcon>
//                                 <ListItemText primary="Integrations" />
//                             </ListItemButton>

//                             <Divider sx={{ my: 1 }} />
//                             {secondaryListItems}
//                         </List>
//                     </Drawer>
//                     <AppProvider>
//                         <Box
//                             component="main"
//                             className="container"
//                             sx={{
//                                 backgroundColor: theme =>
//                                     theme.palette.mode === 'light'
//                                         ? theme.palette.grey[100]
//                                         : theme.palette.grey[900],
//                                 flexGrow: 1,
//                                 height: '100vh',
//                                 overflow: 'auto',
//                             }}>
//                             <Toolbar />
//                             <div className="container">
//                                 <Grid container spacing={1}>
//                                     <Box
//                                         component="main"
//                                         sx={{
//                                             backgroundColor: theme =>
//                                                 theme.palette.mode === 'light'
//                                                     ? theme.palette.grey[100]
//                                                     : theme.palette.grey[900],
//                                             flexGrow: 1,
//                                             height: '100vh',
//                                             overflow: 'auto',
//                                         }}>
//                                         <Router>
//                                             <Routes />
//                                         </Router>
//                                     </Box>
//                                 </Grid>

//                                 <Footer />
//                             </div>
//                         </Box>
//                     </AppProvider>
//                 </Box>
//             </ThemeProvider>
//         </div>
//     );
// };

const App: React.FC = () => {
    const baseUrl =
        process.env.REACT_APP_ENVIROMENT === 'local'
            ? 'http://localhost:6001'
            : 'https://api.gessis.com.br';
    const socket = io(baseUrl, { transports: ['websocket'] });

    const [message, setMessage] = React.useState<any>({});
    React.useEffect(() => {
        socket.on('msgToClient', (messageTest: any) => {
            setMessage(messageTest);
        });
    }, []);

    return (
        <>
            <Router>
                <AppProvider message={message}>
                    <Routes />
                    {/* <InterceptorComponent /> */}
                </AppProvider>
            </Router>
        </>
    );
};

export default App;
